import client from '../restClients/authenticatedBff';
import { completeRegistrationAction } from 'components/Signup/actions';
import MESSAGES from '../translations';
import ActionTypes from './actionTypes';

const ACTIVE_STATUS = 'active';

export const initStoreAction = (
  intl,
  loginInfo,
  logoutInfo,
  registrationInfo,
  registrationSuccessInfo,
  verifyInfo,
  otp,
) => {
  return {
    type: ActionTypes.VERIFY_FETCH,
    loginInfo,
    intl,
    logoutInfo,
    registrationInfo,
    registrationSuccessInfo,
    verifyInfo,
    otp,
  };
};

export const changeLocale = (payload) => {
  // eslint-disable-next-line no-param-reassign
  payload.messages = MESSAGES[payload.locale];
  return {
    type: ActionTypes.CHANGE_LOCALE_LANGUAGE_SUCCESS,
    payload,
  };
};

export const verifyFetch = () => {
  return {
    type: ActionTypes.VERIFY_FETCH,
  };
};

export const verifySuccess = () => {
  return {
    type: ActionTypes.VERIFY_SUCCESS,
  };
};

export const verifyFail = () => {
  return {
    type: ActionTypes.VERIFY_FAIL,
  };
};

export const tokenSuccess = (token) => {
  return {
    type: ActionTypes.TOKEN_SUCCESS,
    token,
  };
};

export const startRegistrationAction = (payload) => {
  return {
    type: ActionTypes.START_REGISTRATION,
    payload,
  };
};

export const registerFailureAction = (payload) => {
  return {
    type: ActionTypes.REGISTER_FAILURE,
    payload,
  };
};

export const registrationAdditionalStepAction = (payload) => {
  return {
    type: ActionTypes.REGISTRATION_ADDITIONAL_STEP,
    payload,
  };
};

export const logoutStartAction = () => {
  return {
    type: ActionTypes.LOGOUT_START,
  };
};

export const logoutSuccessAction = (payload) => {
  return {
    type: ActionTypes.LOGOUT_SUCCESS,
    payload,
  };
};

export const logoutFailureAction = (payload) => {
  return {
    type: ActionTypes.LOGOUT_FAILURE,
    payload,
  };
};

export const makeRequest = (body) => {
  const BFF_VERIFY_EMAIL = window.REACT_APP_BFF_VERIFY_MAIL || '';
  return (dispatch) => {
    dispatch(verifyFetch());
    client
      .post(BFF_VERIFY_EMAIL, body)
      .then((response) => {
        dispatch(completeRegistrationAction(response.data));
        if (response.data.accountStatus !== ACTIVE_STATUS) {
          dispatch(verifyFail());
        } else {
          dispatch(verifySuccess());
        }
      })
      .catch(() => {
        dispatch(verifyFail());
      });
  };
};

export const updateMfaButtonStatus = (requestInProgress) => {
  return {
    type: ActionTypes.UPDATE_MFA_BUTTON_STATUS,
    requestInProgress,
  };
};

export const actionCreators = {
  tokenSuccess,
  completeRegistrationAction,
  changeLocale,
  makeRequest,
};

import React from 'react';
import PropTypes from 'prop-types';
import { ExpandablePanel, TextLink, P, MessageCard, MESSAGE_VARIANT } from '@manulife/mux';
import { useRealm, useRichIntl, FormattedRichMessage } from 'ciam-self-service-shared';
import { FormattedMessage } from 'react-intl';

function ContactPanel(props) {
  const { product } = props;
  const realm = useRealm();
  const richIntl = useRichIntl();

  const phone = richIntl.formatMessage({ id: `contact.${realm}.${product}.phone`, defaultMessage: ' ' });
  const email = richIntl.formatMessage({ id: `contact.${realm}.${product}.email`, defaultMessage: ' ' });
  const hours = richIntl.formatMessage(
    {
      id: `contact.${realm}.${product}.hours`,
      defaultMessage: ' ',
    },
    {
      newline: function newline() {
        return '';
      },
    },
  );
  const link = richIntl.formatMessage(
    { id: `contact.${realm}.${product}.link`, defaultMessage: ' ' },
    {
      ContactLink: function ContactLink(chunks) {
        return chunks;
      },
    },
  );

  const hasPhone = phone !== ' ';
  const hasEmail = email !== ' ';
  const hasHours = hours !== ' ';
  const hasLink = link !== ' ';

  return (
    <ExpandablePanel
      title={<FormattedRichMessage id={`contact.${realm}.${product}.title`} />}
      customStyle={{ expandablePanelStyle: { marginBottom: '1rem' } }}
    >
      <FormattedRichMessage
        id={`contact.${realm}.${product}.topContent`}
        defaultMessage=" "
        values={{
          MessageCardPrimaryInformation: function MessageCardPrimaryInformation(chunks) {
            return <MessageCard variant={MESSAGE_VARIANT.PRIMARY_INFORMATION}>{chunks}</MessageCard>;
          },
          GroupRetirementSupportLink: function GroupRetirementSupportLink(chunks) {
            return (
              <TextLink
                to={richIntl.formatMessage({
                  id: 'contact.customer.groupRetirement.custom.GroupRetirementSupportLink',
                })}
              >
                {chunks}
              </TextLink>
            );
          },
        }}
      />
      {hasPhone && (
        <P>
          <FormattedRichMessage id="contact.phone" />
          <TextLink to={`tel:${phone}`}>{phone}</TextLink>
          <FormattedMessage id={`contact.${realm}.${product}.phone.extension`} defaultMessage=" " />
        </P>
      )}
      {hasEmail && (
        <P>
          <FormattedRichMessage id="contact.email" />
          <TextLink to={`mailto:${email}`}>{email}</TextLink>
        </P>
      )}
      {hasHours && (
        <P>
          <FormattedRichMessage id={`contact.${realm}.${product}.hours`} />
        </P>
      )}
      {hasLink && (
        <P>
          <FormattedRichMessage
            id={`contact.${realm}.${product}.link`}
            values={{
              ContactLink: function ContactLink(chunks) {
                return (
                  <TextLink isExternal to={richIntl.formatMessage({ id: `contact.${realm}.${product}.link.url` })}>
                    {chunks}
                  </TextLink>
                );
              },
            }}
          />
        </P>
      )}
    </ExpandablePanel>
  );
}

ContactPanel.propTypes = {
  product: PropTypes.string.isRequired,
};

export default ContactPanel;

import { brandMiddleware } from 'ciam-self-service-shared';

const baseConfig = {
  clientId: window.REACT_APP_FR_CLIENT_ID,
  redirectUri: window.REACT_APP_FR_REDIRECT_URL,
  scope: window.REACT_APP_FR_SCOPE,
  serverConfig: {
    baseUrl: window.REACT_APP_FR_BASE_URL,
    timeout: window.REACT_APP_TIME_OUT,
  },
  realmPath: window.REACT_APP_FR_REALM_PATH,
  tree: '',
};

const determineConfig = (treeName, realm, brand) => {
  baseConfig.tree = treeName;
  if (realm) {
    const mapping = {
      user: 'customers',
      sponsor: 'sponsors',
      advisor: 'advisors',
      customer: 'customers',
      sponsors: 'sponsors',
      advisors: 'advisors',
      mortgagecreditor: 'mortgagecreditor',
      travel: 'travel',
    };
    const urlMapping = {
      user: window.REACT_APP_FR_BASE_URL,
      sponsor: window.REACT_APP_FR_SPONSOR_BASE_URL,
      advisor: window.REACT_APP_FR_ADVISOR_BASE_URL,
      customer: window.REACT_APP_FR_BASE_URL,
      sponsors: window.REACT_APP_FR_SPONSOR_BASE_URL,
      advisors: window.REACT_APP_FR_ADVISOR_BASE_URL,
      mortgagecreditor: window.REACT_APP_FR_MORTGAGECREDITOR_BASE_URL,
      travel: window.REACT_APP_FR_TRAVEL_BASE_URL,
    };
    baseConfig.serverConfig.baseUrl = urlMapping[realm];
    baseConfig.realmPath = mapping[realm];
    baseConfig.middleware = [brandMiddleware(brand)];
  }
  return baseConfig;
};

export default determineConfig;
export const backendBaseUrl = window.REACT_APP_CIAM_BACKEND_BASE_URL || '';
export const amBaseUrl = window.REACT_APP_FR_BASE_URL || '';

import init from './steps/init';
import chooseChannel from './steps/chooseChannel';
import requestNewChannel from './steps/requestNewChannel';
import requestResend from './steps/requestResend';
import submitCode from './steps/submitCode';
import abandon from './steps/abandon';

export default {
  init,
  chooseChannel,
  submitCode,
  requestResend,
  requestNewChannel,
  abandon,
};

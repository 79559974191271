import { CallbackType, StepType } from '@forgerock/javascript-sdk/lib';
import { updateStageAction, updateUserContext } from 'components/Login/actions';
import executeHiddenCallbackForOTPAutomatedTesting from './executeHiddenCallbackForOTPAutomatedTesting';
import nextStep from './handleStep';

const executeHiddenCallbackForUserContextRetrieval = async (dispatch, step) => {
  if (step.type === StepType.LoginSuccess) {
    dispatch(updateStageAction(step));
  } else {
    const userContextScript = step.getCallbackOfType(CallbackType.TextOutputCallback).getOutputByName('message');
    // Start of parsing userContext out of script
    const startOfSection = userContextScript.indexOf('context');
    const endOffValue = userContextScript.indexOf('function submit');
    const value = userContextScript.substring(startOfSection + 10, endOffValue).replace(/;/g, '');
    // End of parsing userContext out of script
    dispatch(updateUserContext(JSON.parse(value)));
    sessionStorage.setItem('context', JSON.stringify(JSON.parse(value)));
    const hiddenValueCallback = step.getCallbackOfType(CallbackType.HiddenValueCallback);
    hiddenValueCallback.setInputValue('output');
    const secondStep = await nextStep(dispatch, step);
    // handling for users in automated testing group
    const finalStep = await executeHiddenCallbackForOTPAutomatedTesting(dispatch, secondStep);
    return finalStep;
  }
  return step;
};

export default executeHiddenCallbackForUserContextRetrieval;

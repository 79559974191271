import {
  sessionValidated,
  sessionValidateFail,
  getSessionInfoFailure,
  getSessionInfoSuccess,
  sessionValidating,
} from 'components/Login/actions';
import client from '../restClients/authenticatedBff';

const BFF_VALIDATE_SESSION = window.REACT_APP_BFF_VALIDATE_SESSION;
const BFF_REFRESH_SESSION = window.REACT_APP_BFF_REFRESH_SESSION;
const BFF_SESSION_INFO = window.REACT_APP_BFF_SESSION_INFO;
const successPlaceholder = { type: '<insert success action>' };
const failurePlaceholder = { type: '<insert failure action>' };

export const validateSession = async (dispatch, realm) => {
  const reqBody = {
    realm,
  };

  dispatch(sessionValidating());
  const response = await client.post(BFF_VALIDATE_SESSION, reqBody);
  if (response.data === true) {
    dispatch(sessionValidated());
  } else {
    dispatch(sessionValidateFail());
  }
};

export const refreshSession = async (dispatch, realm, tokenId) => {
  const reqBody = {
    realm,
    tokenId,
  };
  try {
    const response = await client.post(BFF_REFRESH_SESSION, reqBody, {
      withCredentials: true,
    });
    if (response.data.hashedCiamId) {
      dispatch(successPlaceholder);
    } else {
      dispatch(failurePlaceholder);
    }
  } catch {
    dispatch(failurePlaceholder);
  }
};

export const getSessionInfo = async (dispatch, realm) => {
  try {
    const response = await client.post(
      BFF_SESSION_INFO,
      {
        realm,
      },
      {
        withCredentials: true,
      },
    );
    dispatch(getSessionInfoSuccess(response.data));
  } catch (_) {
    dispatch(getSessionInfoFailure());
  }
};

export const getCiamId = async (realm) => {
  try {
    const response = await client.post(
      BFF_SESSION_INFO,
      {
        realm,
      },
      {
        withCredentials: true,
      },
    );
    return response.data.hashedCiamId;
  } catch (_) {
    return '';
  }
};

import React, { useDebugValue, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRealm } from 'ciam-self-service-shared';
import { validateSession } from '../utils/session';

const AUTH_TYPE_NONE = 'none';

const previousRealm = React.createRef();

function useAuthCheck(authType) {
  const dispatch = useDispatch();
  const realm = useRealm();
  const isLoggedIn = useSelector((state) => state.loginInfo.isLoggedIn);
  const isAuthLoading = authType !== AUTH_TYPE_NONE && typeof isLoggedIn === 'undefined';
  const logoutStarted = useSelector((state) => state.loginInfo.logoutStarted);

  useEffect(() => {
    const skipAuth = authType === AUTH_TYPE_NONE;
    const realmChanged = previousRealm.current !== realm;
    const needsLoginCheck = typeof isLoggedIn === 'undefined';

    previousRealm.current = realm;

    if (!skipAuth && (realmChanged || needsLoginCheck)) {
      validateSession(dispatch, realm);
    }
  }, [authType, realm, dispatch, isLoggedIn]);

  useDebugValue({ isAuthLoading, isLoggedIn });
  return { isAuthLoading, isLoggedIn, logoutStarted };
}

export default useAuthCheck;

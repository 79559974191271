import React from 'react';
import { useIntl } from 'react-intl';
import MFAEnterCode from 'components/common/MFA/MFAEnterCode';
import { FormattedRichMessage, ContentPage, LoadingPage, usePathBuilder } from 'ciam-self-service-shared';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import userContextUtils from 'utils/userContextUtils';
import Page, { AUTH_TYPE } from 'components/common/Page';
import onDemandAuthTree from '../authTree';
import useAuthTreeErrorHandler from '../useAuthTreeErrorHandler';
import OnDemandBackButton from './OnDemandBackButton';
import useOnDemandOtpType from '../useOnDemandOtpType';

function OnDemandEnterCodePage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const pathBuilder = usePathBuilder();
  const type = useOnDemandOtpType();
  const intl = useIntl();
  const authTreeErrorHandler = useAuthTreeErrorHandler();
  const stage = useSelector((state) => state.loginInfo.stage);
  const otpCount = useSelector((state) => state.loginInfo.otpCount);
  const isOTPGeneratedMultipleTimes = useSelector((state) => state.loginInfo.isOTPGeneratedMultipleTimes);
  const isLoginAfterError = useSelector((state) => state.loginInfo.isLoginAfterError);
  const mobileNumber = useSelector((state) => state.loginInfo.mobileNumber);
  const loginError = useSelector((state) => state.loginInfo.loginError);
  const userContext = useSelector((state) => state.loginInfo.userContext);
  const channels = userContextUtils.getChannels(userContext);
  const selectedChannel = userContextUtils.getSelectedChannel(userContext);

  const handleSubmitCode = async (_, payload) => {
    try {
      await onDemandAuthTree.submitCode(dispatch, payload);
    } catch (err) {
      authTreeErrorHandler(err);
    }
  };

  const handleRequestResend = async (_, payload) => {
    try {
      await onDemandAuthTree.requestResend(dispatch, payload);
    } catch (err) {
      authTreeErrorHandler(err);
    }
  };

  const handleRequestNewChannel = async (_, payload) => {
    try {
      await onDemandAuthTree.requestNewChannel(dispatch, payload);
      history.push(pathBuilder('/otp-on-demand', true, { searchParams: { type } }));
    } catch (err) {
      authTreeErrorHandler(err);
    }
  };

  return (
    <Page
      title={intl.formatMessage({ id: 'mfa.enterCode.page.title' })}
      authType={AUTH_TYPE.AUTHENTICATED}
      showLangToggle={false}
      showRealmToggle={false}
      showContactUs={false}
      showManageId={false}
    >
      {selectedChannel ? (
        <ContentPage
          backButton={<OnDemandBackButton />}
          heading={
            // eslint-disable-next-line react/jsx-wrap-multilines
            <FormattedRichMessage
              id="mfa.enterCode.page.heading"
              values={{
                new: isOTPGeneratedMultipleTimes ? intl.formatMessage({ id: `mfa.enterCode.new` }) : '',
              }}
            />
          }
        >
          <MFAEnterCode
            content={<FormattedRichMessage id="mfa.enterCode.timeReminder" values={{ mobileNumber }} />}
            stage={stage}
            channel={selectedChannel}
            hasResentOtp={isOTPGeneratedMultipleTimes}
            otpCount={otpCount}
            error={loginError}
            isLoginAfterError={isLoginAfterError}
            onSubmitCode={handleSubmitCode}
            onRequestResend={handleRequestResend}
            onRequestNewChannel={handleRequestNewChannel}
            singleChannel={channels?.length === 1}
          />
        </ContentPage>
      ) : (
        <LoadingPage />
      )}
    </Page>
  );
}

export default OnDemandEnterCodePage;

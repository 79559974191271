import { REALMS } from 'ciam-self-service-shared';

const DEFAULT_REDIRECTS = {
  'dev.': {
    [REALMS.ADVISOR]: {
      'en-CA': 'https://dev.id.manulife.ca/products/advisor/auth?ui_locales=en-CA',
      'fr-CA': 'https://dev.id.manulife.ca/products/advisor/auth?ui_locales=fr-CA',
    },
    [REALMS.CUSTOMER]: {
      'en-CA': 'https://dev.id.manulife.ca/products/auth?ui_locales=en-CA',
      'fr-CA': 'https://dev.id.manulife.ca/products/auth?ui_locales=fr-CA',
    },
    [REALMS.MCI]: {
      'en-CA': 'https://broker-ui-tst.apps.cac.preview.pcf.manulife.com/member/login?ui_locales=en-CA',
      'fr-CA': 'https://broker-ui-tst.apps.cac.preview.pcf.manulife.com/member/login?ui_locales=fr-CA',
    },
    [REALMS.SPONSOR]: {
      'en-CA': 'https://dev.id.manulife.ca/products/sponsor/auth?ui_locales=en-CA',
      'fr-CA': 'https://dev.id.manulife.ca/products/sponsor/auth?ui_locales=fr-CA',
    },
    [REALMS.TRAVEL]: {
      'en-CA': 'https://travelb2b-dev.apps.cac.preview.pcf.manulife.com/agents',
      'fr-CA': 'https://travelb2b-dev-fr.apps.cac.preview.pcf.manulife.com/agents',
    },
  },
  'sit.': {
    [REALMS.ADVISOR]: {
      'en-CA': 'https://sit.id.manulife.ca/products/advisor/auth?ui_locales=en-CA',
      'fr-CA': 'https://sit.id.manulife.ca/products/advisor/auth?ui_locales=fr-CA',
    },
    [REALMS.CUSTOMER]: {
      'en-CA': 'https://sit.id.manulife.ca/products/auth?ui_locales=en-CA',
      'fr-CA': 'https://sit.id.manulife.ca/products/auth?ui_locales=fr-CA',
    },
    [REALMS.MCI]: {
      'en-CA': 'https://broker-ui-tst.apps.cac.preview.pcf.manulife.com/member/login',
      'fr-CA': 'https://broker-ui-tst.apps.cac.preview.pcf.manulife.com/member/login',
    },
    [REALMS.SPONSOR]: {
      'en-CA': 'https://sit.id.manulife.ca/products/sponsor/auth?ui_locales=en-CA',
      'fr-CA': 'https://sit.id.manulife.ca/products/sponsor/auth?ui_locales=fr-CA',
    },
    [REALMS.TRAVEL]: {
      'en-CA': 'https://travelb2b-sit.apps.cac.preview.pcf.manulife.com/agents',
      'fr-CA': 'https://travelb2b-sit-fr.apps.cac.preview.pcf.manulife.com/agents',
    },
  },
  'uat.': {
    [REALMS.ADVISOR]: {
      'en-CA': 'https://uat.id.manulife.ca/products/advisor/auth?ui_locales=en-CA',
      'fr-CA': 'https://uat.id.manulife.ca/products/advisor/auth?ui_locales=fr-CA',
    },
    [REALMS.CUSTOMER]: {
      'en-CA': 'https://uat.id.manulife.ca/products/auth?ui_locales=en-CA',
      'fr-CA': 'https://uat.id.manulife.ca/products/auth?ui_locales=fr-CA',
    },
    [REALMS.MCI]: {
      'en-CA': 'https://mppbroker-uat.manulife.com/member/login?ui_locales=en-CA',
      'fr-CA': 'https://mppbroker-uat.manulife.com/member/login?ui_locales=fr-CA',
    },
    [REALMS.SPONSOR]: {
      'en-CA': 'https://uat.id.manulife.ca/products/sponsor/auth?ui_locales=en-CA',
      'fr-CA': 'https://uat.id.manulife.ca/products/sponsor/auth?ui_locales=fr-CA',
    },
    [REALMS.TRAVEL]: {
      'en-CA': 'https://uat.manulife-insurance.ca/agents',
      'fr-CA': 'https://uat.assurance-manuvie.ca/agents',
    },
  },
  // when window.REACT_ENV is empty, environment is prod
  '': {
    [REALMS.ADVISOR]: {
      'en-CA': 'https://id.manulife.ca/products/advisor/auth?ui_locales=en-CA',
      'fr-CA': 'https://id.manulife.ca/products/advisor/auth?ui_locales=fr-CA',
    },
    [REALMS.CUSTOMER]: {
      'en-CA': 'https://id.manulife.ca/products/auth?ui_locales=en-CA',
      'fr-CA': 'https://id.manulife.ca/products/auth?ui_locales=fr-CA',
    },
    [REALMS.MCI]: {
      'en-CA': 'https://mpphub.com/member/login?ui_locales=en-CA',
      'fr-CA': 'https://mpphub.com/member/login?ui_locales=fr-CA',
    },
    [REALMS.SPONSOR]: {
      'en-CA': 'https://id.manulife.ca/products/sponsor/auth?ui_locales=en-CA',
      'fr-CA': 'https://id.manulife.ca/products/sponsor/auth?ui_locales=fr-CA',
    },
    [REALMS.TRAVEL]: {
      'en-CA': 'https://www.manulife-insurance.ca/agents',
      'fr-CA': 'https://www.assurance-manuvie.ca/agents',
    },
  },
};

const determineCallback = (env, locale, realm) => {
  const redirectUrl =
    (DEFAULT_REDIRECTS[env] && DEFAULT_REDIRECTS[env][realm] && DEFAULT_REDIRECTS[env][realm][locale]) ||
    'www.manulife.ca'; // defaulting to manulife.ca in case of unexpected values of env, realm or locale
  return redirectUrl;
};
export default determineCallback;

import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useRealm, useCIAMContextParam, usePathBuilder } from 'ciam-self-service-shared';
import { Redirect } from 'react-router';
import useGoToUrl from '../../../hooks/useGoToUrl';
import authTree from '../authTree';
import useAuthTreeErrorHandler from '../authTree/useAuthTreeErrorHandler';

function ActivationSendOTP() {
  const dispatch = useDispatch();
  const pathBuilder = usePathBuilder();
  const realm = useRealm();
  const username = sessionStorage.getItem(`userName-${realm}`);
  const ciamUniqueID = sessionStorage.getItem(`ciamId-${realm}`);
  const goto = useGoToUrl();
  const brand = useCIAMContextParam('brand');
  const authTreeErrorHandler = useAuthTreeErrorHandler();

  const handleOTPTrigger = useCallback(() => {
    (async () => {
      try {
        const initStep = await authTree.init(realm, goto, brand, dispatch);
        await authTree.submitCredentials(dispatch, { stage: initStep, username, ciamUniqueID });
      } catch (err) {
        authTreeErrorHandler(err);
      } finally {
        sessionStorage.removeItem(`userName-${realm}`);
        sessionStorage.removeItem(`ciamId-${realm}`);
      }
    })();
  }, [realm, goto, brand, dispatch, username, ciamUniqueID, authTreeErrorHandler]);

  useEffect(() => {
    handleOTPTrigger();
  }, [handleOTPTrigger]);
  if (username && ciamUniqueID) {
    return <Redirect to={pathBuilder('/activation/enter-code')} push />;
  }
  return <Redirect to={pathBuilder('/activation/login')} push />;
}

export default ActivationSendOTP;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useLocation } from 'react-router-dom';
import { LoadingPage, usePathBuilder } from 'ciam-self-service-shared';
import qs from 'qs';
import Page from '../../common/Page';

const EmailConfirm = (props) => {
  const location = useLocation();
  const pathBuilder = usePathBuilder();
  const { confirmEmailStatus, confirmHandler } = props;
  useEffect(() => {
    const params = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    const { nonce, lang } = params;
    const payload = {
      nonce,
      lang,
    };
    confirmHandler(payload);
  }, [confirmHandler, location]);

  if (confirmEmailStatus === 'Confirmed') {
    return <Redirect to={pathBuilder('/mailconfirm/success')} push />;
  }
  if (confirmEmailStatus === 'ConfirmFail') {
    return <Redirect to={pathBuilder('/mailconfirm/error')} push />;
  }
  return (
    <Page>
      <LoadingPage />
    </Page>
  );
};
EmailConfirm.propTypes = {
  confirmEmailStatus: PropTypes.string.isRequired,
  confirmHandler: PropTypes.func.isRequired,
};
export default EmailConfirm;

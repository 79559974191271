import { useCallback } from 'react';

export default function useAsyncCallback(asyncFn, deps) {
  const callback = useCallback(
    (...args) => {
      asyncFn(...args);
    },
    // sonar-ignore
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps,
  );

  return callback;
}

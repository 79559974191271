import { CallbackType } from '@forgerock/javascript-sdk/lib';
import { nextStepWithChecks, progressToChannelSelect } from '../commonSteps';
import { getCallbackOfTypeSafely } from '../../../../utils/authTreeUtils';

export default async function chooseChannel(dispatch, step, otpChannel) {
  let currentStep = step;

  const choiceCallback = getCallbackOfTypeSafely(currentStep, CallbackType.ChoiceCallback);
  if (!choiceCallback) {
    return currentStep;
  }

  choiceCallback.setChoiceIndex(otpChannel);
  currentStep = await nextStepWithChecks(dispatch, currentStep);
  currentStep = await progressToChannelSelect(dispatch, currentStep);
  return currentStep;
}

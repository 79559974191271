import { Switch, Route, useRouteMatch } from 'react-router';
import * as React from 'react';
import PropTypes from 'prop-types';
import { defineError } from 'ciam-self-service-shared';
import { SERVICES, mapSignupErrorCode } from '../../../utils/error-map';
import SignupPage from './SignUpPage';
import SignUpVerifyPage from './SignUpVerifyPage';
import ErrorPage from './ErrorPage';

function SignUpView(props) {
  const { registrationInfo } = props;
  const path = useRouteMatch()?.path || '';

  // TODO: Move this into error component
  let signupError;
  if (registrationInfo?.error && registrationInfo?.error?.code !== 0) {
    const errCode = mapSignupErrorCode(SERVICES.SIGNUP, registrationInfo);
    const errObj = {
      errorCode: errCode,
      message: registrationInfo,
    };
    signupError = defineError(errObj);
  }

  return (
    <Switch>
      <Route path={`${path}`} exact>
        <SignupPage registrationInfo={registrationInfo} error={signupError} />
      </Route>
      <Route path={`${path}/verify`}>
        <SignUpVerifyPage
          firstName={registrationInfo?.registrationInfo?.givenName}
          email={registrationInfo?.registrationInfo?.email}
          isVerified={false}
          userName={registrationInfo?.registrationInfo?.userName}
          ciamId={registrationInfo?.registrationInfo?.ciamId}
        />
      </Route>
      <Route path={`${path}/verified`}>
        <SignUpVerifyPage firstName={registrationInfo?.registrationInfo?.givenName} isVerified />
      </Route>
      <Route path={`${path}/error`}>
        <ErrorPage />
      </Route>
    </Switch>
  );
}

SignUpView.propTypes = {
  registrationInfo: PropTypes.shape({
    id: PropTypes.string,
    error: PropTypes.shape({}),
    registrationInfo: PropTypes.shape({}),
  }),
};

SignUpView.defaultProps = {
  registrationInfo: {},
};

export default SignUpView;

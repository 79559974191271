import ActionTypes from './actionTypes';
import initState from '../../reducers/initState';
import { AsyncActionState } from '../../utils/constants';

export const register = (state = initState.registrationInfo, action = {}) => {
  switch (action.type) {
    case ActionTypes.START_REGISTRATION: {
      return {
        ...state,
        username: action.payload.username,
        password: action.payload.password,
        email: action.payload.email,
        registrationState: AsyncActionState.Loading,
        error: null,
      };
    }

    case ActionTypes.COMPLETE_REGISTRARTION: {
      const registrationInfo = action.payload;
      return {
        ...state,
        registrationState: AsyncActionState.Success,
        registrationInfo,
      };
    }

    case ActionTypes.REGISTER_FAILURE: {
      return {
        ...state,
        registrationState: AsyncActionState.Failed,
        error: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export const registrationSuccessInfo = (state = initState.registrationSuccessInfo, action = {}) => {
  switch (action.type) {
    case ActionTypes.INIT_STORE:
    case ActionTypes.REGISTRATION_ADDITIONAL_STEP: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
};

import { CallbackType, StepType } from '@forgerock/javascript-sdk/lib';
import { checkStep } from 'utils/authTree/helpers';
import nextStep from './handleStep';
import successRedirection from './common/successRedirection';
import doItLaterHandlerAsyncAction from './doItLaterHandlerAsyncAction';
import { getCallbackOfTypeSafely } from '../authTreeUtils';

const SKIP_NUMBER = 'skipnumber';

const skipAddNumberAsyncAction = async (dispatch, payload) => {
  const { stage, history, gotoURI, locale, realm, brand } = payload;

  const textOutputCallback = getCallbackOfTypeSafely(stage, CallbackType.TextOutputCallback);
  if (textOutputCallback) {
    const confirmationCallback = stage.getCallbackOfType(CallbackType.ConfirmationCallback);
    confirmationCallback.setInputValue('1');
    const finalStep = await nextStep(dispatch, stage);
    if (finalStep?.type === StepType.LoginSuccess) {
      successRedirection(dispatch, { history, finalStep, gotoURI, locale, realm, brand });
    } else {
      // handle any uncaught error - request not sent from UI
      checkStep(finalStep, 'D_705');
    }
  } else {
    // handle any uncaught error - request not sent from UI
    checkStep(null, 'D_705');
  }

  const nameCallback = getCallbackOfTypeSafely(stage, CallbackType.NameCallback);
  if (nameCallback) {
    nameCallback.setName(SKIP_NUMBER);
    const secondStep = await nextStep(dispatch, stage);
    doItLaterHandlerAsyncAction(dispatch, { stage: secondStep, history, gotoURI, locale, realm, brand });
  }
};

export default skipAddNumberAsyncAction;

import { CallbackType } from '@forgerock/javascript-sdk/lib';
import { FORGOT_PASSWORD_TREE, MESSAGE_MFA_CHANNEL } from 'components/Login/constants';
import nextStep from 'utils/loginAuthnTree/handleStep';
import { captureUserNameAction, cleanFPErrorAction } from 'components/ForgotPassword/actions';
import { G200_ERROR, D507_ERROR } from 'components/ForgotPassword/constants';
import { updateErrorCode } from 'components/Login/actions';
import { updateErrorInState } from 'ciam-self-service-shared';
import { checkStep } from 'utils/authTree/helpers';
import executeHiddenCallbackForDevicePrint from '../loginAuthnTree/handleDeviceFingerprint';
import { initAsyncAction } from './initAsyncActionImpl';
import { getCallbackWithMessageSafely, getCallbackOfTypeSafely, getCallbackWithPromptSafely } from '../authTreeUtils';

const submitUsernameAsyncAction = async (dispatch, payload) => {
  dispatch(cleanFPErrorAction());
  const { userName, history, realm, brand } = payload;
  let step = await initAsyncAction(dispatch, FORGOT_PASSWORD_TREE, realm);

  if (getCallbackWithMessageSafely(step, G200_ERROR)) {
    step = await initAsyncAction(dispatch, FORGOT_PASSWORD_TREE, realm, brand);
  }
  checkStep(step, 'D_704');
  const nameCallback = checkStep(getCallbackOfTypeSafely(step, CallbackType.NameCallback), 'D_704');
  if (nameCallback) {
    nameCallback.setName(userName);
    const secondStep = await nextStep(dispatch, step);
    checkStep(secondStep, 'D_704');
    dispatch(captureUserNameAction(userName));
    //  check if username is correct
    if (getCallbackWithMessageSafely(secondStep, G200_ERROR)) {
      updateErrorInState(dispatch, D507_ERROR, updateErrorCode);
    } else {
      const finalStep = await executeHiddenCallbackForDevicePrint(dispatch, secondStep);
      checkStep(finalStep, 'D_704');
      //  redirect to mfa page
      if (checkStep(getCallbackWithPromptSafely(finalStep, MESSAGE_MFA_CHANNEL), 'D_704')) {
        history.push('/mfa');
      }
    }
  }
};

export default submitUsernameAsyncAction;

import { CallbackType, Config, StepType } from '@forgerock/javascript-sdk/lib';
import { updateUserContext } from 'components/Login/actions';
import { forceAuthMiddleware, gotoParamMiddleware } from 'ciam-self-service-shared';
import determineConfig from '../../../../utils/frAuthConfig';
import { nextStepWithChecks, progressToChannelSelect } from '../commonSteps';
import AuthTreeError from '../../../../utils/authTree/AuthTreeError';
import { extractUserContext, redirectIfRequired } from '../../../../utils/authTree/helpers';
import { getCallbackOfTypeSafely } from '../../../../utils/authTreeUtils';

export const OTPOnDemandTrees = {
  oidc: 'ondemand_stepup',
  saml: 'saml_ondemand_stepup',
};

export default async function init(realm, goto, dispatch, treeName = OTPOnDemandTrees.OnDemandStepup) {
  Config.set({
    ...determineConfig(treeName, realm),
    middleware: [forceAuthMiddleware(), gotoParamMiddleware(goto)],
  });

  let currentStep = await nextStepWithChecks(dispatch);

  if (currentStep.type === StepType.LoginSuccess) {
    window.location.href = currentStep.getSuccessUrl();
    return currentStep;
  }

  if (redirectIfRequired(currentStep)) {
    return currentStep;
  }

  const choiceCallback = currentStep.getCallbackOfType(CallbackType.ChoiceCallback);
  choiceCallback.setChoiceValue('ondemand');
  currentStep = await nextStepWithChecks(dispatch, currentStep);

  const textOutputCallback = getCallbackOfTypeSafely(currentStep, CallbackType.TextOutputCallback);
  if (textOutputCallback) {
    const userContext = extractUserContext(textOutputCallback);
    dispatch(updateUserContext(userContext));
    currentStep = await progressToChannelSelect(dispatch, currentStep);
    return currentStep;
  }

  throw new AuthTreeError('Unexpected auth tree node');
}

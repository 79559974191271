import { Switch, Route, useRouteMatch } from 'react-router';
import * as React from 'react';
import PropTypes from 'prop-types';
import EmailConfirm from './EmailConfirm';
import ConfirmMailPage from './ConfirmMailPage';
import ErrorPage from './ErrorPage';

function ConfirmMailView(props) {
  const { confirmEmailStatus, confirmHandler } = props;
  const path = useRouteMatch()?.path || '';

  return (
    <Switch>
      <Route path={`${path}`} exact>
        <EmailConfirm confirmEmailStatus={confirmEmailStatus} confirmHandler={confirmHandler} />
      </Route>
      <Route path={`${path}/success`}>
        <ConfirmMailPage />
      </Route>
      <Route path={`${path}/error`}>
        <ErrorPage />
      </Route>
    </Switch>
  );
}

ConfirmMailView.propTypes = {
  confirmEmailStatus: PropTypes.string.isRequired,
  confirmHandler: PropTypes.func.isRequired,
};

export default ConfirmMailView;

import ActionTypes from '../actions/actionTypes';
import initState from './initState';

const verifyReducer = (state = initState.verifyInfo, action) => {
  switch (action.type) {
    case ActionTypes.VERIFY_FETCH: {
      return {
        ...state,
        success: 0,
      };
    }
    case ActionTypes.VERIFY_SUCCESS: {
      return {
        ...state,
        success: 1,
      };
    }
    case ActionTypes.VERIFY_FAIL: {
      return {
        ...state,
        success: 2,
      };
    }
    default: {
      return state;
    }
  }
};

export default verifyReducer;

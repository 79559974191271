import { CallbackType } from '@forgerock/javascript-sdk/lib';
import {
  MESSAGE_OTP_EXPIRED_ADD_NUMBER,
  MESSAGE_OTP_VERIFICATION_FAILED,
  MESSAGE_OTP_RETRY_LIMIT,
} from 'components/Login/constants';
import { updateErrorCode } from 'components/Login/actions';
import removeSessionStorageAttributes from 'utils/removeSessionStorageAttributes';
import { updateErrorInState } from 'ciam-self-service-shared';
import { INVALID_OTP, DO_IT_LATER_SELECTION } from './constants';
import nextStep from './handleStep';
import { checkStep } from 'utils/authTree/helpers';
import chooseResendOrRetryAfterOTPExpiry from './chooseResendOrRetryAfterOTPExpiry';
import gotoMyAccountHandlerAsyncAction from './gotoMyAccountHandlerAsyncActionImpl';
import handleBackButtonClick from './handleBackButtonClick';
import { getCallbackOfTypeSafely, getCallbackWithMessageSafely } from '../authTreeUtils';

const doItLaterHandlerAsyncAction = async (dispatch, payload) => {
  dispatch(updateErrorCode(null));
  const { history, gotoURI, locale, realm, pathBuilder, ciamHistory, brand } = payload;
  let { stage } = payload;
  // after clicking back button from email account
  stage = await handleBackButtonClick(stage, realm, brand, 'ciam.addNumberbackButton-otp');

  let step = stage;
  let resultStage = '';
  if (getCallbackWithMessageSafely(stage, MESSAGE_OTP_RETRY_LIMIT)) {
    await removeSessionStorageAttributes();
    updateErrorInState(dispatch, 'D_622', updateErrorCode);
    history.push(pathBuilder('/signin', true, { searchParams: { error_code: 'D_622' } }));
  }

  const passwordCallback = getCallbackOfTypeSafely(stage, CallbackType.PasswordCallback);
  if (passwordCallback) {
    passwordCallback.setPassword(INVALID_OTP);
    step = await nextStep(dispatch, stage);
    // handle any uncaught error - request not sent from UI
    checkStep(step, 'D_705');
  }

  const choiceCallback = getCallbackOfTypeSafely(step, CallbackType.ChoiceCallback);
  if (choiceCallback) {
    choiceCallback.setChoiceIndex(DO_IT_LATER_SELECTION);
    resultStage = await nextStep(dispatch, step);
  }

  if (getCallbackWithMessageSafely(stage, MESSAGE_OTP_EXPIRED_ADD_NUMBER)) {
    const secondStep = await chooseResendOrRetryAfterOTPExpiry(dispatch, stage);
    const secondStepChoiceCallback = secondStep.getCallbackOfType(CallbackType.ChoiceCallback);
    secondStepChoiceCallback.setChoiceIndex(DO_IT_LATER_SELECTION);
    resultStage = await nextStep(dispatch, secondStep);
  }
  if (getCallbackWithMessageSafely(resultStage, MESSAGE_OTP_VERIFICATION_FAILED)) {
    gotoMyAccountHandlerAsyncAction(dispatch, { stage: resultStage, ciamHistory, gotoURI, locale, realm, brand });
  }
};

export default doItLaterHandlerAsyncAction;

import ActionTypes from './actionTypes';

export const captureUserNameAction = (userName) => {
  return {
    type: ActionTypes.ADD_USERNAME,
    userName,
  };
};

export const updateFPErrorAction = (error) => {
  return {
    type: ActionTypes.FP_ADDING_ERR,
    error,
  };
};

export const cleanFPErrorAction = () => {
  return {
    type: ActionTypes.FP_CLEAN_ERROR,
  };
};

export const changePasswordUpdateStatus = (passwordUpdateStatus) => {
  return {
    type: ActionTypes.PASSWORD_UPDATE_STATUS,
    passwordUpdateStatus,
  };
};

export const updateMfaPage = (payload) => {
  return {
    type: ActionTypes.UPDATE_MFA_DETAIL,
    payload,
  };
};

import React from 'react';
import { ErrorAlert, FormattedRichMessage, ContentPage, useRichIntl } from 'ciam-self-service-shared';
import Page, { AUTH_TYPE } from 'components/common/Page';
import addNumberAsyncAction from 'utils/loginAuthnTree/addNumberAsyncActionImpl';
import skipAddNumberAsyncAction from 'utils/loginAuthnTree/skipAddNumberAsyncAction';
import { useSelector } from 'react-redux';
import AddMobilePromptForm from './AddMobilePromptForm';
import AnalyticsDataLayer from '../../common/Analytics';
import { ADD_MOBILE_NUMBER } from '../constants';

function AddMobilePrompt() {
  const intl = useRichIntl();

  const userContext = useSelector((state) => state.loginInfo.userContext);
  const firstName = userContext.givenName;
  const stage = useSelector((state) => state.loginInfo.stage);
  const error = useSelector((state) => state.loginInfo.loginError);
  const addNumberLoadingStatus = useSelector((state) => state.loginInfo.addNumberLoadingStatus);
  const doItLaterLoadingStatus = useSelector((state) => state.loginInfo.doItLaterLoadingStatus);

  return (
    <Page
      title={intl.formatMessage({ id: 'signin.addmobile.title' })}
      authType={AUTH_TYPE.UNAUTHENTICATED}
      heading={intl.formatMessage(
        { id: 'signin.addmobile.heading' },
        {
          firstName,
        },
      )}
    >
      <AnalyticsDataLayer pageName={ADD_MOBILE_NUMBER} />
      <ContentPage heading={<FormattedRichMessage id="signin.addmobile.heading" values={{ firstName }} />}>
        <ErrorAlert error={error} />

        <AddMobilePromptForm
          addNumberHandler={addNumberAsyncAction}
          skipAddNumberHandler={skipAddNumberAsyncAction}
          stage={stage}
          addNumberLoadingStatus={addNumberLoadingStatus}
          doItLaterLoadingStatus={doItLaterLoadingStatus}
        />
      </ContentPage>
    </Page>
  );
}

export default AddMobilePrompt;

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useEffect, useDebugValue } from 'react';
import qs from 'qs';

export default function useStoredQueryString(paramName, selector, callback) {
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    const queryStringParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
    if (paramName in queryStringParams) {
      const paramValue = queryStringParams[paramName];
      dispatch(callback(paramValue));
    }
  }, [paramName, callback, dispatch, location]);

  const value = useSelector(selector);
  useDebugValue(value);
  return value;
}

import { CallbackType } from '@forgerock/javascript-sdk/lib';
import { updateStageAction } from 'components/Login/actions';
import executeHiddenCallbackForUserContextRetrieval from './executeHiddenCallbackForUserContextRetrieval';
import nextStep from './handleStep';

// creating the global for use in Window.Ciam API
let deviceFingerprint = '';

window.ciam = {
  fingerprint: async (data) => {
    deviceFingerprint = data;
  },
};

//  function to wait and get device fingerprint
const waitForFingerprint = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error('Timeout while getting device fingerprints'));
    }, 5000);
    const doCheck = () => {
      setTimeout(() => {
        if (deviceFingerprint) {
          resolve(deviceFingerprint);
        } else {
          doCheck();
        }
      }, 10);
    };
    doCheck();
  });
};

const executeHiddenCallbackForDevicePrint = async (dispatch, step) => {
  dispatch(updateStageAction(step));
  const gplusjs = step.getCallbackOfType(CallbackType.TextOutputCallback).getOutputByName('message');
  const script = document.createElement('script');
  script.textContent = gplusjs;
  document.head.appendChild(script);
  //  wait for device fingerprint from backend script
  const data = await waitForFingerprint();

  //  send the fingerprint to backend for validation
  const hiddenValueCallback = step.getCallbackOfType(CallbackType.HiddenValueCallback);
  hiddenValueCallback.setInputValue(data);
  const secondStep = await nextStep(dispatch, step);
  const finalStep = await executeHiddenCallbackForUserContextRetrieval(dispatch, secondStep);
  return finalStep;
};

export default executeHiddenCallbackForDevicePrint;

import { updateOtpCountAction } from 'components/Login/actions';
import { MESSAGE_MFA_CHANNEL, PROMPT_OTP } from 'components/Login/constants';
import { getCallbackWithPromptSafely } from '../authTreeUtils';
import executeHiddenCallbackForUserContextRetrieval from './executeHiddenCallbackForUserContextRetrieval';
import nextStep from './handleStep';

const otpChannelSelectionAsyncAction = async (dispatch, payload) => {
  const { stage, otpchannel, history } = payload;
  const choiceCallback = getCallbackWithPromptSafely(stage, MESSAGE_MFA_CHANNEL);

  if (choiceCallback) {
    dispatch(updateOtpCountAction(0));
    choiceCallback.setChoiceIndex(otpchannel);
    const step = await nextStep(dispatch, stage);
    const secondStep = await executeHiddenCallbackForUserContextRetrieval(dispatch, step);
    if (getCallbackWithPromptSafely(secondStep, PROMPT_OTP)) {
      history.push('/otp');
    }
    sessionStorage.setItem('ciam.backButton-otp', JSON.stringify(secondStep));
  }
};

export default otpChannelSelectionAsyncAction;

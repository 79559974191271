import React from 'react';
import PropTypes from 'prop-types';
import { useIntl, FormattedMessage } from 'react-intl';
import { ContentPage, Link, FormattedRichMessage } from 'ciam-self-service-shared';
import Page, { AUTH_TYPE } from '../../common/Page';
import FormElement from '../../common/FormElement';
import AnalyticsDataLayer from '../../common/Analytics';
import { FU_CONFIRM_PAGE_NAME } from '../constants';
import SigninButton from '../../common/SigninButton';

function ForgotUsernameConfirmPage(props) {
  const { emailOrPhoneValue, resetStage } = props;
  const intl = useIntl();
  const handleTryAgainClick = () => {
    resetStage();
  };
  const emailContentId = 'forgotUsername.confirm.page.contentForEmail';
  const phoneContentId = 'forgotUsername.confirm.page.contentForNumber';
  const isEmail = emailOrPhoneValue && emailOrPhoneValue.includes('@');
  const channelContentId = isEmail ? emailContentId : phoneContentId;
  return (
    <Page
      title={intl.formatMessage({ id: 'forgotUsername.confirm.page.title' })}
      authType={AUTH_TYPE.UNAUTHENTICATED}
      heading={intl.formatMessage({ id: 'forgotUsername.confirm.page.heading' })}
    >
      <AnalyticsDataLayer pageName={FU_CONFIRM_PAGE_NAME} />
      <ContentPage heading={<FormattedMessage id="forgotUsername.confirm.page.heading" />} contentColumns={7}>
        <FormattedRichMessage
          id={channelContentId}
          values={{
            /* eslint-disable react/display-name */
            ForgotUsernameTryAgainLink: (chunks) => (
              /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
              <Link onClick={handleTryAgainClick}>{chunks}</Link>
            ),
            emailOrPhoneValue,
          }}
        />
        <FormElement spacing={2}>
          <SigninButton />
        </FormElement>
      </ContentPage>
    </Page>
  );
}

ForgotUsernameConfirmPage.propTypes = {
  emailOrPhoneValue: PropTypes.string.isRequired,
  resetStage: PropTypes.func.isRequired,
};

export default ForgotUsernameConfirmPage;

// Polyfills - These MUST be the first imports and the order does matter
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'fastestsmallesttextencoderdecoder';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import '@manulife/mux/core/typography/assets/fonts/fonts.css';
import './assets/styles/global.css';
import App from './app';
import client from './restClients/authenticatedBff';

client.get('init-session').then(({ data: { csrfToken } }) => {
  client.defaults.headers['X-CSRF-TOKEN'] = csrfToken;
  const render = (RootComponent) => {
    ReactDOM.render(<RootComponent />, document.getElementById('app'));
  };

  render(App);

  if (module.hot) {
    module.hot.accept('./app', () => {
      // eslint-disable-next-line global-require
      const NewApp = require('./app').default;

      render(NewApp);
    });
  }
});

import { useEffect } from 'react';

const useAdobeScript = () => {
  useEffect(() => {
    const script = document.createElement('script');

    let adobeURL = '//assets.adobedtm.com/b84dbf01908c/35322d9ba5bd/launch-4ffa2830ea87-staging.min.js';

    if (window.REACT_ENV === '') {
      adobeURL = '//assets.adobedtm.com/b84dbf01908c/35322d9ba5bd/launch-04a3708576d1.min.js';
    }

    script.src = adobeURL;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
};

export default useAdobeScript;

export const BFF_SIGNUP = `${window.REACT_APP_BFF_REGISTER_RESPONSE}`;
export const BFF_MATCH_PROFILE = `${window.REACT_APP_BFF_MATCH_PROFILE}`;

export const ARIAMODEL = {
  ariaDescribedBy: 'content',
  ariaLabel: 'Timeout Modal',
  ariaLabelledBy: 'heading',
};

export const CUSTOMSTYLE = {
  modalContentStyle: {
    textAlign: 'center',
  },
};

export const SIGNUP_PAGE_NAME = 'set-up-your-manulife-id';
export const VERIFY_EMAIL_PAGE_NAME = 'verify-your-email';
export const VERIFY_EMAIL_PAGE_TITLE = 'Manulife ID Verify Your Email';
export const VERIFIED_EMAIL_PAGE_NAME = 'manulife-id-email-verified';
export const VERIFIED_EMAIL_PAGE_TITLE = 'Manulife ID Verify Your Email';
export const EVENT_REGISTER_START = 'registration-start';
export const EVENT_REGISTER_ERROR = 'registration-error';
export const EVENT_REGISTER_SUCCESS = 'registration-success';

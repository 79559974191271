import React from 'react';
import { FormattedRichMessage, CIAMLinkButton, ContentPage, useRichIntl, customStyles } from 'ciam-self-service-shared';
import Page, { AUTH_TYPE } from 'components/common/Page';
import { H2, BUTTON_VARIANT } from '@manulife/mux';
import FormElement from 'components/common/FormElement';
import { ABOUT_PAGE_LOAD } from './constants';
import AnalyticsDataLayer from '../common/Analytics';
import SigninButton from '../common/SigninButton';

function AboutManulifeId() {
  const richIntl = useRichIntl();

  return (
    <Page
      title={richIntl.formatMessage({ id: 'aboutmanulifeid.page.title' })}
      heading={richIntl.formatMessage({ id: 'aboutmanulifeid.page.heading' })}
      authType={AUTH_TYPE.UNAUTHENTICATED}
    >
      <AnalyticsDataLayer pageName={ABOUT_PAGE_LOAD} />
      <ContentPage
        title={richIntl.formatMessage({ id: 'aboutmanulifeid.page.title' })}
        heading={<FormattedRichMessage id="aboutmanulifeid.page.heading" />}
      >
        <div>
          <FormattedRichMessage id="aboutmanulifeid.page.paragraph" />
        </div>
        <FormElement spacing={2}>
          <CIAMLinkButton to="/register" customStyle={customStyles.button}>
            <FormattedRichMessage id="aboutmanulifeid.page.gosignup.button" />
          </CIAMLinkButton>
        </FormElement>
        <H2>
          <FormattedRichMessage id="aboutmanulifeid.page.haveid" />
        </H2>
        <FormElement spacing={2}>
          <SigninButton variant={BUTTON_VARIANT.SECONDARY}>
            <FormattedRichMessage id="aboutmanulifeid.page.gosignin.button" />
          </SigninButton>
        </FormElement>
      </ContentPage>
    </Page>
  );
}

export default AboutManulifeId;

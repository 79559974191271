import { CallbackType } from '@forgerock/javascript-sdk/lib';
import nextStep from 'utils/loginAuthnTree/handleStep';
import { FAILED_PSWD_POLICY, PSWD_RESET_RATE_VIOLATION, PWNED_PASSWORD } from 'components/ForgotPassword/constants';
import { updateErrorCode } from 'components/Login/actions';
import removeSessionStorageAttributes from 'utils/removeSessionStorageAttributes';
import { updateErrorInState } from 'ciam-self-service-shared';
import { checkStep } from 'utils/authTree/helpers';
import { getCallbackWithIdSafely, getCallbackOfTypeSafely } from '../authTreeUtils';

const PASSWORD_RESET_INFO = 'pwdResetInfo';

const checkForForbiddenCases = (
  dispatch,
  history,
  pswdChangeResultSuccess,
  pswdChangeResultForbidden,
  pswdChangeResultForbiddenMessage,
  pswdChangeResultOldPassword,
  pswdChangePolicySpecialCombination,
  pswdChangeResultPwnedMessage,
  pwdFailedPswdPolicy,
) => {
  let errorCode;
  // success scenario
  if (pswdChangeResultSuccess !== -1) {
    history.push('/resetpasswordsuccess');
  } else if (
    pswdChangeResultPwnedMessage === -1 &&
    pswdChangeResultForbidden === -1 &&
    pswdChangeResultOldPassword === -1 &&
    pswdChangePolicySpecialCombination === -1
  ) {
    // no flag returned
    errorCode = 'D_601';
  }
  // forbidden message for password reset rate violation
  else if (pswdChangeResultForbiddenMessage !== -1) {
    errorCode = 'D_700';
  } else if (pswdChangePolicySpecialCombination !== -1) {
    //  Special Combination error
    errorCode = 'D_620';
  } else if (pswdChangeResultOldPassword !== -1) {
    //  Old password used
    errorCode = 'D_606';
  } else if (pwdFailedPswdPolicy !== -1) {
    errorCode = 'D_611';
  } else if (pswdChangeResultPwnedMessage !== -1 || pswdChangeResultForbidden !== -1) {
    errorCode = 'D_613';
  } else {
    // nothing to do
  }
  if (errorCode) {
    updateErrorInState(dispatch, errorCode, updateErrorCode);
  }
};
const decideViewForFinalResult = (dispatch, stage, history) => {
  if (stage?.payload?.code === 500 || stage?.payload?.code === 401) {
    updateErrorInState(dispatch, 'D_601', updateErrorCode);
  }
  // retrieving the script from AM which contains flags realted to success/failure and corresponding messages
  const regexFromBackend = '^((?!';
  const messageScriptFromAM = stage.getCallbackOfType(CallbackType.TextOutputCallback).getOutputByName('message');
  const pswdChangeResultSuccess = messageScriptFromAM.indexOf('pwdResetResult": [Status: 200 OK]');
  const pswdChangeResultForbidden = messageScriptFromAM.indexOf('pwdResetResult": [Status: 403 Forbidden]');
  const pswdChangeResultPwnedMessage = messageScriptFromAM.indexOf(PWNED_PASSWORD);
  const pswdChangeResultOldPassword = messageScriptFromAM.indexOf('IS_NEW');
  const pswdChangeResultForbiddenMessage = messageScriptFromAM.indexOf(PSWD_RESET_RATE_VIOLATION);
  const pswdChangePolicySpecialCombination = messageScriptFromAM.indexOf(regexFromBackend);
  const pwdFailedPswdPolicy = messageScriptFromAM.indexOf(FAILED_PSWD_POLICY);

  return checkForForbiddenCases(
    dispatch,
    history,
    pswdChangeResultSuccess,
    pswdChangeResultForbidden,
    pswdChangeResultForbiddenMessage,
    pswdChangeResultOldPassword,
    pswdChangePolicySpecialCombination,
    pswdChangeResultPwnedMessage,
    pwdFailedPswdPolicy,
  );
};

const newPasswordAsyncAction = async (dispatch, payload) => {
  await removeSessionStorageAttributes();
  const { newPassword, stage, history } = payload;
  let secondStep = stage;

  const hiddenValueCallback = getCallbackWithIdSafely(stage, PASSWORD_RESET_INFO);
  if (getCallbackOfTypeSafely(stage, CallbackType.TextOutputCallback) && hiddenValueCallback) {
    hiddenValueCallback.setInputValue(PASSWORD_RESET_INFO);
    secondStep = await nextStep(dispatch, stage);
  }

  const passwordCallback = getCallbackOfTypeSafely(secondStep, CallbackType.PasswordCallback);
  if (passwordCallback) {
    passwordCallback.setPassword(newPassword);
    const finalStep = await nextStep(dispatch, secondStep);
    // handle any uncaught error - request not sent from UI
    checkStep(finalStep, 'D_704');
    decideViewForFinalResult(dispatch, finalStep, history);
  } else {
    updateErrorInState(dispatch, 'D_601', updateErrorCode);
  }
};

export default newPasswordAsyncAction;

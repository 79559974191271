import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'qs';
import * as Redux from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { useLocation } from 'react-router';
import { LoadingPage, usePathBuilder, useRealm, useCIAMContextParam } from 'ciam-self-service-shared';
import Page from '../common/Page';
import { actionCreators } from '../../actions/actions';

function VerifyPage(props) {
  const { verifyInfo, actions } = props;
  const location = useLocation();
  const realm = useRealm();
  const brand = useCIAMContextParam('brand');
  const pathBuilder = usePathBuilder();

  const params = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  useEffect(() => {
    if (params.ui_locales === 'fr-CA') {
      actions.changeLocale({ locale: 'fr-CA', messages: {} });
    }
  });

  useEffect(() => {
    const body = {
      salt: params.salt,
      data: params.data,
      iv: params.iv,
      mac: params.mac,
      lang: params.ui_locales.substr(0, 2),
      realm,
      brand,
    };

    actions.makeRequest(body);
  }, [location, actions, realm, brand, params.salt, params.data, params.iv, params.mac, params.ui_locales]);

  if (verifyInfo.success === 1) {
    return <Redirect to={pathBuilder('/register/verified')} push />;
  }

  if (verifyInfo.success === 2) {
    return <Redirect to={pathBuilder('/register/error')} push />;
  }

  return (
    <Page>
      <LoadingPage />
    </Page>
  );
}

VerifyPage.propTypes = {
  verifyInfo: PropTypes.shape().isRequired,
  actions: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  verifyInfo: state.verifyInfo,
});

const mapDispatchToProps = (dispatch) => ({
  actions: Redux.bindActionCreators(actionCreators, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyPage);

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import errorValue from '../translations/errors.json';

const ERRORS = {
  GLOBAL: {
    GENERAL: 'G_200',
  },
  LOGIN: {
    GENERIC: 'G_200',
    401: 'D_107',
    400: 'D_505',
    500: 'D_505',
  },
  OTP: {
    401: 'D_615',
    TextOutputCallback: 'E_116',
    ChoiceCallback: 'E_116',
  },
  SIGNUP: {
    GENERAL: 'G_200',
    userNameVALID_USERNAME: 'D_504',
    userNameMATCH_REGEXP: 'D_506',
    password: 'D_611',
    breachedpassword: 'D_613',
    SPECIAL_COMBINATION: 'D_620',
  },
  LOGOUT: {
    401: 'G_200',
  },
};

export const SERVICES = {
  LOGIN: 'LOGIN',
  OTP: 'OTP',
  SIGNUP: 'SIGNUP',
  LOGOUT: 'LOGOUT',
};

export const mapErrorCode = (service, err) => {
  if (err) {
    let errorCode;
    const serviceErrorCode = get(err.payload, 'code');
    errorCode = get(ERRORS, `${service}.${serviceErrorCode}`);

    if (serviceErrorCode) {
      errorCode = get(ERRORS, `${service}.${serviceErrorCode}`);
    }

    if (!errorCode) {
      if (get(ERRORS, `${service}.GENERIC`)) {
        return ERRORS[service].GENERIC;
      }
      return ERRORS.GLOBAL.GENERAL;
    }
    return errorCode;
  }
  if (get(ERRORS, `${service}.GENERIC`)) {
    return ERRORS[service].GENERIC;
  }
  return ERRORS.GLOBAL.GENERAL;
};

export const mapOtpErrorCode = (service, err) => {
  if (err) {
    let errorCode;
    const serviceErrorCode = get(err.payload.callbacks[0], 'type');
    errorCode = get(ERRORS, `${service}.${serviceErrorCode}`);

    if (serviceErrorCode) {
      errorCode = get(ERRORS, `${service}.${serviceErrorCode}`);
    }

    if (!errorCode) {
      if (get(ERRORS, `${service}.GENERIC`)) {
        return ERRORS[service].GENERIC;
      }
      return ERRORS.GLOBAL.GENERAL;
    }
    return errorCode;
  }
  if (get(ERRORS, `${service}.GENERIC`)) {
    return ERRORS[service].GENERIC;
  }
  return ERRORS.GLOBAL.GENERAL;
};

const pwnedPasswordErrorMsg =
  'Constraint Violation: The password value for attribute userPassword was found to be unacceptable: The provided password contained a word from the server&#39;s dictionary';

export const mapSignupErrorCode = (service, err) => {
  try {
    if (err) {
      let errorCode;
      let serviceErrorCode = get(err.error, 'code');
      if (serviceErrorCode === 403 && !isEmpty(err.error.detail)) {
        const failedPolicyRequirements = err?.error?.detail?.failedPolicyRequirements || [];
        const failedPolicyRequirement = failedPolicyRequirements[0];
        const propertyName = failedPolicyRequirement?.property;
        serviceErrorCode = propertyName;
        if (propertyName === 'userName') {
          serviceErrorCode = `${propertyName}${failedPolicyRequirement?.policyRequirements[0]?.policyRequirement}`;
        }
        if (propertyName === 'password' && failedPolicyRequirement?.policyRequirements[0]?.regexp === '^((?!') {
          return get(ERRORS, `${service}.SPECIAL_COMBINATION`);
        }
      }
      if (
        serviceErrorCode === 403 &&
        isEmpty(err.error.detail) &&
        err.error.message &&
        err.error.message.indexOf('Cannot use breached password') !== -1
      ) {
        return get(ERRORS, `${service}.breachedpassword`);
      }
      if (serviceErrorCode === 400 && err?.error?.message === pwnedPasswordErrorMsg) {
        return get(ERRORS, `${service}.password`);
      }
      errorCode = get(ERRORS, `${service}.${serviceErrorCode}`);

      if (serviceErrorCode) {
        errorCode = get(ERRORS, `${service}.${serviceErrorCode}`);
      }

      if (!errorCode) {
        if (get(ERRORS, `${service}.GENERIC`)) {
          return ERRORS[service].GENERIC;
        }
        return ERRORS.GLOBAL.GENERAL;
      }
      return errorCode;
    }
    if (get(ERRORS, `${service}.GENERIC`)) {
      return ERRORS[service].GENERIC;
    }
    return ERRORS.GLOBAL.GENERAL;
  } catch {
    return ERRORS.GLOBAL.GENERAL;
  }
};

export const mapLogoutErrorCode = (service, err) => {
  if (err) {
    const serviceErrorCode = get(err.data, 'code');
    const errorCode = get(ERRORS, `${service}.${serviceErrorCode}`);
    if (!errorCode) {
      return ERRORS.GLOBAL.GENERAL;
    }
    return errorCode;
  }
  return ERRORS.GLOBAL.GENERAL;
};

// Function to return error detail for a specific error code from json
// Can be used in the cases to handle error during user flow journeys specific to self service ui
export const mapErrorFromJson = (errCode) =>
  errCode && errorValue[errCode] ? { ...errorValue[errCode], errorCode: errCode } : null;

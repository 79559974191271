import qs from 'qs';
import { useLocation } from 'react-router';

const constructUrlForRedirect = (params) => {
  try {
    const url = new URL(params.goto);

    // overwrite ui_locales in goto if it's present as a param
    const uiLocales = 'ui_locales';
    if (params[uiLocales]) {
      url.searchParams.set(uiLocales, params[uiLocales]);
    }

    return url.toString();
  } catch (err) {
    // If unable to construct a url, then return nothing so the default is used.
    return null;
  }
};
const getSubDomain = (url) => {
  const hostnameArray = url.hostname.split('.');
  const numberOfSubdomains = hostnameArray.length - 2;
  return hostnameArray.length === 2 ? url.hostname : hostnameArray.slice(numberOfSubdomains).join('.');
};

const isGotoNotAuthorized = (url) => {
  const urlObject = new URL(url);
  const domain = getSubDomain(urlObject);
  return !window.REACT_APP_VALID_REDIRECT_SUBDOMAINS?.split(',').includes(domain);
};

const useGoToUrl = () => {
  const location = useLocation();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  if (!params.goto) {
    return null;
  }
  if (isGotoNotAuthorized(params.goto)) {
    return null;
  }

  const goto = constructUrlForRedirect(params);
  return goto;
};

export default useGoToUrl;

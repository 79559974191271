import { CallbackType } from '@forgerock/javascript-sdk/lib';
import { getCallbackOfTypeSafely } from '../authTreeUtils';
import nextStep from './handleStep';

const chooseRetryToSelectSecondOTPFlow = async (dispatch, step, choice) => {
  const textOutputCallback = getCallbackOfTypeSafely(step, CallbackType.TextOutputCallback);
  const confirmationCallback = getCallbackOfTypeSafely(step, CallbackType.ConfirmationCallback);
  if (textOutputCallback && confirmationCallback) {
    confirmationCallback.setInputValue(parseInt(choice, 10));
  }
  const resultStage = await nextStep(dispatch, step);
  return resultStage;
};

export default chooseRetryToSelectSecondOTPFlow;

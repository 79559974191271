import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useRealm, APP_LANGS } from 'ciam-self-service-shared';
import { P, MessageCard } from '@manulife/mux';
import { getAemNotifications } from '../../utils/aemNotificattions';

function AemNotification(props) {
  const { displayPage } = props;
  const realm = useRealm();
  const [notifications, setNotifications] = useState([]);
  const { locale } = useIntl();

  useEffect(() => {
    (async () => {
      const aemResponse = await getAemNotifications();

      // Filter the list based on current realm and page
      if (aemResponse) {
        const filteredMessageList = aemResponse.filter((x) => x && x[realm] && x[displayPage]);
        setNotifications(filteredMessageList);
      }
    })();
  }, [displayPage, realm]);

  return (
    <>
      {notifications.map((element) => (
        <P key={element.titleEn}>
          <MessageCard
            variant={element.messageType.toLowerCase()}
            titleText={locale === APP_LANGS.EN_CA ? element.titleEn : element.titleFr}
          >
            {locale === APP_LANGS.EN_CA ? element.messageEn : element.messageFr}
          </MessageCard>
        </P>
      ))}
    </>
  );
}

AemNotification.propTypes = {
  displayPage: PropTypes.string.isRequired,
};
export default AemNotification;

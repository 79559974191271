import ActionTypes from './actionTypes';

export const confirmEmailSuccess = (firstName) => {
  return {
    type: ActionTypes.CONFIRM_EMAIL_SUCCESS,
    firstName,
  };
};

export const confirmEmailFailure = () => {
  return {
    type: ActionTypes.CONFIRM_EMAIL_FAILURE,
  };
};

import { connect } from 'react-redux';
import confirmEmailAsyncAction from 'utils/confirmMail';
import ConfirmMailView from './views/ConfirmMailView';

export const mapStateToProps = (state) => {
  const { confirmEmailStatus } = state.confirmEmail;
  return {
    confirmEmailStatus,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  confirmHandler: (payload) => dispatch(confirmEmailAsyncAction(payload)),
});

const EmailConfirmPage = connect(mapStateToProps, mapDispatchToProps)(ConfirmMailView);
export default EmailConfirmPage;

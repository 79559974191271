import { CallbackType } from '@forgerock/javascript-sdk/lib';
import nextStep from './handleStep';

const makeChoiceResendOrRetry = async (dispatch, step, choice) => {
  const confirmationCallback = step.getCallbackOfType(CallbackType.ConfirmationCallback);
  confirmationCallback.setInputValue(choice);
  const secondStep = await nextStep(dispatch, step);
  return secondStep;
};

export default makeChoiceResendOrRetry;

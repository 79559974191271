export const CustomCallbackType = {
  RedirectCallback: 'RedirectCallback',
};

export function getCallbackOfTypeSafely(step, callbackType) {
  return step?.callbacks?.find((callback) => callback.getType() === callbackType);
}

export function getCallbackWithOutputSafely(step, outputName, callbackPrompt) {
  return step?.callbacks?.find((callback) => callback.getOutputByName(outputName) === callbackPrompt);
}

export function getCallbackWithMessageSafely(step, callbackMessage) {
  return getCallbackWithOutputSafely(step, 'message', callbackMessage);
}

export function getCallbackWithPromptSafely(step, callbackPrompt) {
  return getCallbackWithOutputSafely(step, 'prompt', callbackPrompt);
}

export function getCallbackWithIdSafely(step, callbackId) {
  return getCallbackWithOutputSafely(step, 'id', callbackId);
}

import ActionTypes from '../actions/actionTypes';
import initState from './initState';

const mfaReducer = (state = initState.mfa, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_MFA_BUTTON_STATUS: {
      const { requestInProgress } = action;
      return {
        ...state,
        requestInProgress,
      };
    }
    default: {
      return state;
    }
  }
};

export default mfaReducer;

import { useCallback, useEffect, useMemo, useState } from 'react';
import userContextUtils from 'utils/userContextUtils';
import { useSelector, useDispatch } from 'react-redux';
import { useCIAMHistory } from 'ciam-self-service-shared';

export default function useSelectChannel(onSelectChannel) {
  const dispatch = useDispatch();
  const history = useCIAMHistory();
  const [displayChoices, setDisplayChoices] = useState(false);
  const stage = useSelector((state) => state.loginInfo.stage);
  const userContext = useSelector((state) => state.loginInfo.userContext);
  const channels = useMemo(() => userContextUtils.getChannels(userContext), [userContext]);

  const handleSelectChannel = useCallback(
    (selectedChannel) => {
      const payload = {
        stage,
        otpchannel: selectedChannel.value,
        history,
      };
      onSelectChannel(dispatch, payload, selectedChannel);
    },
    [onSelectChannel, dispatch, history, stage],
  );

  useEffect(() => {
    const shouldAutoSelect = channels?.length === 1;
    const shouldDisplayChoices = channels?.length > 1;

    setDisplayChoices(shouldDisplayChoices);

    if (shouldAutoSelect) {
      handleSelectChannel(channels[0]);
    }
  }, [handleSelectChannel, channels]);

  return [channels, handleSelectChannel, displayChoices];
}

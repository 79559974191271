const AMtoUIRealmMapping = {
  customer: '/customers',
  advisor: '/advisors',
  sponsor: '/sponsors',
  mortgagecreditor: '/mortgagecreditor',
  travel: '/travel',
};

const urlMapping = {
  user: window.REACT_APP_FR_BASE_URL,
  sponsor: window.REACT_APP_FR_SPONSOR_BASE_URL,
  advisor: window.REACT_APP_FR_ADVISOR_BASE_URL,
  customer: window.REACT_APP_FR_BASE_URL,
  customers: window.REACT_APP_FR_BASE_URL,
  sponsors: window.REACT_APP_FR_SPONSOR_BASE_URL,
  advisors: window.REACT_APP_FR_ADVISOR_BASE_URL,
  mortgagecreditor: window.REACT_APP_FR_MORTGAGECREDITOR_BASE_URL,
  travel: window.REACT_APP_FR_TRAVEL_BASE_URL,
};
const newUrl = (amRealm) => urlMapping[amRealm];
const getAMRealms = (amRealm) => AMtoUIRealmMapping[amRealm];

export { getAMRealms, newUrl };

import client from '../restClients/authenticatedBff';

const BFF_AEM_NOTIFICATIONS = `${window.REACT_APP_BFF_AEM_NOTIFICATIONS}`;

// eslint-disable-next-line import/prefer-default-export
export const getAemNotifications = async () => {
  try {
    const response = await client.get(BFF_AEM_NOTIFICATIONS);
    return response.data;
  } catch {
    return undefined;
  }
};

import chooseResendOrRetryAfterOTPExpiry from '../../../../utils/loginAuthnTree/chooseResendOrRetryAfterOTPExpiry';
import { DIFFERENT_CHANNEL_SELECTION, INVALID_OTP, RESEND_OTP } from '../../../../utils/loginAuthnTree/constants';
import executeHiddenCallbackForOTPAutomatedTesting from '../../../../utils/loginAuthnTree/executeHiddenCallbackForOTPAutomatedTesting';
import makeChoiceResendOrRetry from '../../../../utils/loginAuthnTree/makeChoiceResendOrRetry';
import sameChannelOrDifferent from '../../../../utils/loginAuthnTree/sameChannelOrDifferent';
import { checkStep, submitOtp } from '../commonSteps';
import { isOnEnterOtpStep, isOtpExpired } from '../../../../utils/authTree/helpers';
import { updateErrorCode, updateOTPRequestedMultipleTimes } from 'components/Login/actions';
import { updateErrorInState } from 'ciam-self-service-shared';

export default async function requestNewChannel(dispatch, payload) {
  updateErrorInState(dispatch, null, updateErrorCode);
  const { stage } = payload;
  let currentStep = stage;

  await checkStep(dispatch, currentStep);

  currentStep = await executeHiddenCallbackForOTPAutomatedTesting(dispatch, currentStep);

  if (isOnEnterOtpStep(currentStep)) {
    // Intentionally submit an invalid OTP to move to the next node in the tree
    currentStep = await submitOtp(dispatch, currentStep, INVALID_OTP);
  }

  if (isOtpExpired()) {
    currentStep = await chooseResendOrRetryAfterOTPExpiry(dispatch, currentStep);
    await checkStep(dispatch, currentStep);
  }

  currentStep = await makeChoiceResendOrRetry(dispatch, currentStep, RESEND_OTP);
  await checkStep(dispatch, currentStep);

  currentStep = await sameChannelOrDifferent(dispatch, currentStep, DIFFERENT_CHANNEL_SELECTION);
  await checkStep(dispatch, currentStep);

  dispatch(updateOTPRequestedMultipleTimes(true));
  return currentStep;
}

export const SET_EMBEDDED = 'SET_EMBEDDED';
export const SET_GOTO_EMBEDDED = 'SET_GOTO_EMBEDDED';

export const setEmbedded = (isEmbedded) => ({
  type: SET_EMBEDDED,
  payload: isEmbedded,
});

export const setGoToEmbedded = (goToEmbedded) => ({
  type: SET_GOTO_EMBEDDED,
  payload: goToEmbedded,
});

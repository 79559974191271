export const MESSAGE_TRY_NEW_OTP = 'Do you want to try with new OTP?';
export const MESSAGE_RESEND_OR_RETRY = 'OTP Resend (YES ) or Retry (NO)';
export const MESSAGE_MFA_CHANNEL = 'Select MFA Channel';
export const MESSAGE_ADD_VERIFY_MOBILE = 'Add & Verify Mobile?';
export const MESSAGE_MOBILE_NUMBER = 'Mobile number';
export const PROMPT_OTP_CHOICES = 'what do you want to do for OTP?';
export const MESSAGE_OTP_VERIFICATION_FAILED = 'OTP verification failed for mobile';
export const MESSAGE_ENTER_PASSWORD = 'Enter your password';
export const MESSAGE_LOCKED_ACCOUNT = 'Your Account is locked due to wrong attempts';
export const MESSAGE_OTP_RETRY_LIMIT = 'You have Exceeded the OTP Limit';
export const MESSAGE_OTP_EXPIRED = 'OTP Expired';
export const MESSAGE_OTP_EXPIRED_ADD_NUMBER = 'OTPExpiredAddnumber';
export const PROMPT_OTP = 'One Time Password';
export const GIVEN_NAME = 'givenName';
export const FIRST_TIME_LOGIN = 'firstTimeLoginMobile';
export const OTP_TEST_ACCOUNT = 'otpToAutomaticTestAccount';
export const AUTH_TREE_MESSAGES = {
  AccountActivated: 'Account Activated',
};

export const MAIN_TREE_CUSTOMERS = 'customersAuth';
export const MAIN_TREE_ADVISORS = 'advisorsAuth';
export const MAIN_TREE_SPONSORS = 'authTree';
export const MAIN_TREE_MCI = 'authTree';
export const MAIN_TREE_TRAVEL = 'authTree';
export const FORGOT_PASSWORD_TREE = 'forgotPassword';

export const REMEMBER_USERNAME_COOKIE_NAME = 'remembered-username';
export const REMEMBER_USERNAME_COOKIE_EXPIRATION_DAYS = 90;

export const INVALID_LOGIN_SESSION_NAME = 'invalidLoginCount';

export const EVENT_SIGNIN_START = 'pageLoad';
export const SIGNIN_PAGE_NAME = 'sign-in';
export const EVENT_SIGNIN_ERROR = 'sign-in-error';
export const ACTIVATION_ERROR = 'activation-error';

export const AUTH_TREE_REALM_MAPPING = {
  user: MAIN_TREE_CUSTOMERS,
  sponsor: MAIN_TREE_SPONSORS,
  advisor: MAIN_TREE_ADVISORS,
  customer: MAIN_TREE_CUSTOMERS,
  sponsors: MAIN_TREE_SPONSORS,
  advisors: MAIN_TREE_ADVISORS,
  mortgagecreditor: MAIN_TREE_MCI,
  travel: MAIN_TREE_TRAVEL,
};
export const langCodes = {
  'en-CA': 'en',
  'fr-CA': 'fr',
  en: 'en',
  fr: 'fr',
};

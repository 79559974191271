import React, { useEffect } from 'react';
import { useRichIntl, useRealm, LoadingPage } from 'ciam-self-service-shared';
import redirectInbound from './steps/redirectInbound';
import { secondStepInit } from './steps/init';
import Page, { AUTH_TYPE } from '../../../common/Page';
import useInboundTreeErrorHandler, { useSuccessUrlErrorHandler } from './steps/useInboundTreeErrorHandler';

function Code() {
  const inboundTreeErrorHandler = useInboundTreeErrorHandler();
  const realm = useRealm();
  const richIntl = useRichIntl();
  const authId = sessionStorage.getItem('inboundAuthId');
  const successUrlErrorHandler = useSuccessUrlErrorHandler();

  useEffect(() => {
    (async () => {
      try {
        const { successUrl } = await secondStepInit(realm, authId);
        const inboundRedirect = redirectInbound(successUrl);
        if (inboundRedirect === successUrl) {
          sessionStorage.clear('inboundAuthId');
          window.location.href = successUrl;
        } else {
          sessionStorage.clear('inboundAuthId');
          successUrlErrorHandler();
        }
      } catch (err) {
        inboundTreeErrorHandler(err);
      }
    })();
  }, [realm, authId, inboundTreeErrorHandler, successUrlErrorHandler]);

  return (
    <Page title={richIntl.formatMessage({ id: 'signin.page.title' })} authType={AUTH_TYPE.NONE}>
      <LoadingPage />
    </Page>
  );
}

export default Code;

import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router';
import { SessionTimeoutHandler, useRealm, useCIAMHistory } from 'ciam-self-service-shared';
import { useDispatch } from 'react-redux';
import OnDemandSelectChannelPage from './OnDemandChannelSelectPage';
import OnDemandEnterCodePage from './OnDemandEnterCodePage';
import onDemandAuthTree from '../authTree';
import useGoToUrl from '../../../hooks/useGoToUrl';
import useAuthTreeErrorHandler from '../useAuthTreeErrorHandler';
import Page from '../../common/Page';
import useSessionTimeoutProps from '../../../hooks/useSessionTimeoutProps';
import { OTPOnDemandTrees } from '../authTree/steps/init';
import useOnDemandOtpType from '../useOnDemandOtpType';

function OnDemandOTP() {
  const routeMatch = useRouteMatch();
  const dispatch = useDispatch();
  const history = useCIAMHistory();
  const location = useLocation();
  const type = useOnDemandOtpType();
  const realm = useRealm();
  const goto = useGoToUrl();
  const authTreeErrorHandler = useAuthTreeErrorHandler();
  const [initialized, setInitialized] = useState(false);
  const sessionTimeoutProps = useSessionTimeoutProps();

  const relativePath = routeMatch?.path || '';
  const relativeUrl = routeMatch?.url || '';

  // Whenever the context or realm change, we need to reinitialize the tree
  useEffect(() => {
    setInitialized(false);
  }, [goto, realm]);

  useEffect(() => {
    (async () => {
      try {
        if (!initialized) {
          const tree = OTPOnDemandTrees[type];
          await onDemandAuthTree.init(realm, goto, dispatch, tree);
          setInitialized(true);
          await sessionTimeoutProps.refreshSession();

          if (location.pathname !== relativeUrl) {
            history.push(relativeUrl);
          }
        }
      } catch (err) {
        authTreeErrorHandler(err);
      }
    })();
  }, [
    goto,
    realm,
    dispatch,
    relativePath,
    relativeUrl,
    history,
    location,
    authTreeErrorHandler,
    initialized,
    type,
    sessionTimeoutProps,
  ]);

  if (!initialized) {
    return <Page isLoading />;
  }

  return (
    <>
      <SessionTimeoutHandler {...sessionTimeoutProps} />
      <Switch>
        <Route exact path={`${relativePath}`}>
          <OnDemandSelectChannelPage />
        </Route>
        <Route exact path={`${relativePath}/enter`}>
          <OnDemandEnterCodePage />
        </Route>
        <Route>
          <Redirect to={relativeUrl} />
        </Route>
      </Switch>
    </>
  );
}

export default OnDemandOTP;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { BUTTON_VARIANT } from '@manulife/mux';
import { FormattedRichMessage, CoBrandingButton, customStyles } from 'ciam-self-service-shared';

const ChannelButtonWrapper = styled.div`
  line-height: 20px;
`;

const MFAChannelButton = (props) => {
  const { type, maskedValue, onClick, isLoading, ...otherProps } = props;

  const messageId = `mfa.${type}ChannelButton${maskedValue ? '.withMask' : ''}`;

  return (
    <div>
      <CoBrandingButton
        variant={BUTTON_VARIANT.SIGN_IN}
        customStyle={customStyles.button}
        onClick={onClick}
        disabled={isLoading}
        saving={isLoading}
        {...otherProps}
      >
        <ChannelButtonWrapper>
          <FormattedRichMessage
            id={messageId}
            values={{
              maskedValue,
              disclaimer: function Disclaimer(...chunks) {
                return <span style={{ fontWeight: 'normal' }}>{chunks}</span>;
              },
            }}
          />
        </ChannelButtonWrapper>
      </CoBrandingButton>
    </div>
  );
};

MFAChannelButton.propTypes = {
  type: PropTypes.oneOf(['phone', 'email']).isRequired,
  onClick: PropTypes.func.isRequired,
  maskedValue: PropTypes.string,
  isLoading: PropTypes.bool,
};

MFAChannelButton.defaultProps = {
  maskedValue: '',
  isLoading: false,
};

export default MFAChannelButton;

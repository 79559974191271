import { Switch, Route, useRouteMatch } from 'react-router';
import * as React from 'react';
import PropTypes from 'prop-types';
import { StageShape } from 'ciam-self-service-shared';
import ErrorPage from './ErrorPage';
import AddMobilePrompt from './AddMobilePrompt';

function AddMobileView(props) {
  const { addNumberHandler, skipAddNumberHandler, stage, addNumberLoadingStatus, doItLaterLoadingStatus } = props;
  const path = useRouteMatch()?.path || '';

  return (
    <Switch>
      <Route path={`${path}/error`}>
        <ErrorPage />
      </Route>
      <Route path={`${path}`}>
        <AddMobilePrompt
          addNumberHandler={addNumberHandler}
          skipAddNumberHandler={skipAddNumberHandler}
          stage={stage}
          addNumberLoadingStatus={addNumberLoadingStatus}
          doItLaterLoadingStatus={doItLaterLoadingStatus}
        />
      </Route>
    </Switch>
  );
}

AddMobileView.propTypes = {
  addNumberHandler: PropTypes.func.isRequired,
  skipAddNumberHandler: PropTypes.func.isRequired,
  stage: StageShape.isRequired,
  addNumberLoadingStatus: PropTypes.bool,
  doItLaterLoadingStatus: PropTypes.bool,
};

export default AddMobileView;

import ActionTypes from '../actions/actionTypes';
import initState from './initState';

const registrationSuccessReducer = (state = initState.registrationSuccessInfo, action) => {
  switch (action.type) {
    case ActionTypes.INIT_STORE: {
      return action.registrationSuccessInfo;
    }

    case ActionTypes.REGISTRATION_ADDITIONAL_STEP: {
      const registerSuccess = action.payload;
      return registerSuccess;
    }

    default: {
      return state;
    }
  }
};

export default registrationSuccessReducer;

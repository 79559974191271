import { useCallback } from 'react';
import { usePathBuilder } from 'ciam-self-service-shared';
import { useHistory } from 'react-router';
import AuthTreeError from '../../utils/authTree/AuthTreeError';
import { OnDemandOTPRedirectError, OnDemandOTPUnauthenticatedError } from './authTree/errors';

export default function useAuthTreeErrorHandler() {
  const history = useHistory();
  const pathBuilder = usePathBuilder();

  return useCallback(
    (error) => {
      if (error instanceof OnDemandOTPRedirectError) {
        // Nothing as redirect will occur
      } else if (error instanceof OnDemandOTPUnauthenticatedError || error instanceof AuthTreeError) {
        const path = pathBuilder('/signin', true, { searchParams: { goto: undefined } });
        history.push(path);
      } else {
        throw error;
      }
    },
    [history, pathBuilder],
  );
}

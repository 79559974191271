import React, { useEffect } from 'react';
import { useRichIntl, LoadingPage, useRealm } from 'ciam-self-service-shared';
import redirectInbound from './steps/redirectInbound';
import inboundSignin from './steps/init';
import Page, { AUTH_TYPE } from '../../../common/Page';
import useInboundTreeErrorHandler, { useSuccessUrlErrorHandler } from './steps/useInboundTreeErrorHandler';

function OidcPage() {
  const inboundTreeErrorHandler = useInboundTreeErrorHandler();
  const richIntl = useRichIntl();
  const realm = useRealm();
  const successUrlErrorHandler = useSuccessUrlErrorHandler();

  useEffect(() => {
    (async () => {
      try {
        const { authId, redirectCallback } = await inboundSignin(realm);
        sessionStorage.setItem('inboundAuthId', authId);
        const azureRedirect = redirectInbound(redirectCallback);
        if (azureRedirect === redirectCallback) {
          window.location.href = azureRedirect;
        } else {
          successUrlErrorHandler();
        }
      } catch (error) {
        inboundTreeErrorHandler(error);
      }
    })();
  }, [realm, inboundTreeErrorHandler, successUrlErrorHandler]);

  return (
    <Page title={richIntl.formatMessage({ id: 'signin.page.title' })} authType={AUTH_TYPE.NONE}>
      <LoadingPage />
    </Page>
  );
}

export default OidcPage;

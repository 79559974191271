import nextStep from 'utils/loginAuthnTree/handleStep';
import { AuthTreeMessages, DOB_LASTNAME_NOT_FOUND } from 'components/ForgotPassword/constants';
import { updateFPErrorAction } from 'components/ForgotPassword/actions';
import removeSessionStorageAttributes from 'utils/removeSessionStorageAttributes';
import { CallbackType } from '@forgerock/javascript-sdk/lib';
import { updateErrorInState } from 'ciam-self-service-shared';
import { checkStep } from 'utils/authTree/helpers';
import { getCallbackOfTypeSafely, getCallbackWithMessageSafely, getCallbackWithPromptSafely } from '../authTreeUtils';

const handleFinalResponse = async (dispatch, finalStep, history) => {
  const lastNameNotFoundCallback = getCallbackWithMessageSafely(finalStep, DOB_LASTNAME_NOT_FOUND);
  const passwordCallback = getCallbackOfTypeSafely(finalStep, CallbackType.PasswordCallback);

  if (lastNameNotFoundCallback) {
    updateErrorInState(dispatch, 'D_619', updateFPErrorAction);
  } else if (passwordCallback) {
    history.push('/inputpassword');
  } else {
    // handle any uncaught error - request not sent from UI
    checkStep(null, 'D_704');
  }
};

const userInfoAsyncAction = async (dispatch, payload) => {
  await removeSessionStorageAttributes();
  const { lastName, dob, stage, history } = payload;

  const lastNameCallback = getCallbackWithPromptSafely(stage, AuthTreeMessages.LastName);
  const dobCallback = getCallbackWithPromptSafely(stage, AuthTreeMessages.DateOfBirth);

  if (lastNameCallback && dobCallback) {
    lastNameCallback.setName(lastName);
    dobCallback.setName(dob);
    const finalStep = await nextStep(dispatch, stage);
    await handleFinalResponse(dispatch, finalStep, history);
  } else {
    // handle any uncaught error - request not sent from UI - page refresh
    checkStep(null, 'D_704');
  }
};

export default userInfoAsyncAction;

import buildUrl from './buildUrl';
import firstStep, { secondStep } from './steps';

export default async function inboundSignin(realm) {
  const encodedURI = await buildUrl();
  try {
    const { authId, redirectCallback } = await firstStep(encodedURI, realm);
    return { authId, redirectCallback };
  } catch (err) {
    return null;
  }
}

export async function secondStepInit(realm, authId) {
  try {
    const { successUrl } = await secondStep(realm, authId);
    return { successUrl };
  } catch (err) {
    return null;
  }
}

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { BUTTON_VARIANT, Modal, H3 } from '@manulife/mux';
import { FormattedRichMessage, RealmSpecific, REALMS, CoBrandingButton } from 'ciam-self-service-shared';
import styled from 'styled-components';
import muxCustomStyles from '../../../assets/styles/muxCustomStyles';

const ButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem;

  & > button {
    flex: 1 1 0;
    margin: 0.5rem;
  }
`;

function SignUpDuplicateWarningModal(props) {
  const { isOpen, onSignin, onCreateAnyway, onForgotUsername } = props;
  return (
    <Modal isOpen={isOpen} showCloseButton={false}>
      <H3>
        <FormattedRichMessage id="signup.duplicateModal.title" />
      </H3>

      <RealmSpecific showFor={[REALMS.CUSTOMER]}>
        <FormattedRichMessage id="signup.duplicateModal.content.customer" />
      </RealmSpecific>
      <RealmSpecific hideFor={[REALMS.CUSTOMER]}>
        <FormattedRichMessage id="signup.duplicateModal.content" />
      </RealmSpecific>

      <ButtonWrapper>
        <CoBrandingButton variant={BUTTON_VARIANT.SECONDARY} onClick={onForgotUsername}>
          <FormattedMessage id="signup.duplicateModal.forgotUsername" />
        </CoBrandingButton>
        <CoBrandingButton onClick={onSignin}>
          <FormattedMessage id="common.signin" />
        </CoBrandingButton>
      </ButtonWrapper>
      <CoBrandingButton
        onClick={onCreateAnyway}
        variant={BUTTON_VARIANT.TERTIARY}
        customStyle={muxCustomStyles.tertiaryButton}
        id="create-anyway"
      >
        <FormattedRichMessage id="signup.duplicateModal.createAnyway" />
      </CoBrandingButton>
    </Modal>
  );
}

SignUpDuplicateWarningModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onSignin: PropTypes.func.isRequired,
  onCreateAnyway: PropTypes.func.isRequired,
  onForgotUsername: PropTypes.func.isRequired,
};

export default SignUpDuplicateWarningModal;

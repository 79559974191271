import * as React from 'react';
import { connect } from 'react-redux';
import AboutManulifeId from '../AboutManulifeId';

const AboutManulifeIdPage = () => {
  return <AboutManulifeId />;
};

const mapStateToProps = (state) => {
  return {
    intl: state.intl,
  };
};

export default connect(mapStateToProps)(AboutManulifeIdPage);

import { CallbackType } from '@forgerock/javascript-sdk/lib';
import { updateOtpCountAction, updateOTPRequestedMultipleTimes, updateErrorCode } from 'components/Login/actions';
import { checkStep } from 'utils/authTree/helpers';
import { MESSAGE_OTP_EXPIRED, MESSAGE_OTP_RETRY_LIMIT } from 'components/Login/constants';
import removeSessionStorageAttributes from 'utils/removeSessionStorageAttributes';
import { updateErrorInState } from 'ciam-self-service-shared';
import { INVALID_OTP, RESEND_OTP, SAME_CHANNEL_SELECTION } from './constants';
import nextStep from './handleStep';
import makeChoiceResendOrRetry from './makeChoiceResendOrRetry';
import sameChannelOrDifferent from './sameChannelOrDifferent';
import chooseResendOrRetryAfterOTPExpiry from './chooseResendOrRetryAfterOTPExpiry';
import executeHiddenCallbackForOTPAutomatedTesting from './executeHiddenCallbackForOTPAutomatedTesting';
import handleBackButtonClick from './handleBackButtonClick';
import { getCallbackOfTypeSafely, getCallbackWithMessageSafely } from '../authTreeUtils';

const executeFinalStepsForResendOtp = async (dispatch, step, history, pathBuilder) => {
  let resultStage = null;
  const secondStep = await makeChoiceResendOrRetry(dispatch, step, RESEND_OTP);
  checkStep(secondStep, 'D_704');
  if (getCallbackWithMessageSafely(secondStep, MESSAGE_OTP_RETRY_LIMIT)) {
    await removeSessionStorageAttributes();
    updateErrorInState(dispatch, 'D_622', updateErrorCode);
    history.push(pathBuilder('/signin', true));
  } else {
    resultStage = await sameChannelOrDifferent(dispatch, secondStep, SAME_CHANNEL_SELECTION);
    checkStep(resultStage, 'D_704');
  }
  return resultStage;
};

const resendOtpAsyncAction = async (dispatch, payload) => {
  dispatch(updateErrorCode(null));
  let resultStage = null;
  let { stage } = payload;
  const { realm, brand, history, pathBuilder } = payload;
  dispatch(updateOtpCountAction(0));
  dispatch(updateOTPRequestedMultipleTimes(true));
  sessionStorage.setItem('isOTPGeneratedMultipleTimes', true);

  // after clicking back button from email account
  stage = await handleBackButtonClick(stage, realm, brand, 'ciam.backButton-otp');
  checkStep(stage, 'D_704');
  if (getCallbackWithMessageSafely(stage, MESSAGE_OTP_RETRY_LIMIT)) {
    await removeSessionStorageAttributes();
    updateErrorInState(dispatch, 'D_622', updateErrorCode);
    history.push(pathBuilder('/signin', true, { searchParams: { error_code: 'D_622' } }));
  }

  const passwordCallback = getCallbackOfTypeSafely(stage, CallbackType.PasswordCallback);
  if (passwordCallback) {
    passwordCallback.setPassword(INVALID_OTP);
    const step = await nextStep(dispatch, stage);
    checkStep(step, 'D_704');
    resultStage = await executeFinalStepsForResendOtp(dispatch, step, history, pathBuilder);
  } else if (getCallbackWithMessageSafely(stage, MESSAGE_OTP_EXPIRED)) {
    const secondStep = await chooseResendOrRetryAfterOTPExpiry(dispatch, stage);
    checkStep(secondStep, 'D_704');
    resultStage = await executeFinalStepsForResendOtp(dispatch, secondStep, history, pathBuilder);
  } else {
    resultStage = await executeFinalStepsForResendOtp(dispatch, stage, history, pathBuilder);
  }
  const finalStep = await executeHiddenCallbackForOTPAutomatedTesting(dispatch, resultStage);
  if (finalStep && Object.keys(finalStep).length !== 0) {
    sessionStorage.setItem('ciam.backButton-otp', JSON.stringify(finalStep));
  }
};

export default resendOtpAsyncAction;

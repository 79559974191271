import { useState } from 'react';
import dayjs from 'dayjs';

const hasDateParts = (dateParts) => {
  return dateParts && 'day' in dateParts && 'month' in dateParts && 'year' in dateParts;
};

const extractDatePartsFromDate = (rawDate) => {
  const dateOnly = rawDate.split('T')[0];
  const date = dayjs(dateOnly, 'YYYY-MM-DD');
  if (!rawDate || !date.isValid()) {
    return { day: undefined, month: undefined, year: undefined };
  }

  return {
    day: date.date(),
    month: date.format('MMMM').toLowerCase(),
    year: date.year(),
  };
};

const getDateParts = (date, dateParts) => {
  if (date && !hasDateParts(dateParts)) {
    return extractDatePartsFromDate(date);
  }

  if (!hasDateParts(dateParts)) {
    return { day: undefined, month: undefined, year: undefined };
  }

  return dateParts;
};

/**
 * Returns a stateful date of birth parts, date of birth, and a function to update the state
 */
export default function useDateOfBirthState(initialDateOfBirthParts, initalDateOfBirth) {
  const safeInitialDateOfBirthParts = getDateParts(initalDateOfBirth, initialDateOfBirthParts);
  const [dateOfBirthParts, setDateOfBirthParts] = useState(safeInitialDateOfBirthParts);
  const [dateOfBirth, setDateOfBirth] = useState(initalDateOfBirth);

  const handleChange = (value) => {
    const { date, ...rawDateParts } = value || {};
    const safeDateParts = getDateParts(date, rawDateParts);

    setDateOfBirthParts(safeDateParts);
    setDateOfBirth(date);
  };

  return [dateOfBirthParts, dateOfBirth, handleChange];
}

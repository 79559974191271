import ActionTypes from './actionTypes';
import initState from '../../reducers/initState';

// eslint-disable-next-line import/prefer-default-export
export const forgotPasswordInfo = (state = initState.forgotPasswordInfo, action) => {
  switch (action.type) {
    case ActionTypes.INIT_STORE: {
      return action.forgotPasswordInfo;
    }
    case ActionTypes.UPDATE_STAGE: {
      const { stage } = action;
      return {
        ...state,
        stage,
      };
    }
    case ActionTypes.ADD_USERNAME: {
      const { userName } = action;
      return {
        ...state,
        userName,
      };
    }
    case ActionTypes.FP_ADDING_ERR: {
      const { error } = action;
      return {
        ...state,
        error,
      };
    }
    case ActionTypes.FP_CLEAN_ERROR: {
      return {
        ...state,
        error: null,
      };
    }
    case ActionTypes.PASSWORD_UPDATE_STATUS: {
      const { passwordUpdateStatus } = action;
      return {
        ...state,
        passwordUpdateStatus,
      };
    }
    default: {
      return state;
    }
  }
};

export const pageInfo = (state = { redirect: false, mfaDetail: false }, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_MFA_DETAIL: {
      return {
        ...state,
        mfaDetail: action.payload.mfaDetail,
      };
    }
    default: {
      return state;
    }
  }
};

const CHANNEL_LIST_TYPES = {
  mobile: 'Mobile',
  primaryEmail: 'Primary email',
  secondaryEmail: 'Secondary email',
};

const CHANNEL_TYPE = {
  phone: 'phone',
  email: 'email',
  backupemail: 'backupemail',
};

const getMaskedValue = (value) => {
  const isFullyMasked = !value || /^\*+$/.test(value);
  if (isFullyMasked) {
    return null;
  }
  return value;
};

const getChannels = (userContext) => {
  const channels = [];
  const channelList = userContext?.channelList || [];

  if (channelList.includes(CHANNEL_LIST_TYPES.mobile)) {
    channels.push({
      type: CHANNEL_TYPE.phone,
      maskedValue: getMaskedValue(userContext?.mobile),
      value: 0,
    });
  }
  if (channelList.includes(CHANNEL_LIST_TYPES.primaryEmail)) {
    channels.push({
      type: CHANNEL_TYPE.email,
      maskedValue: getMaskedValue(userContext?.mail),
      value: 1,
    });
  }
  if (channelList.includes(CHANNEL_LIST_TYPES.secondaryEmail)) {
    channels.push({
      type: CHANNEL_TYPE.backupemail,
      maskedValue: getMaskedValue(userContext?.secondaryMail),
      value: 2,
    });
  }
  return channels;
};

const getSelectedChannel = (userContext) => {
  const channel = userContext?.channel;
  if (channel === 'sms') {
    return {
      type: CHANNEL_TYPE.phone,
      maskedValue: getMaskedValue(userContext?.mobile),
      value: 0,
    };
  }

  if (channel === 'primaryEmail') {
    return {
      type: CHANNEL_TYPE.email,
      maskedValue: getMaskedValue(userContext?.mail),
      value: 1,
    };
  }

  if (channel === 'secondaryEmail') {
    return {
      type: CHANNEL_TYPE.backupemail,
      maskedValue: getMaskedValue(userContext?.secondaryMail),
      value: 2,
    };
  }

  return null;
};

const isValidCode = (code) => {
  return !code || (code?.toString() !== '' && !/^[0-9]{6}$/.test(code));
};

export default {
  getChannels,
  getSelectedChannel,
  isValidCode,
};

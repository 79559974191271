import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  ContentPage,
  FormattedRichMessage,
  Form,
  UsernameInput,
  USERNAME_VARIANTS,
  PasswordInput,
  PASSWORD_INPUT_VARIANTS,
  useRichIntl,
  useCIAMHistory,
  ErrorAlert,
  useRealm,
  CoBrandingButton,
  useCIAMContextParam,
  customStyles,
} from 'ciam-self-service-shared';
import { ACTIVATION_ERROR } from 'components/Login/constants';
import Page, { AUTH_TYPE } from '../../common/Page';
import FormElement from '../../common/FormElement';

import useOtpState from '../../../hooks/useOtpState';
import useAuthTreeErrorHandler from '../authTree/useAuthTreeErrorHandler';
import AnalyticsDataLayer from '../../common/Analytics';
import signinforOTP from '../authTree/steps/ActivationCredentialsPageValidation';

function ActivationCredentialsPage() {
  const intl = useRichIntl();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const history = useCIAMHistory();
  const dispatch = useDispatch();
  const { error } = useOtpState();
  const realm = useRealm();
  const brand = useCIAMContextParam('brand');
  const [submitting, setSubmitting] = useState(false);
  const authTreeErrorHandler = useAuthTreeErrorHandler();

  const handleSubmit = useCallback(() => {
    (async () => {
      setSubmitting(true);
      try {
        const userInfoRes = await signinforOTP(dispatch, {
          username,
          password,
          realm,
          brand,
        });
        if (userInfoRes.includes('ciamUniqueID')) {
          const userInfo = JSON.parse(userInfoRes);
          sessionStorage.setItem(`userName-${realm}`, username);
          sessionStorage.setItem(`ciamId-${realm}`, userInfo.ciamUniqueID);
        }
        history.push('/activation');
      } catch (err) {
        authTreeErrorHandler(err);
      } finally {
        setSubmitting(false);
      }
    })();
  }, [realm, brand, dispatch, username, password, history, authTreeErrorHandler]);

  return (
    <Page
      title={intl.formatMessage({ id: 'activation.credentials.page.title' })}
      authType={AUTH_TYPE.UNAUTHENTICATED}
      heading={intl.formatMessage({ id: 'activation.credentials.page.heading' })}
    >
      {error?.errorCode && <AnalyticsDataLayer eventName={ACTIVATION_ERROR} error={error} ignoreSession />}
      <ContentPage heading={<FormattedRichMessage id="activation.credentials.page.heading" />}>
        <ErrorAlert error={error} />
        <FormattedRichMessage id="activation.credentials.page.content" />
        <Form onSubmit={handleSubmit}>
          <FormElement>
            <UsernameInput
              variant={USERNAME_VARIANTS.PROMPT}
              id="username"
              value={username}
              onChange={setUsername}
              required
              ariaRequired
              hideForgotUsername
            />
          </FormElement>
          <FormElement>
            <PasswordInput
              variant={PASSWORD_INPUT_VARIANTS.PROMPT}
              id="password"
              value={password}
              onChange={setPassword}
              required
              ariaRequired
            />
          </FormElement>
          <FormElement>
            <CoBrandingButton type="submit" saving={submitting} disabled={submitting} customStyle={customStyles.button}>
              <FormattedRichMessage id="activation.credentials.page.submit" />
            </CoBrandingButton>
          </FormElement>
        </Form>
      </ContentPage>
    </Page>
  );
}

export default ActivationCredentialsPage;

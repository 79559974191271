import { Config } from '@forgerock/javascript-sdk/lib';
import { updateErrorCode } from 'components/Login/actions';
import { updateErrorInState } from 'ciam-self-service-shared';
import determineConfig from '../../../../utils/frAuthConfig';
import { nextStepWithChecks } from './commonSteps';

const ACCOUNT_ACTIVATION_TREE_NAME = 'accountActivation';

export default async function init(realm, goto, brand, dispatch) {
  updateErrorInState(dispatch, null, updateErrorCode);

  Config.set({ ...determineConfig(ACCOUNT_ACTIVATION_TREE_NAME, realm, brand) });

  return nextStepWithChecks(dispatch);
}

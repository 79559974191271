import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { P, TextLink } from '@manulife/mux';
import {
  FormattedRichMessage,
  RealmSpecific,
  ContentPage,
  useCoBranding,
  useRealm,
  REALMS,
} from 'ciam-self-service-shared';
import Page from '../common/Page';
import { CONTACT_US } from './constant';
import AnalyticsDataLayer from '../common/Analytics';
import ContactPanel from './ContactPanel';

// See https://confluence.manulife.ca/display/CETES/Contact+Us
const ContactProducts = {
  [REALMS.CUSTOMER]: [
    'groupBenefits',
    'groupRetirement',
    'insuranceSecureSite',
    'bank',
    'manulifeSecurities',
    'manulifeInvestments',
    'manulifeVitality',
    'manulifePrivateWealth',
    'secureServe',
  ],
  [REALMS.ADVISOR]: [
    'advisorPortal',
    'groupRetirement',
    'groupRetirementVIP',
    'sellHealthPlans',
    'investmentsAdvisorSite',
    'travelInsurance',
  ],
  [REALMS.SPONSOR]: ['groupBenefits', 'groupRetirement', 'groupRetirementVIP'],
  [REALMS.MCI]: ['mortgageProtectionPlan', 'creditSecurityPlan'],
};

function Contact() {
  const intl = useIntl();
  const realm = useRealm();
  const coBranding = useCoBranding();

  const products = ContactProducts[realm] || [];

  if (coBranding?.brand?.contactUrl) {
    window.location.replace(coBranding?.brand?.contactUrl);
    return null;
  }

  return (
    <Page
      title={intl.formatMessage({ id: 'contact.page.title' })}
      heading={intl.formatMessage({ id: 'contact.page.heading' })}
    >
      <AnalyticsDataLayer pageName={CONTACT_US} />
      <ContentPage heading={<FormattedMessage id="contact.page.heading" />} contentColumns={7}>
        {products.map((product) => (
          <ContactPanel key={product} product={product} />
        ))}

        <RealmSpecific showFor={[REALMS.CUSTOMER, REALMS.ADVISOR, REALMS.SPONSOR]}>
          <P>
            <FormattedRichMessage
              id="contact.page.fallback"
              values={{
                ManulifeCaContactUsLink: function ManulifeCaContactUsLink(chunks) {
                  return (
                    <TextLink isExternal to={intl.formatMessage({ id: `contact.page.fallback.${realm}.contactUrl` })}>
                      {chunks}
                    </TextLink>
                  );
                },
                ManulifeCaLink: function ManulifeCaLink(chunks) {
                  return (
                    <TextLink
                      isExternal
                      to={intl.formatMessage({ id: `contact.page.fallback.${realm}.manulifeCaUrl` })}
                    >
                      {chunks}
                    </TextLink>
                  );
                },
              }}
            />
          </P>
        </RealmSpecific>
      </ContentPage>
    </Page>
  );
}

export default Contact;

import React from 'react';
import { P } from '@manulife/mux';
import Page from 'components/common/Page';

const HealthCheckPage = () => {
  return (
    <Page>
      <P>Identity Management is up</P>
    </Page>
  );
};

export default HealthCheckPage;

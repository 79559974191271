import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { RadioGroup } from '@manulife/mux';

function LanguageSelection(props) {
  const intl = useIntl();
  const { legendName, preferredLanguage, onChange } = props;

  const radioValues = [
    {
      label: intl.formatMessage({ id: 'signup.personalInfo.languageRadioValueEN' }),
      value: 'en',
    },
    {
      label: intl.formatMessage({ id: 'signup.personalInfo.languageRadioValueFR' }),
      value: 'fr',
    },
  ];
  return (
    <>
      <RadioGroup
        name="preferredLanguage"
        values={radioValues}
        controlledSelected={preferredLanguage}
        onChange={onChange}
        legend={intl.formatMessage({ id: legendName })}
      />
    </>
  );
}

LanguageSelection.propTypes = {
  legendName: PropTypes.string.isRequired,
  preferredLanguage: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default LanguageSelection;

import ActionTypes from '../actions/actionTypes';
import initState from './initState';

const otpReducer = (state = initState.otp, action) => {
  switch (action.type) {
    case ActionTypes.OTP_SUCCESS: {
      const otpRes = action.payload;
      return {
        ...state,
        sendStatus: true,
        primaryEmail: otpRes.primaryEmail,
        secondaryEmail: otpRes.secondaryEmail,
        mobileNumber: otpRes.mobileNumber,
      };
    }
    default: {
      return state;
    }
  }
};

export default otpReducer;

import ActionTypes from './actionTypes';

export const startRegistrationAction = (payload) => {
  return {
    type: ActionTypes.START_REGISTRATION,
    payload,
  };
};

export const completeRegistrationAction = (payload) => {
  return {
    type: ActionTypes.COMPLETE_REGISTRARTION,
    payload,
  };
};

export const registerFailureAction = (payload) => {
  return {
    type: ActionTypes.REGISTER_FAILURE,
    payload,
  };
};

export const registrationAdditionalStepAction = (payload) => {
  return {
    type: ActionTypes.REGISTRATION_ADDITIONAL_STEP,
    payload,
  };
};

import { updateOTPRequestedMultipleTimes } from 'components/Login/actions';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import userContextUtils from '../utils/userContextUtils';

export default function useOtpState() {
  const dispatch = useDispatch();
  const stage = useSelector((state) => state.loginInfo.stage);
  const otpCount = useSelector((state) => state.loginInfo.otpCount);
  const isOTPGeneratedMultipleTimes = useSelector((state) => state.loginInfo.isOTPGeneratedMultipleTimes);
  const isLoginAfterError = useSelector((state) => state.loginInfo.isLoginAfterError);
  const error = useSelector((state) => state.loginInfo.loginError);
  const userContextFromStore = useSelector((state) => state.loginInfo.userContext);
  const [userContext, setUserContext] = useState(userContextFromStore);
  const channels = useMemo(() => userContextUtils.getChannels(userContext), [userContext]);
  const selectedChannel = useMemo(() => userContextUtils.getSelectedChannel(userContext), [userContext]);

  useEffect(() => {
    const shouldUseContextFromSession = Object.keys(stage).length === 0 && !selectedChannel;
    if (shouldUseContextFromSession) {
      const contextFromSession = sessionStorage.getItem('context');
      const newUserContext = JSON.parse(contextFromSession);
      setUserContext(newUserContext);
    }
  }, [selectedChannel, stage]);

  useEffect(() => {
    dispatch(updateOTPRequestedMultipleTimes(false));
  }, [dispatch]);

  const otpState = useMemo(
    () => ({
      stage,
      otpCount,
      isOTPGeneratedMultipleTimes,
      isLoginAfterError,
      channels,
      selectedChannel,
      error,
    }),
    [channels, error, isLoginAfterError, isOTPGeneratedMultipleTimes, otpCount, selectedChannel, stage],
  );

  return otpState;
}

import { CallbackType, StepType } from '@forgerock/javascript-sdk/lib';
import { updateErrorCode } from 'components/Login/actions';
import { updateErrorInState } from 'ciam-self-service-shared';
import chooseResendOrRetryAfterOTPExpiry from '../../../../utils/loginAuthnTree/chooseResendOrRetryAfterOTPExpiry';
import { RETRY_OTP } from '../../../../utils/loginAuthnTree/constants';
import makeChoiceResendOrRetry from '../../../../utils/loginAuthnTree/makeChoiceResendOrRetry';
import AuthTreeError from '../../../../utils/authTree/AuthTreeError';

import { checkStep, submitOtp } from '../commonSteps';
import {
  isNewOtpRequired,
  isOtpExpired,
  isOtpRetryLimitReached,
  redirectIfRequired,
} from '../../../../utils/authTree/helpers';
import { getCallbackOfTypeSafely } from '../../../../utils/authTreeUtils';

async function handleSubmitCodeResult(dispatch, step) {
  let currentStep = step;

  if (step.type === StepType.LoginSuccess) {
    window.location.href = step.payload.successUrl;
  }

  if (redirectIfRequired(currentStep)) {
    return currentStep;
  }

  await checkStep(dispatch, step);

  const textOutputCallback = getCallbackOfTypeSafely(currentStep, CallbackType.TextOutputCallback);

  if (isNewOtpRequired(currentStep)) {
    updateErrorInState(dispatch, 'D_614', updateErrorCode);
  } else if (isOtpRetryLimitReached(currentStep)) {
    throw new AuthTreeError('max otp limit', currentStep);
  } else if (textOutputCallback && isOtpExpired(textOutputCallback)) {
    // When the user enters an expired OTP, still allow retrying in case they had multiple OTPs out.
    currentStep = await chooseResendOrRetryAfterOTPExpiry(dispatch, currentStep);
    await checkStep(dispatch, step);
    currentStep = await makeChoiceResendOrRetry(dispatch, currentStep, RETRY_OTP);
    await checkStep(dispatch, step);
    updateErrorInState(dispatch, 'D_610', updateErrorCode);
  } else if (textOutputCallback && !isOtpExpired(textOutputCallback)) {
    // When the user enters an invalid OTP, allow retrying.
    currentStep = await makeChoiceResendOrRetry(dispatch, currentStep, RETRY_OTP);
    await checkStep(dispatch, currentStep);
    updateErrorInState(dispatch, 'E_116', updateErrorCode);
  } else {
    // Do nothing
  }

  if (isNewOtpRequired(currentStep)) {
    updateErrorInState(dispatch, 'D_614', updateErrorCode);
  }

  return currentStep;
}

export default async function submitCode(dispatch, payload) {
  updateErrorInState(dispatch, null, updateErrorCode);

  const { stage, otp } = payload;
  let currentStep = stage;

  if (isNewOtpRequired(currentStep)) {
    updateErrorInState(dispatch, 'D_614', updateErrorCode);
    return currentStep;
  }

  currentStep = await submitOtp(dispatch, currentStep, otp);
  currentStep = await handleSubmitCodeResult(dispatch, currentStep);

  return currentStep;
}

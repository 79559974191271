import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRealm } from 'ciam-self-service-shared';

import { getSessionInfo, refreshSession } from '../utils/session';
import { logoutHandler } from '../components/Logout/actions';

const useSessionTimeoutProps = () => {
  const dispatch = useDispatch();
  const realm = useRealm();
  const { maxIdleExpirationTime, maxSessionExpirationTime } = useSelector((state) => state?.loginInfo?.sessionInfo);
  const sessionInfoFetched = useSelector((state) => state?.loginInfo?.sessionInfoFetched);
  const isLoggingOut = useSelector((state) => state.loginInfo?.logoutStarted);
  const handleLogout = useCallback(() => logoutHandler(realm)(dispatch), [realm, dispatch]);
  const handleRefreshSession = useCallback(() => refreshSession(dispatch, realm, ''), [dispatch, realm]);
  const handleGetSessionInfo = useCallback(() => getSessionInfo(dispatch, realm), [dispatch, realm]);

  return {
    maxIdleExpirationTime,
    maxSessionExpirationTime,
    sessionInfoFetched,
    isLoggingOut,
    handleLogout,
    refreshSession: handleRefreshSession,
    getSessionInfo: handleGetSessionInfo,
  };
};

export default useSessionTimeoutProps;

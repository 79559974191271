import { CallbackType } from '@forgerock/javascript-sdk';
import { updateErrorInState } from 'ciam-self-service-shared';
import { updateErrorCode } from '../../../Login/actions';
import { getCallbackOfTypeSafely } from '../../../../utils/authTreeUtils';
import nextStep from '../../../../utils/loginAuthnTree/handleStep';
import { isFailed } from '../../../../utils/authTree/helpers';
import AuthTreeError from '../../../../utils/authTree/AuthTreeError';

export default async function submitCredentials(dispatch, payload) {
  updateErrorInState(dispatch, null, updateErrorCode);
  const { stage, username, ciamUniqueID } = payload;

  let currentStep = stage;
  const usernameCallback = getCallbackOfTypeSafely(currentStep, CallbackType.NameCallback);
  const passwordCallback = getCallbackOfTypeSafely(currentStep, CallbackType.PasswordCallback);

  usernameCallback.setName(username);
  passwordCallback.setPassword(ciamUniqueID);

  currentStep = await nextStep(dispatch, currentStep);
  if (isFailed(currentStep)) {
    throw new AuthTreeError('Cannot activate with provided credentials', currentStep, 'D_702');
  }

  return currentStep;
}

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SPACING = 20;

function FormElement(props) {
  const { className, children, ...otherProps } = props;

  return (
    <div className={className} {...otherProps}>
      {children}
    </div>
  );
}

FormElement.propTypes = {
  className: PropTypes.string,
  spacing: PropTypes.number,
  children: PropTypes.node,
};

FormElement.defaultProps = {
  className: '',
  spacing: 1,
  children: '',
};

export default styled(FormElement)`
  position: relative;
  width: 100%;
  max-width: 409px;
  padding-top: ${(props) => (props.spacing || 1) * SPACING - SPACING}px;
  padding-bottom: ${(props) => (props.spacing || 1) * SPACING}px;
`;

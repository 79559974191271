import { useMemo } from 'react';
import { useLocation } from 'react-router';

/**
 * Gets the type of On Demand OTP flow
 * @returns {"oidc" | "saml"} The type of On Demand OTP flow
 */
export default function useOnDemandOtpType() {
  const location = useLocation();

  return useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get('type');
  }, [location.search]);
}

export const FORGOT_PASSWORD = 'ForgotPassword';
export const NEW_PASSWORD = 'NewPassword';
export const REQUIRE_USERINFO = 'RequireUserInfo';
export const PSWD_CHANGE_SUCCESS = 'PasswordChangeSuccessfull';
export const PSWD_CHANGE_FAILURE = 'PasswordChangeFailed';
export const FAILED_PSWD_POLICY = 'Failed policy validation';
export const PSWD_RESET_RATE_VIOLATION = 'Not allowed to change/reset password more than twice in 24 hours';
export const G200_ERROR = 'G_200';
export const D507_ERROR = 'D_507';
export const DOB_LASTNAME_NOT_FOUND = 'Brithday/Lastname Validation Failed';
export const OLD_PASSWORD_USED = 'Old Password Used';
export const SPECIAL_COMBO_VIOLATION = 'Special combination not allowed';
export const PWNED_PASSWORD = 'Cannot use breached password';

export const FP_PROMPT_PAGE_NAME = 'forgot-password:enter-username';
export const FP_PI_PAGE_NAME = 'change-password:personal-info';
export const FP_SUCCESS_PAGE_NAME = 'change-password:success';
export const FP_ENTER_PW_PAGE_NAME = 'change-password:enter-new-password';

export const EVENT_ENTER_USERNAME = 'forgot-password:enter-username';
export const EVENT_FP_SUCCESS = 'forgot-password-update-success';

export const EVENT_FP_PI_ERROR = 'change-password:personal-info-error';
export const EVENT_ENTER_USERNAME_ERROR = 'forgot-password:enter-username-error';
export const EVENT_ENTER_PW_ERROR = 'change-password:enter-new-password-error';

export const AuthTreeMessages = {
  LastName: 'Enter your last name',
  DateOfBirth: 'Enter your DOB',
};

const ActionTypes = {
  LOG_IN_SUCCESS: 'LOG_IN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGIN_AUTHN_TREE_STEP: 'LOGIN_AUTHN_TREE_STEP',
  LOGIN_UPDATE_USER_CONTEXT: 'LOGIN_UPDATE_USER_CONTEXT',
  LOGIN_OTP_MULTIPLE_TIMES: 'LOGIN_OTP_MULTIPLE_TIMES',
  LOGIN_MOBILE_NUMBER_ADDED: 'LOGIN_MOBILE_NUMBER_ADDED',
  UPDATE_LOGIN_LOADING_STATUS: 'UPDATE_LOGIN_LOADING_STATUS',
  UPDATE_ADD_NUMBER_STATUS: 'UPDATE_ADD_NUMBER_STATUS',
  UPDATE_DO_IT_LATER_STATUS: 'UPDATE_DO_IT_LATER_STATUS',
  UPDATE_GO_BACK_STATUS: 'UPDATE_GO_BACK_STATUS',
  OTP_COUNT: 'OTP_COUNT',
  INIT_STORE: 'INIT_STORE',
  OTP_REQUESTED_STATE: 'OTP_REQUESTED_STATE',
  UPDATE_REALM: 'UPDATE_REALM',
  UPDATE_REDIRECT_URI: 'UPDATE_REDIRECT_URI',
  IS_LOGIN_AFTER_ERROR: 'IS_LOGIN_AFTER_ERROR',
  CREATE_SESSION_STATUS: 'CREATE_SESSION_STATUS',
  LOGOUT_START: 'LogoutStart',
  LOGOUT_SUCCESS: 'LogoutSuccess',
  LOGOUT_FAILURE: 'LogoutFailure',
  LOGOUT_CLEANUP: 'LOGOUT_CLEANUP',
  UPDATE_USERNAME: 'UPDATE_USERNAME',
  UPDATE_ERROR_CODE: 'UPDATE_ERROR_CODE',
  UPDATE_IS_LOGOUT: 'UPDATE_IS_LOGOUT',
  SAVE_TOKEN: 'SAVE_TOKEN',
  VALIDATE_SESSION_START: 'VALIDATE_SESSION_START',
  VALIDATE_SESSION_SUCCESS: 'VALIDATE_SESSION_SUCCESS',
  VALIDATE_SESSION_FAIL: 'VALIDATE_SESSION_FAIL',
  SESSION_INFO_SUCCESS: 'SESSION_INFO_SUCCESS',
  SESSION_INFO_FAILURE: 'SESSION_INFO_FAILURE',
};

export default ActionTypes;

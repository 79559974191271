import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { isEmbeddedSelector, goToEmbeddedSelector } from './selectors';

function HideWhenEmbedded(props) {
  const { children } = props;
  const embedded = useSelector(isEmbeddedSelector);
  const gotoEmbedded = useSelector(goToEmbeddedSelector);
  const isEmbedded = embedded || gotoEmbedded;

  if (!isEmbedded) {
    return children;
  }

  return null;
}

HideWhenEmbedded.propTypes = {
  children: PropTypes.node.isRequired,
};

export default HideWhenEmbedded;

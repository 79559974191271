import { StepType } from '@forgerock/javascript-sdk/lib';
import client from '../../restClients/authenticatedBff';
import { updateStageAction } from '../Login/actions';
import ActionTypes from './actionTypes';

export const captureEmailOrPhoneValueAction = (emailOrPhoneValue) => {
  return {
    type: ActionTypes.ADD_EMAIL_OR_PHONE,
    emailOrPhoneValue,
  };
};

export const submitAsyncEmailAction = (payload) => {
  // TODO after integration with Forgerock Backend. Mocking stage till then.
  const recoverUserNameApi = `${window.REACT_APP_BFF_RECOVER_USER_NAME}`;
  const stage = { type: 'EmailSuccess' };
  const { email, telephoneNumber } = payload;
  const emailOrPhoneValue = email ? email : telephoneNumber;
  return (dispatch) => {
    dispatch(updateStageAction(stage));
    dispatch(captureEmailOrPhoneValueAction(emailOrPhoneValue));
    client.post(recoverUserNameApi, payload);
  };
};

export const resetStageAction = () => {
  const stage = { type: StepType.Step };
  return (dispatch) => {
    dispatch(updateStageAction(stage));
  };
};

import React, { useEffect, useState } from 'react';
import { Provider, useSelector, useDispatch } from 'react-redux';
import queryString from 'query-string';
import qs from 'qs';
import { BrowserRouter } from 'react-router-dom';
import { MuxProvider, LANG } from '@manulife/mux';
import { IntlProvider } from 'react-intl';
import { CoBrandingProvider } from 'ciam-self-service-shared';
import useGoToUrl from './hooks/useGoToUrl';
import useAdobeScript from './hooks/useAdobeScript';
import { configureStore } from './store/configStore';
import { route } from './routes';
import { actionCreators } from './actions/actions';

const store = configureStore();

const App = () => {
  useAdobeScript();
  return (
    <Provider store={store}>
      <AppWithStore />
    </Provider>
  );
};

function AppWithStore() {
  const locale = useSelector((state) => state?.intl?.locale) || LANG.EN_CA;
  const messages = useSelector((state) => state?.intl?.messages) || [];
  const isSignedIn = useSelector((state) => state?.loginInfo?.isLoggedIn || false);

  return (
    <MuxProvider langProvider={{ lang: locale }} navigationProvider={{ isSignedIn }}>
      <IntlProvider locale={locale} messages={messages}>
        <BrowserRouter>
          <ProviderWithBranding />
        </BrowserRouter>
      </IntlProvider>
    </MuxProvider>
  );
}

function ProviderWithBranding() {
  const dispatch = useDispatch();
  const [languageParsed, setLanguageParsed] = useState(false);

  function getSupportedBrowserLanguage() {
    const browserLanguage = window.navigator.userLanguage || window.navigator.language;
    if (browserLanguage?.startsWith('fr')) {
      return LANG.FR_CA;
    }
    return LANG.EN_CA;
  }

  const gotoUrl = useGoToUrl();
  useEffect(() => {
    const urlParams = queryString.parse(decodeURIComponent(window.location.search), { ignoreQueryPrefix: true });
    let gotoParams;
    if (gotoUrl) {
      try {
        const url = new URL(gotoUrl);
        gotoParams = qs.parse(url.search, { ignoreQueryPrefix: true });
      } catch {
        // in case of failure it will just go to next line and get ui_locales from urlParams or default it to english
      }
    }

    const browserLocale = getSupportedBrowserLanguage();
    // eslint-disable-next-line camelcase
    let lang = urlParams.ui_locales || gotoParams?.ui_locales;
    if (Array.isArray(urlParams.ui_locales)) {
      [lang] = urlParams.ui_locales;
    }
    if (lang === LANG.FR_CA) {
      dispatch(actionCreators.changeLocale({ locale: LANG.FR_CA }));
    } else if (lang === LANG.EN_CA) {
      dispatch(actionCreators.changeLocale({ locale: LANG.EN_CA }));
    } else {
      dispatch(actionCreators.changeLocale({ locale: browserLocale }));
    }
    setLanguageParsed(true);
  }, [dispatch, gotoUrl]);

  if (!languageParsed) {
    return null;
  }

  return <CoBrandingProvider hostUrl={window.REACT_APP_COBRANDING_HOST_URL}>{route}</CoBrandingProvider>;
}

export default App;

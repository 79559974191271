const ActionTypes = {
  CHANGE_LOCALE_LANGUAGE_SUCCESS: 'CHANGE_LOCALE_LANGUAGE_SUCCESS',
  ADD_TODO_ITEM: 'ADD_TODO_ITEM',
  COMPLETE_TODO_ITEM: 'COMPLETE_TODO_ITEM',
  LOGOUT_START: 'LOGOUT_START',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',
  START_REGISTRATION: 'START_REGISTRATION',
  COMPLETE_REGISTRARTION: 'COMPLETE_REGISTRARTION',
  REGISTER_FAILURE: 'REGISTER_FAILURE',
  REGISTRATION_ADDITIONAL_STEP: 'REGISTRATION_ADDITIONAL_STEP',
  TOKEN_SUCCESS: 'TOKEN_SUCCESS',
  VERIFY_FETCH: 'VERIFY_FETCH',
  VERIFY_SUCCESS: 'VERIFY_SUCCESS',
  VERIFY_FAIL: 'VERIFY_FAIL',
  OTP_SUCCESS: 'OTP_SUCCESS',
  INIT_STORE: 'INIT_STORE',
  UPDATE_MFA_BUTTON_STATUS: 'UPDATE_MFA_BUTTON_STATUS',
};

export default ActionTypes;

import { amBaseUrl } from '../../utils/frAuthConfig';

export const LOGOUT_BFF = `${window.REACT_APP_BFF_LOGOUT}`;

export const LOGOUT_START = 'LoginSuccess';
export const LOGOUT_SUCCESS = 'LoginSuccess';
export const LOGOUT_FAILURE = 'LoginFailure';

export const AM_ROOT_URL = amBaseUrl.concat('/json/realms/root/realms');
export const SESSION_ENDPOINT = '/sessions/?_action=logout';

export const AM_HEADERS = {
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache',
  'Accept-API-Version': 'resource=2.0,protocol=1.0',
};

import React, { useCallback } from 'react';
import MFASelectChannel from 'components/common/MFA/MFASelectChannel';
import Page, { AUTH_TYPE } from 'components/common/Page';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';
import { FormattedRichMessage, ContentPage, LoadingPage, usePathBuilder, useRichIntl } from 'ciam-self-service-shared';
import onDemandAuthTree from '../authTree';
import useAuthTreeErrorHandler from '../useAuthTreeErrorHandler';
import OnDemandBackButton from './OnDemandBackButton';
import useSelectChannel from '../../common/MFA/useSelectChannel';
import useOnDemandOtpType from '../useOnDemandOtpType';

function OnDemandSelectChannelPage() {
  const dispatch = useDispatch();
  const history = useHistory();
  const pathBuilder = usePathBuilder();
  const type = useOnDemandOtpType();
  const intl = useRichIntl();
  const authTreeErrorHandler = useAuthTreeErrorHandler();

  const onSelectChannel = useCallback(
    async (_, payload) => {
      const { stage, otpchannel } = payload;
      try {
        await onDemandAuthTree.chooseChannel(dispatch, stage, otpchannel);
        history.push(pathBuilder('/otp-on-demand/enter', true, { searchParams: { type } }));
      } catch (err) {
        authTreeErrorHandler(err);
      }
    },
    [authTreeErrorHandler, dispatch, history, type, pathBuilder],
  );

  const [channels, handleSelectChannel, displayChoices] = useSelectChannel(onSelectChannel);

  return (
    <Page
      title={intl.formatMessage({ id: 'mfa.selectChannel.page.title' })}
      authType={AUTH_TYPE.AUTHENTICATED}
      showLangToggle={false}
      showRealmToggle={false}
      showContactUs={false}
      showManageId={false}
      heading={intl.formatMessage({ id: 'mfa.selectChannel.page.heading' })}
    >
      {displayChoices ? (
        <ContentPage
          heading={<FormattedRichMessage id="mfa.selectChannel.page.heading" />}
          backButton={<OnDemandBackButton />}
        >
          <MFASelectChannel channels={channels} onSelectChannel={handleSelectChannel} />
        </ContentPage>
      ) : (
        <LoadingPage />
      )}
    </Page>
  );
}

export default OnDemandSelectChannelPage;

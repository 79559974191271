import { CallbackType } from '@forgerock/javascript-sdk/lib';
import { updateOTPRequestedState, updateOtpCountAction, updateErrorCode } from 'components/Login/actions';
import {
  MESSAGE_OTP_EXPIRED_ADD_NUMBER,
  PROMPT_OTP_CHOICES,
  MESSAGE_OTP_RETRY_LIMIT,
} from 'components/Login/constants';
import removeSessionStorageAttributes from 'utils/removeSessionStorageAttributes';
import { updateErrorInState } from 'ciam-self-service-shared';
import nextStep from './handleStep';
import { checkStep } from 'utils/authTree/helpers';
import chooseResendOrRetryAfterOTPExpiry from './chooseResendOrRetryAfterOTPExpiry';
import sendOtpForAddNumberFlow from './sendOtpForAddNumberFlow';
import handleBackButtonClick from './handleBackButtonClick';
import { getCallbackOfTypeSafely, getCallbackWithMessageSafely, getCallbackWithPromptSafely } from '../authTreeUtils';

const decideViewForAddNumberResult = async (dispatch, stage, ciamHistory) => {
  const userContextScript = stage.getCallbackOfType(CallbackType.TextOutputCallback).getOutputByName('message');
  const saveMobileResult = userContextScript.indexOf('"saveMobileResult": "[Status: 200 OK"');
  if (saveMobileResult === -1) {
    updateErrorInState(dispatch, 'G_202', updateErrorCode);
  }
  await removeSessionStorageAttributes();
  ciamHistory.push('/addmobilesuccess');
};

const handleFinalResponse = async (
  dispatch,
  finalStep,
  history,
  otpCount,
  isOTPGeneratedMultipleTimes,
  pathBuilder,
  ciamHistory,
) => {
  if (otpCount > 2 && isOTPGeneratedMultipleTimes) {
    //  No more otp attempts
    await removeSessionStorageAttributes();
    updateErrorInState(dispatch, 'D_615', updateErrorCode);
    history.push(pathBuilder('/signin', true, { searchParams: { error_code: 'D_615' } }));
  } else if (getCallbackWithMessageSafely(finalStep, MESSAGE_OTP_EXPIRED_ADD_NUMBER)) {
    // 3 attempts for 1st OTP
    updateErrorInState(dispatch, 'D_610', updateErrorCode);
  } else if (otpCount > 2 && getCallbackWithPromptSafely(finalStep, PROMPT_OTP_CHOICES)) {
    // 3 attempts for 1st OTP
    updateErrorInState(dispatch, 'D_614', updateErrorCode);
  } else if (getCallbackWithPromptSafely(finalStep, PROMPT_OTP_CHOICES)) {
    //  wrong otp entered
    updateErrorInState(dispatch, 'E_116', updateErrorCode);
  } else {
    decideViewForAddNumberResult(dispatch, finalStep, ciamHistory);
  }
};

export const handleOtpForAddNumberAsyncAction = async (dispatch, payload) => {
  dispatch(updateErrorCode(null));
  const { otp, otpCount, history, realm, brand, pathBuilder, ciamHistory } = payload;
  let { stage, isOTPGeneratedMultipleTimes } = payload;

  if (getCallbackWithMessageSafely(stage, MESSAGE_OTP_RETRY_LIMIT)) {
    await removeSessionStorageAttributes();
    updateErrorInState(dispatch, 'D_622', updateErrorCode);
    history.push(pathBuilder('/signin', true, { searchParams: { error_code: 'D_615' } }));
  }
  if (otpCount === 0) {
    stage = await handleBackButtonClick(stage, realm, brand, 'ciam.addNumberbackButton-otp');
  }
  isOTPGeneratedMultipleTimes = sessionStorage.getItem('isOTPGeneratedMultipleTimes');
  let resultStage = stage;
  const count = otpCount + 1;
  dispatch(updateOtpCountAction(parseInt(count, 10)));
  dispatch(updateOTPRequestedState(true));
  const choiceCallback = getCallbackOfTypeSafely(stage, CallbackType.ChoiceCallback);
  const expiredOtpCallback = getCallbackWithMessageSafely(stage, MESSAGE_OTP_EXPIRED_ADD_NUMBER);
  if (choiceCallback) {
    choiceCallback.setChoiceIndex('1');
    const step = await nextStep(dispatch, stage);
    resultStage = await sendOtpForAddNumberFlow(dispatch, step, otp);
    // handle any uncaught error - request not sent from UI
    checkStep(resultStage, 'D_705');
  } else if (expiredOtpCallback) {
    const secondStep = await chooseResendOrRetryAfterOTPExpiry(dispatch, stage);
    const secondStepChoiceCallback = secondStep.getCallbackOfType(CallbackType.ChoiceCallback);
    secondStepChoiceCallback.setChoiceIndex('1');
    const thirdStep = await nextStep(dispatch, secondStep);
    resultStage = await sendOtpForAddNumberFlow(dispatch, thirdStep, otp);
  } else {
    resultStage = await sendOtpForAddNumberFlow(dispatch, stage, otp);
    // handle any uncaught error - request not sent from UI
    checkStep(resultStage, 'D_705');
  }
  //  check here for result of adding number.
  handleFinalResponse(dispatch, resultStage, history, count, isOTPGeneratedMultipleTimes, pathBuilder, ciamHistory);
};

export default handleOtpForAddNumberAsyncAction;

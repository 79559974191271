import * as React from 'react';
import PropTypes from 'prop-types';
import { StageShape } from 'ciam-self-service-shared';
import ForgotUsernamePromptPage from './ForgotUsernamePromptPage';
import ForgotUsernameConfirmPage from './ForgotUsernameConfirmPage';
import { EMAIL_SUCCESS } from '../constants';

function ForgotUsernameView(props) {
  const { stage, submitHandler, emailOrPhoneValue, resetStageHandler } = props;
  switch (stage.type) {
    case EMAIL_SUCCESS:
      return <ForgotUsernameConfirmPage emailOrPhoneValue={emailOrPhoneValue} resetStage={resetStageHandler} />;
    default:
      return <ForgotUsernamePromptPage onRequestUsername={submitHandler} />;
  }
}

ForgotUsernameView.propTypes = {
  stage: StageShape.isRequired,
  submitHandler: PropTypes.func.isRequired,
  emailOrPhoneValue: PropTypes.string.isRequired,
  resetStageHandler: PropTypes.func.isRequired,
};

export default ForgotUsernameView;

const ActionTypes = {
  INIT_STORE: 'INIT_STORE',
  UPDATE_STAGE: 'UPDATE_STAGE',
  ADD_USERNAME: 'ADD_USERNAME',
  FP_ADDING_ERR: 'FP_ADDING_ERR',
  FP_CLEAN_ERROR: 'FP_CLEAN_ERROR',
  PASSWORD_UPDATE_STATUS: 'PASSWORD_UPDATE_STATUS',
  UPDATE_MFA_DETAIL: 'UPDATE_MFA_DETAIL',
};

export default ActionTypes;

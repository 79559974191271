import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { useCookies } from 'react-cookie';
import { BUTTON_VARIANT, H2, P } from '@manulife/mux';
import {
  ErrorAlert,
  Form,
  FormattedRichMessage,
  CIAMLink,
  CIAMLinkButton,
  PasswordInput,
  PASSWORD_INPUT_VARIANTS,
  UsernameInput,
  USERNAME_VARIANTS,
  ErrorShape,
  useRealm,
  useRichIntl,
  CoBrandingButton,
  customStyles,
} from 'ciam-self-service-shared';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import InternetExplorerUserWarning from '../../common/InternetExplorerUserWarning';
import FormElement from '../../common/FormElement';
import AllFieldsRequiredMessage from '../../common/AllFieldsRequiredMessage';
import HideWhenEmbedded from '../../Embedded/HideWhenEmbedded';
import AnalyticsDataLayer from '../../common/Analytics';
import { isEmbeddedSelector, goToEmbeddedSelector } from '../../Embedded/selectors';
import {
  REMEMBER_USERNAME_COOKIE_NAME,
  REMEMBER_USERNAME_COOKIE_EXPIRATION_DAYS,
  EVENT_SIGNIN_START,
  SIGNIN_PAGE_NAME,
  EVENT_SIGNIN_ERROR,
} from '../constants';
import { Wrapper } from './styledComponents';
import AemNotification from '../../AemNotification';

function SignInForm(props) {
  const realm = useRealm();
  const { onLogin, loginLoadingStatus, error } = props;
  const [cookies, setCookie, removeCookie] = useCookies([`${REMEMBER_USERNAME_COOKIE_NAME}-${realm}`]);
  const [username, setUsername] = useState(cookies[`${REMEMBER_USERNAME_COOKIE_NAME}-${realm}`] || '');
  const [password, setPassword] = useState('');
  const [rememberUsername, setRememberUsername] = useState(
    Boolean(cookies[`${REMEMBER_USERNAME_COOKIE_NAME}-${realm}`]),
  );
  const embedded = useSelector(isEmbeddedSelector);
  const gotoEmbedded = useSelector(goToEmbeddedSelector);
  const isEmbedded = embedded || gotoEmbedded;
  const location = useLocation();
  const isVerified = location?.state?.isVerified;
  const richIntl = useRichIntl();

  const handleLogin = useCallback(
    (event) => {
      // eslint-disable-next-line no-unused-expressions
      event?.preventDefault();
      if (rememberUsername) {
        setCookie(`${REMEMBER_USERNAME_COOKIE_NAME}-${realm}`, username, {
          path: '/',
          secure: true,
          httpOnly: false,
          sameSite: 'lax',
          expires: dayjs().add(REMEMBER_USERNAME_COOKIE_EXPIRATION_DAYS, 'days').toDate(),
        });
      }
      onLogin(username, password);
    },
    [onLogin, username, password, rememberUsername, setCookie, realm],
  );

  const handleRememberUsernameChanged = useCallback(
    (newRememberUsername) => {
      if (!newRememberUsername) {
        removeCookie(`${REMEMBER_USERNAME_COOKIE_NAME}-${realm}`);
      }
      setRememberUsername(newRememberUsername);
    },
    [realm, removeCookie],
  );

  return (
    <>
      <Form onSubmit={handleLogin}>
        {error?.eventType === EVENT_SIGNIN_ERROR && (
          <AnalyticsDataLayer eventName={EVENT_SIGNIN_ERROR} error={error} ignoreSession />
        )}
        {!error && <AnalyticsDataLayer pageName={SIGNIN_PAGE_NAME} eventName={EVENT_SIGNIN_START} ignoreSession />}
        <Wrapper>
          <InternetExplorerUserWarning />
        </Wrapper>
        <AemNotification displayPage="signIn" />
        <ErrorAlert error={error} callouts />
        <Wrapper>
          <AllFieldsRequiredMessage />
        </Wrapper>
        <FormElement>
          <UsernameInput
            variant={USERNAME_VARIANTS.LOGIN}
            id="username"
            value={username}
            onChange={setUsername}
            rememberUsername={rememberUsername}
            onRememberUsernameChange={handleRememberUsernameChanged}
            required
            ariaRequired
            isEmbedded={isEmbedded}
          />
        </FormElement>
        <FormElement>
          <PasswordInput
            variant={PASSWORD_INPUT_VARIANTS.LOGIN}
            id="password"
            value={password}
            onChange={setPassword}
            required
            ariaRequired
          />
        </FormElement>
        <FormElement spacing={2}>
          <CoBrandingButton
            type="submit"
            variant={BUTTON_VARIANT.PRIMARY}
            saving={loginLoadingStatus}
            disabled={loginLoadingStatus}
            customStyle={customStyles.button}
            data-testid="submit"
          >
            <FormattedRichMessage id="common.signin" />
          </CoBrandingButton>
        </FormElement>
      </Form>

      <HideWhenEmbedded>
        {!isVerified && (
          <>
            <H2>
              <FormattedRichMessage id="signin.noIdPrompt" />
            </H2>
            <FormElement>
              <CIAMLinkButton to="/register" variant={BUTTON_VARIANT.SECONDARY} customStyle={customStyles.button}>
                <FormattedRichMessage id="signin.setUpManulifeId" />
              </CIAMLinkButton>
            </FormElement>
            <P>
              <CIAMLink to="/aboutmanulifeid">{richIntl.formatMessage({ id: 'signin.whatIsManulifeId' })}</CIAMLink>
            </P>
          </>
        )}
      </HideWhenEmbedded>
    </>
  );
}

SignInForm.propTypes = {
  onLogin: PropTypes.func.isRequired,
  loginLoadingStatus: PropTypes.bool.isRequired,
  error: ErrorShape,
};

SignInForm.defaultProps = {
  error: undefined,
};

export default SignInForm;

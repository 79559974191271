import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MessageCard, MESSAGE_VARIANT } from '@manulife/mux';

export default function InternetExplorerUserWarning() {
  const userAgentRules = [
    /Trident\/7\.0.*rv:([0-9.]+).*\).*Gecko$/,
    /MSIE\s([0-9.]+);.*Trident\/[4-7].0/,
    /MSIE\s(7\.0)/,
  ];
  const matchUserAgent = (userAgent) => {
    return userAgentRules.some((regex) => regex.test(userAgent));
  };

  return (
    <>
      {matchUserAgent(navigator.userAgent) && (
        <MessageCard variant={MESSAGE_VARIANT.PRIMARY_WARNING}>
          <FormattedMessage id="common.internetExplorerUserWarning" />
        </MessageCard>
      )}
    </>
  );
}

// localStorage flag for controlling display of interstitial ("about") page
export const SHOW_DEV_INTERSTITIAL = 'SHOW_DEV_INTERSTITIAL';

export const SIGN_OUT_REASONS = {
  SIGNED_OUT: 'sign-out',
  INACTIVE: 'inactive',
  MAX_SESSION: 'session',
};

export const AsyncActionState = {
  Idle: 'idle',
  Loading: 'loading',
  Failed: 'failed',
  Success: 'success',
};

import { confirmEmailSuccess, confirmEmailFailure } from 'components/ConfirmMail/actions';
import client from '../restClients/authenticatedBff';

export const confirmEmailAsyncAction = (payload) => {
  const confirmEmailApi = window.REACT_APP_BFF_CONFIRM_MAIL;
  return async (dispatch) => {
    await client
      .post(confirmEmailApi, payload)
      .then((response) => {
        if (response.data?.status === 'success') {
          dispatch(confirmEmailSuccess(response.data.user.firstName));
        } else {
          dispatch(confirmEmailFailure());
        }
      })
      .catch(() => {
        dispatch(confirmEmailFailure());
      });
  };
};
export default confirmEmailAsyncAction;

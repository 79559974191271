import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { FormattedRichMessage, ContentPage, useCIAMHistory } from 'ciam-self-service-shared';
import { updateErrorCode } from 'components/Login/actions';
import { mapErrorFromJson } from 'utils/error-map';
import MFAEnterCode from '../../common/MFA/MFAEnterCode';
import Page, { AUTH_TYPE } from '../../common/Page';
import useOtpState from '../../../hooks/useOtpState';
import authTree from '../authTree';
import useAsyncCallback from '../../../hooks/useAsyncCallback';
import useAuthTreeErrorHandler from '../authTree/useAuthTreeErrorHandler';

const EMAIL_CHANNEL = {
  type: 'email',
  maskedValue: null,
  value: 1,
};

function ActivationEnterCodePage() {
  const history = useCIAMHistory();
  const intl = useIntl();
  const dispatch = useDispatch();
  const otpState = useOtpState();
  const authTreeErrorHandler = useAuthTreeErrorHandler();
  const msgPlaceHolderValue = otpState.isOTPGeneratedMultipleTimes
    ? intl.formatMessage({ id: `mfa.enterCode.new` })
    : '';

  const handleSubmitCode = useAsyncCallback(
    async (_, payload) => {
      try {
        await authTree.submitCode(dispatch, payload);
        history.push('/register/verified');
      } catch (err) {
        if (err.code === 'D_635') {
          err.code = 'D_703';
        }
        if (err.step === 'LoginFailure') {
          // Only if we have n/w error (unhandled by the fr sdk) - show the generic message via <ErrorAlert>
          dispatch(updateErrorCode(mapErrorFromJson('D_705')));
        } else {
          // Any other error to be handled by existing flow
          authTreeErrorHandler(err);
        }
      }
    },
    [dispatch, history],
  );

  const handleRequestResend = useAsyncCallback(
    async (_, payload) => {
      try {
        await authTree.requestResend(dispatch, payload);
      } catch (err) {
        authTreeErrorHandler(err);
      }
    },
    [dispatch],
  );

  return (
    <Page title={intl.formatMessage({ id: 'activation.otp.page.title' })} authType={AUTH_TYPE.UNAUTHENTICATED}>
      <ContentPage
        heading={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <FormattedRichMessage
            id="activation.otp.page.heading"
            values={{
              new: msgPlaceHolderValue,
            }}
          />
        }
      >
        <MFAEnterCode
          content=" "
          stage={otpState.stage}
          channel={EMAIL_CHANNEL}
          hasResentOtp={otpState.isOTPGeneratedMultipleTimes}
          otpCount={otpState.otpCount}
          error={otpState.error}
          isLoginAfterError={otpState.isLoginAfterError}
          onSubmitCode={handleSubmitCode}
          onRequestResend={handleRequestResend}
          singleChannel
        />
      </ContentPage>
    </Page>
  );
}

export default ActivationEnterCodePage;

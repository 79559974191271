import styled from 'styled-components';
import { BREAKPOINTS } from '@manulife/mux';

export const Wrapper = styled.div`
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const MainContainer = styled.div`
  display: flex;
  width: 100%;

  justify-content: space-between;

  flex-direction: column;
  @media (min-width: ${BREAKPOINTS.DESKTOP_SM}px) {
    flex-direction: row;
  }
`;

export const SignInWrapper = styled.div`
  flex: 0 0 409px;
`;

export const SignInProductInfoWrapper = styled.div`
  flex: 1 1 auto;

  @media (min-width: ${BREAKPOINTS.DESKTOP_SM}px) {
    margin-left: 8rem;
  }
`;

export const MessageWrapper = styled.div`
  text-align: start;

  & button {
    align-items: start;
  }
`;

export const IntroductionPageCardWrapper = styled.div`
  width: 375px;
  margin-top: 2rem;
  @media (min-width: ${BREAKPOINTS.DESKTOP_SM}px) {
    margin-left: 0rem;
    display: flex;
    div:nth-child(1) > div > div > p {
      margin-bottom: 4rem;
    }
    div:nth-child(2) {
      margin-left: 80px;
    }
  }
`;

import { updateErrorCode } from 'components/Login/actions';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useCIAMHistory, updateErrorInState, useRealm } from 'ciam-self-service-shared';
import AuthTreeError from '../../../utils/authTree/AuthTreeError';

export default function useAuthTreeErrorHandler() {
  const dispatch = useDispatch();
  const history = useCIAMHistory();
  const realm = useRealm();
  const errorHandler = useCallback(
    (error) => {
      if (error instanceof AuthTreeError && error.code) {
        updateErrorInState(dispatch, error.code, updateErrorCode);
        if (error.code === 'D_702') {
          const userName = sessionStorage.getItem(`userName-${realm}`);
          if (userName === 'null') {
            history.push('/activation/login');
          } else {
            history.push('/register/error');
          }
        }
        if (error.code === 'D_703') {
          history.push('/register/error');
        }
        if (error.code === 'D_636') {
          history.push('/activation/login');
        }
      } else {
        updateErrorInState(dispatch, 'G_201', updateErrorCode);
        history.push('/activation');
      }
    },
    [dispatch, history, realm],
  );
  return errorHandler;
}

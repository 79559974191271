import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router';
import { SessionTimeoutHandler, updateErrorInState } from 'ciam-self-service-shared';
import ActivationCredentialsPage from './ActivationCredentialsPage';
import ActivationSendOTP from './ActivationSendOTP';
import { updateErrorCode } from '../../Login/actions';
import useSessionTimeoutProps from '../../../hooks/useSessionTimeoutProps';
import ActivationEnterCodePage from './ActivationEnterCodePage';

function ActivationFlow() {
  const relativeUrl = useRouteMatch()?.path || '';
  const dispatch = useDispatch();
  const sessionTimeoutProps = useSessionTimeoutProps();

  // Clear any errors when enterying/leaving the activation flow
  useEffect(() => {
    updateErrorInState(dispatch, null, updateErrorCode);
    return () => updateErrorInState(dispatch, null, updateErrorCode);
  }, [dispatch]);

  return (
    <>
      <SessionTimeoutHandler {...sessionTimeoutProps} />
      <Switch>
        <Route exact path={`${relativeUrl}/login`}>
          <ActivationCredentialsPage />
        </Route>
        <Route exact path={`${relativeUrl}/enter-code`}>
          <ActivationEnterCodePage />
        </Route>
        <Route exact path={`${relativeUrl}`}>
          <ActivationSendOTP />
        </Route>
        <Route>
          <Redirect to={relativeUrl} />
        </Route>
      </Switch>
    </>
  );
}

export default ActivationFlow;

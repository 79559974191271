import { CallbackType } from '@forgerock/javascript-sdk/lib';
import {
  updateOTPRequestedState,
  updateOtpCountAction,
  updateMobileNumberAdded,
  updateErrorCode,
} from 'components/Login/actions';
import { checkStep } from 'utils/authTree/helpers';
import { getCallbackOfTypeSafely } from '../authTreeUtils';
import { ADD_NUMBER } from './constants';
import executeHiddenCallbackForOTPAutomatedTesting from './executeHiddenCallbackForOTPAutomatedTesting';
import nextStep from './handleStep';

export const addNumberAsyncAction = async (dispatch, payload) => {
  dispatch(updateErrorCode(null));
  sessionStorage.removeItem('ciam.backButton-otp');
  sessionStorage.removeItem('ciam.addNumberbackButton-otp');
  const { stage, number, history } = payload;
  dispatch(updateOtpCountAction(0));
  dispatch(updateMobileNumberAdded(true, number));
  dispatch(updateOTPRequestedState(false));
  let resultStep = '';

  const nameCallback = getCallbackOfTypeSafely(stage, CallbackType.NameCallback);
  if (nameCallback) {
    nameCallback.setName(number);
    const secondStep = await nextStep(dispatch, stage);
    if (getCallbackOfTypeSafely(secondStep, CallbackType.PasswordCallback)) {
      dispatch(updateOTPRequestedState(true));
    }
    resultStep = await executeHiddenCallbackForOTPAutomatedTesting(dispatch, secondStep);
  } else if (getCallbackOfTypeSafely(stage, CallbackType.TextOutputCallback)) {
    const confirmationCallback = stage.getCallbackOfType(CallbackType.ConfirmationCallback);
    confirmationCallback.setInputValue(ADD_NUMBER);
    const step = await nextStep(dispatch, stage);

    const nextStepNameCallback = getCallbackOfTypeSafely(step, CallbackType.NameCallback);
    if (nextStepNameCallback) {
      nextStepNameCallback.setName(number);
      const secondStep = await nextStep(dispatch, step);
      resultStep = await executeHiddenCallbackForOTPAutomatedTesting(dispatch, secondStep);
    } else {
      // handle any uncaught error - request not sent from UI
      checkStep(step, 'D_705');
    }
  } else {
    // nothing to do
  }
  if (getCallbackOfTypeSafely(resultStep, CallbackType.PasswordCallback)) {
    // store stage-info in local storage to fetch again for back button purposes
    sessionStorage.setItem('ciam.addNumberbackButton-otp', JSON.stringify(resultStep));
    dispatch(updateOTPRequestedState(true));
    history.push('/addnumbermfa');
  } else {
    // handle any uncaught error - request not sent from UI
    checkStep(null, 'D_705');
  }
};

export default addNumberAsyncAction;

import { CallbackType } from '@forgerock/javascript-sdk/lib';
import { updateErrorInState } from 'ciam-self-service-shared';
import { updateErrorCode } from '../../../Login/actions';
import { AUTH_TREE_MESSAGES } from '../../../Login/constants';
import { RETRY_OTP } from '../../../../utils/loginAuthnTree/constants';
import makeChoiceResendOrRetry from '../../../../utils/loginAuthnTree/makeChoiceResendOrRetry';
import AuthTreeError from '../../../../utils/authTree/AuthTreeError';
import { getCallbackOfTypeSafely, getCallbackWithMessageSafely } from '../../../../utils/authTreeUtils';

import { checkStep, submitOtp } from './commonSteps';
import {
  isNewOtpRequired,
  isOtpExpired,
  isOtpRetryLimitReached,
  redirectIfRequired,
  redirectIfSuccess,
} from '../../../../utils/authTree/helpers';

async function handleSubmitCodeResult(dispatch, step) {
  let currentStep = step;

  if (redirectIfRequired(currentStep)) {
    return currentStep;
  }

  if (redirectIfSuccess(currentStep)) {
    return currentStep;
  }

  await checkStep(dispatch, step);

  const textOutputCallback = getCallbackOfTypeSafely(currentStep, CallbackType.TextOutputCallback);

  if (getCallbackWithMessageSafely(currentStep, AUTH_TREE_MESSAGES.AccountActivated)) {
    return currentStep;
  }

  if (isNewOtpRequired(currentStep)) {
    throw new AuthTreeError('New OTP required', currentStep, 'D_614');
  } else if (isOtpRetryLimitReached(currentStep)) {
    throw new AuthTreeError('max otp limit', currentStep);
  } else if (textOutputCallback && isOtpExpired(textOutputCallback)) {
    throw new AuthTreeError('Expired OTP', currentStep, 'D_635');
  } else if (textOutputCallback && !isOtpExpired(textOutputCallback)) {
    // When the user enters an invalid OTP, allow retrying.
    currentStep = await makeChoiceResendOrRetry(dispatch, currentStep, RETRY_OTP);
    await checkStep(dispatch, currentStep);

    if (isNewOtpRequired(currentStep)) {
      throw new AuthTreeError('New OTP is required', currentStep, 'D_614');
    }

    throw new AuthTreeError('Invalid OTP', currentStep, 'E_116');
  } else {
    // Do nothing
  }

  if (isNewOtpRequired(currentStep)) {
    throw new AuthTreeError('New OTP is required', currentStep, 'D_614');
  }

  throw new AuthTreeError('Unexpected auth tree error', currentStep, 'G_201');
}

export default async function submitCode(dispatch, payload) {
  updateErrorInState(dispatch, null, updateErrorCode);

  const { stage, otp } = payload;
  let currentStep = stage;

  if (isNewOtpRequired(currentStep)) {
    throw new AuthTreeError('New OTP is required', currentStep, 'D_614');
  }

  currentStep = await submitOtp(dispatch, currentStep, otp);
  currentStep = await handleSubmitCodeResult(dispatch, currentStep);

  return currentStep;
}

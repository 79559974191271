import determineCallback from './loginAuthnTree/determineCallback';
import { SHOW_DEV_INTERSTITIAL } from './constants';

const langCodes = {
  'en-CA': 'en',
  'fr-CA': 'fr',
  en: 'en',
  fr: 'fr',
};

function shouldShowIntersitial() {
  const flag = localStorage.getItem(SHOW_DEV_INTERSTITIAL);
  return flag === null ? false : flag === 'true';
}

function redirectToAuthenticated(gotoURI, locale, history, realm, brand, logoutStarted) {
  const env = window.REACT_ENV;
  if (gotoURI) {
    const redirectionURL = new URL(gotoURI);
    const urlParams = redirectionURL.searchParams;
    const lang = langCodes[locale];
    urlParams.set('locale', lang);
    urlParams.set('ui_locales', lang);
    // setting the ui_locales inside claims equal to app locale
    try {
      const claims = JSON.parse(urlParams.get('claims'));
      claims.id_token.ui_locales.value = locale;
      urlParams.set('claims', JSON.stringify(claims));
    } catch {
      // do nothing
    }
    window.location.replace(redirectionURL.href);
  } else if (shouldShowIntersitial()) {
    if (!logoutStarted) {
      history.push('/about');
    }
  } else if (!logoutStarted) {
    const redirectionURL = new URL(determineCallback(env, locale, realm));
    if (brand) {
      redirectionURL.searchParams.set('brand', brand);
    }
    window.location.replace(redirectionURL.href);
  }
}

export default redirectToAuthenticated;

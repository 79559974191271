import { Config, FRStep } from '@forgerock/javascript-sdk/lib';
import { AUTH_TREE_REALM_MAPPING } from 'components/Login/constants';
import { asyncWrapper } from 'utils/async';
import determineConfig from 'utils/frAuthConfig';

const handleBackButtonClick = async (stage, realm, brand, itemName) => {
  const stageInBrowserStorage = sessionStorage.getItem(itemName);
  let step = stage;
  if (Object.keys(stage).length === 0 && stageInBrowserStorage) {
    Config.set(determineConfig(AUTH_TREE_REALM_MAPPING[realm], realm, brand));
    const frPayload = JSON.parse(stageInBrowserStorage).payload;
    step = new FRStep(frPayload);
  }
  return step;
};
export default asyncWrapper(handleBackButtonClick);

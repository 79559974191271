import initState from '../../reducers/initState';
import ActionTypes from './actionTypes';

// eslint-disable-next-line import/prefer-default-export
export const verifyPassword = (state = initState.verifyPassword, action) => {
  switch (action.type) {
    case ActionTypes.REST_FAILURE_COUNT: {
      return {
        verified: false,
        failureCount: 0,
      };
    }
    default: {
      return state;
    }
  }
};

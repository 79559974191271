import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { FormattedMessage, useIntl } from 'react-intl';
import { BUTTON_VARIANT, H2, P, ExpandablePanel } from '@manulife/mux';
import {
  DateOfBirth,
  ErrorAlert,
  Form,
  FormattedRichMessage,
  FormTextInput,
  PasswordInput,
  PASSWORD_INPUT_VARIANTS,
  UsernameInput,
  USERNAME_VARIANTS,
  validEmail,
  ErrorShape,
  validFirstName,
  validLastName,
  CoBrandingButton,
  customStyles,
} from 'ciam-self-service-shared';
import { useDispatch } from 'react-redux';
import LanguageSelection from 'components/common/LanguageSelection';
import useIsInboundSSO from '../../../hooks/useIsInboundSSO';
import FormElement from '../../common/FormElement';
import AllFieldsRequiredMessage from '../../common/AllFieldsRequiredMessage';
import AnalyticsDataLayer from '../../common/Analytics';
import useDateOfBirthState from '../../../hooks/useDateOfBirthState';
import muxCustomStyles from '../../../assets/styles/muxCustomStyles';
import useGoToUrl from '../../../hooks/useGoToUrl';
import { EVENT_REGISTER_ERROR } from '../constants';
import { registerFailureAction } from '../actions';
import AemNotification from '../../AemNotification';
import InternetExplorerUserWarning from '../../common/InternetExplorerUserWarning';

dayjs.extend(utc);

function SignupForm(props) {
  const { onSignup, isSigningUp, error } = props;
  const intl = useIntl();
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [dateOfBirthParts, dateOfBirth, setDateOfBirth] = useDateOfBirthState();
  const [preferredLanguage, setPreferredLanguage] = useState(intl.locale.substr(0, 2));
  const goto = useGoToUrl();
  const isInboundSSO = useIsInboundSSO();
  const inboundButtonStyle = !isInboundSSO ? customStyles.button : muxCustomStyles.inboundButton;
  const handleSignup = async () => {
    // TODO: Move this to redux. Formatting the date to what the backend requires shouldn't be part of the view.
    const dob = `${dayjs(dateOfBirth).format('YYYY-MM-DD')}T00:00:00Z`;
    const signupData = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      dob,
      email,
      username,
      password,
      goto,
      lang: preferredLanguage,
    };
    onSignup(signupData);
  };
  const errorValues = { userName: username };

  const handleUsernameChange = (value) => {
    setUsername(value);
    if (error?.component === 'username') {
      dispatch(registerFailureAction(null));
    }
  };

  const handlePasswordChange = (value) => {
    setPassword(value);
    if (error?.component === 'password') {
      dispatch(registerFailureAction(null));
    }
  };

  return (
    <>
      {(!error || Object.keys(error).length !== 0) && (
        <AnalyticsDataLayer eventName={EVENT_REGISTER_ERROR} error={error} ignoreSession />
      )}
      <InternetExplorerUserWarning />
      <P>
        <FormattedRichMessage id="signup.instructions" />
      </P>
      <P>
        <FormattedRichMessage id="signup.additionalInstructions" />
      </P>
      <AemNotification displayPage="signUp" />
      <P>
        <AllFieldsRequiredMessage />
      </P>
      <ErrorAlert error={error} errorValues={errorValues} callouts />

      <Form onSubmit={handleSignup} noValidate>
        <FormElement>
          <FormTextInput
            id="first-name"
            name="first-name"
            autoComplete="given-name"
            label={intl.formatMessage({ id: 'signup.personalInfo.firstName' })}
            value={firstName}
            onChange={setFirstName}
            required
            ariaRequired
            validations={validFirstName}
            autoFocus
          />
        </FormElement>

        <FormElement>
          <FormTextInput
            id="last-name"
            name="last-name"
            autoComplete="family-name"
            label={intl.formatMessage({ id: 'signup.personalInfo.lastName' })}
            value={lastName}
            onChange={setLastName}
            required
            ariaRequired
            validations={validLastName}
          />
        </FormElement>

        <FormElement>
          <DateOfBirth
            dateOfBirthParts={dateOfBirthParts}
            dateOfBirth={dateOfBirth}
            onChange={setDateOfBirth}
            required
            ariaRequired
          />
        </FormElement>

        <FormElement>
          <FormTextInput
            id="email"
            inputMode="email"
            autoComplete="email"
            label={intl.formatMessage({ id: 'signup.personalInfo.email' })}
            value={email}
            onChange={setEmail}
            required
            ariaRequired
            validations={validEmail}
            validationsCombinedLabel={intl.formatMessage({ id: 'common.email.validation.combined' })}
          />
        </FormElement>
        <FormElement>
          <LanguageSelection
            preferredLanguage={preferredLanguage}
            onChange={setPreferredLanguage}
            legendName="signup.personalInfo.languageLegend"
          />
        </FormElement>
        <H2>
          <FormattedMessage id="signup.credentials.heading" />
        </H2>
        <FormElement>
          <UsernameInput
            id="username"
            name="username"
            variant={USERNAME_VARIANTS.CREATE}
            value={username}
            onChange={handleUsernameChange}
            errors={[error]}
            errorValues={errorValues}
            required
            ariaRequired
          />
        </FormElement>

        <FormElement>
          <PasswordInput
            id="password"
            variant={PASSWORD_INPUT_VARIANTS.CREATE}
            value={password}
            onChange={handlePasswordChange}
            errors={[error]}
            firstName={firstName}
            lastName={lastName}
            userName={username}
            required
            ariaRequired
          />
        </FormElement>
        {isInboundSSO && (
          <ExpandablePanel
            title={intl.formatMessage({
              id: 'signup.personalInfo.termsAndConditions.title',
            })}
            customStyle={{
              titleStyle: { height: 'auto' },
              headerWrapperStyle: { alignItems: 'start' },
            }}
          >
            <FormattedRichMessage id="signup.personalInfo.termsAndConditions.content" />
          </ExpandablePanel>
        )}
        <FormElement spacing={2}>
          <CoBrandingButton
            type="submit"
            variant={BUTTON_VARIANT.PRIMARY}
            saving={isSigningUp}
            disabled={isSigningUp}
            customStyle={inboundButtonStyle}
          >
            <FormattedMessage id="signup.submitButton" />
          </CoBrandingButton>
        </FormElement>
      </Form>
    </>
  );
}

SignupForm.propTypes = {
  isSigningUp: PropTypes.bool,
  onSignup: PropTypes.func.isRequired,
  error: ErrorShape,
};

SignupForm.defaultProps = {
  isSigningUp: false,
  error: undefined,
};

export default SignupForm;

import { useCallback } from 'react';
import { usePathBuilder } from 'ciam-self-service-shared';
import { useHistory } from 'react-router';

export default function useAuthTreeErrorHandler() {
  const history = useHistory();
  const pathBuilder = usePathBuilder();

  return useCallback(
    (error) => {
      if (error) {
        const path = pathBuilder('/signin', true, { searchParams: { goto: undefined } });
        history.push(path);
      }
    },
    [history, pathBuilder],
  );
}

export function useSuccessUrlErrorHandler() {
  const history = useHistory();
  const pathBuilder = usePathBuilder();

  return useCallback(() => {
    const path = pathBuilder('/signin', true, { searchParams: { goto: undefined } });
    history.push(path);
  }, [history, pathBuilder]);
}

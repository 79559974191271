import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Analytics, useRealm } from 'ciam-self-service-shared';

import { getSessionInfo } from '../../../utils/session';

function AnalyticsWrapper(props) {
  const dispatch = useDispatch();
  const realm = useRealm();
  const hashedCiamId = useSelector((state) => state.loginInfo?.sessionInfo?.hashedCiamId);

  useEffect(() => {
    getSessionInfo(dispatch, realm);
  }, [dispatch, realm]);

  return <Analytics {...props} hashedCiamId={hashedCiamId} />;
}

export default AnalyticsWrapper;

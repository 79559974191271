import { updateErrorCode, updateOTPRequestedMultipleTimes } from 'components/Login/actions';
import { updateErrorInState } from 'ciam-self-service-shared';
import { INVALID_OTP, RESEND_OTP, SAME_CHANNEL_SELECTION } from '../../../../utils/loginAuthnTree/constants';
import executeHiddenCallbackForOTPAutomatedTesting from '../../../../utils/loginAuthnTree/executeHiddenCallbackForOTPAutomatedTesting';
import makeChoiceResendOrRetry from '../../../../utils/loginAuthnTree/makeChoiceResendOrRetry';
import sameChannelOrDifferent from '../../../../utils/loginAuthnTree/sameChannelOrDifferent';
import { checkStep, submitOtp } from '../commonSteps';
import { isOnEnterOtpStep } from '../../../../utils/authTree/helpers';

export default async function requestResend(dispatch, payload) {
  updateErrorInState(dispatch, null, updateErrorCode);
  const { stage } = payload;
  let currentStep = stage;

  await checkStep(dispatch, currentStep);

  currentStep = await executeHiddenCallbackForOTPAutomatedTesting(dispatch, currentStep);

  if (isOnEnterOtpStep(currentStep)) {
    // Intentionally submit an invalid OTP to move to the next node in the tree
    currentStep = await submitOtp(dispatch, currentStep, INVALID_OTP);
  }

  currentStep = await makeChoiceResendOrRetry(dispatch, currentStep, RESEND_OTP);
  await checkStep(dispatch, currentStep);

  currentStep = await sameChannelOrDifferent(dispatch, currentStep, SAME_CHANNEL_SELECTION);
  await checkStep(dispatch, currentStep);

  dispatch(updateOTPRequestedMultipleTimes(true));

  return currentStep;
}

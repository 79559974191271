import { CallbackType } from '@forgerock/javascript-sdk/lib';
import { updateOtpCountAction, updateOTPRequestedMultipleTimes, updateErrorCode } from 'components/Login/actions';
import {
  MESSAGE_TRY_NEW_OTP,
  MESSAGE_OTP_EXPIRED_ADD_NUMBER,
  MESSAGE_OTP_RETRY_LIMIT,
  OTP_TEST_ACCOUNT,
} from 'components/Login/constants';
import removeSessionStorageAttributes from 'utils/removeSessionStorageAttributes';
import { updateErrorInState } from 'ciam-self-service-shared';
import { INVALID_OTP, ADD_NUMBER } from './constants';
import nextStep from './handleStep';
import { checkStep } from 'utils/authTree/helpers';
import chooseResendOrRetryAfterOTPExpiry from './chooseResendOrRetryAfterOTPExpiry';
import handleBackButtonClick from './handleBackButtonClick';
import executeHiddenCallbackForOTPAutomatedTesting from './executeHiddenCallbackForOTPAutomatedTesting';
import { getCallbackOfTypeSafely, getCallbackWithIdSafely, getCallbackWithMessageSafely } from '../authTreeUtils';

const executeFinalStepsResendOtp = async (dispatch, step, history, pathBuilder) => {
  const choiceCallback = step.getCallbackOfType(CallbackType.ChoiceCallback);
  choiceCallback.setChoiceIndex(ADD_NUMBER);
  const secondStep = await nextStep(dispatch, step);
  if (getCallbackWithMessageSafely(secondStep, MESSAGE_OTP_RETRY_LIMIT)) {
    await removeSessionStorageAttributes();
    updateErrorInState(dispatch, 'D_622', updateErrorCode);
    history.push(pathBuilder('/signin', true, { searchParams: { error_code: 'D_622' } }));
  } else {
    sessionStorage.setItem('ciam.addNumberbackButton-otp', JSON.stringify(secondStep));
  }
};

const resendForAddNumberAsyncAction = async (dispatch, payload) => {
  dispatch(updateErrorCode(null));
  let { stage } = payload;
  const { realm, brand, history, pathBuilder } = payload;
  dispatch(updateOtpCountAction(0));
  dispatch(updateOTPRequestedMultipleTimes(true));
  sessionStorage.setItem('isOTPGeneratedMultipleTimes', true);

  // after clicking back button from email account
  stage = await handleBackButtonClick(stage, realm, brand, 'ciam.addNumberbackButton-otp');

  if (getCallbackWithMessageSafely(stage, MESSAGE_OTP_RETRY_LIMIT)) {
    await removeSessionStorageAttributes();
    updateErrorInState(dispatch, 'D_622', updateErrorCode);
    history.push(pathBuilder('/signin', true, { searchParams: { error_code: 'D_622' } }));
  }

  const passwordCallback = getCallbackOfTypeSafely(stage, CallbackType.PasswordCallback);
  if (passwordCallback) {
    passwordCallback.setPassword(INVALID_OTP);
    const step = await nextStep(dispatch, stage);
    // handle any uncaught error - request not sent from UI
    checkStep(step, 'D_705');
    executeFinalStepsResendOtp(dispatch, step, history, pathBuilder);
  } else if (getCallbackOfTypeSafely(stage, CallbackType.ChoiceCallback)) {
    executeFinalStepsResendOtp(dispatch, stage, history, pathBuilder);
  } else if (getCallbackWithMessageSafely(stage, MESSAGE_OTP_EXPIRED_ADD_NUMBER)) {
    const secondStep = await chooseResendOrRetryAfterOTPExpiry(dispatch, stage);
    executeFinalStepsResendOtp(dispatch, secondStep, history, pathBuilder);
  } else if (getCallbackOfTypeSafely(stage, CallbackType.TextOutputCallback)) {
    if (getCallbackWithMessageSafely(stage, MESSAGE_TRY_NEW_OTP)) {
      const confirmationCallback = stage.getCallbackOfType(CallbackType.ConfirmationCallback);
      confirmationCallback.setInputValue(ADD_NUMBER);
      const secondStep = await nextStep(dispatch, stage);
      if (getCallbackWithMessageSafely(secondStep, MESSAGE_OTP_RETRY_LIMIT)) {
        await removeSessionStorageAttributes();
        updateErrorInState(dispatch, 'D_622', updateErrorCode);
        history.push(pathBuilder('/signin', true, { searchParams: { error_code: 'D_622' } }));
      }
      sessionStorage.setItem('ciam.addNumberbackButton-otp', JSON.stringify(secondStep));
    } else if (getCallbackWithIdSafely(stage, OTP_TEST_ACCOUNT)) {
      const secondStep = await executeHiddenCallbackForOTPAutomatedTesting(dispatch, stage);
      const secondStepPasswordCallback = getCallbackOfTypeSafely(secondStep, CallbackType.PasswordCallback);
      if (secondStepPasswordCallback) {
        secondStepPasswordCallback.setPassword(INVALID_OTP);
        const step = await nextStep(dispatch, secondStep);
        executeFinalStepsResendOtp(dispatch, step, history);
      }
    }
  } else {
    // nothing to do
  }
};

export default resendForAddNumberAsyncAction;

import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Card, BUTTON_VARIANT, colors } from '@manulife/mux';
import styled from 'styled-components';
import {
  FormattedRichMessage,
  RealmSpecific,
  REALMS,
  HEADER_REALM_KEYS,
  useRealm,
  usePathBuilder,
  CoBrandingButton,
  Link,
} from 'ciam-self-service-shared';
import HideWhenEmbedded from '../../Embedded/HideWhenEmbedded';

export const Wrapper = styled.div`
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const MessageWrapper = styled.div`
  text-align: start;

  & button,
  a {
    align-items: start;
  }

  &.custom-link-wrapper {
    a {
      padding: 12px 12px 12px 0px;
      display: inline-flex;
      color: ${colors.dark_navy} !important;
      font-weight: 400 !important;
      text-decoration: none;
      align-items: start;
      & div {
        display: inline-flex;
      }
      & svg {
        margin: 0px !important;
        height: 1.3125rem !important;
      }
    }
  }
`;

const ListWrapper = styled.div`
  padding-left: 1rem;
`;

function SignInProductInfoRealmLink(props) {
  const { realm } = props;
  const pathBuilder = usePathBuilder();
  const otherRealmsUrl = pathBuilder('/', true, { realm });

  return (
    <RealmSpecific hideFor={[realm]}>
      <Wrapper>
        <FormattedRichMessage id={`signin.page.products.${realm.toLowerCase()}.message`} />
        <MessageWrapper className="custom-link-wrapper">
          <CoBrandingButton as={Link} to={otherRealmsUrl} variant={BUTTON_VARIANT.TERTIARY}>
            <MessageWrapper>
              <FormattedRichMessage id={`signin.page.products.${realm.toLowerCase()}.message.button`} />
            </MessageWrapper>
          </CoBrandingButton>
        </MessageWrapper>
      </Wrapper>
    </RealmSpecific>
  );
}

SignInProductInfoRealmLink.propTypes = {
  realm: PropTypes.string.isRequired,
};

export default function SignInProductInfo() {
  const intl = useIntl();
  const realm = useRealm();
  const otherProductsUrl = intl.messages['signin.page.products.other.message.url'];

  return (
    <HideWhenEmbedded>
      <RealmSpecific showFor={[REALMS.CUSTOMER, REALMS.ADVISOR, REALMS.SPONSOR]}>
        <Card>
          <Card.Content>
            <Wrapper>
              <FormattedRichMessage id="signin.page.products.title" />
              <ListWrapper>
                <FormattedRichMessage id={`signin.page.products.${realm}.list`} />
              </ListWrapper>
            </Wrapper>

            <Wrapper>
              <FormattedRichMessage id="signin.page.products.other.message" />
              <MessageWrapper className="custom-link-wrapper">
                <CoBrandingButton as={Link} to={otherProductsUrl} variant={BUTTON_VARIANT.TERTIARY}>
                  <MessageWrapper>
                    <FormattedRichMessage id="signin.page.products.other.message.button" />
                  </MessageWrapper>
                </CoBrandingButton>
              </MessageWrapper>
            </Wrapper>

            {HEADER_REALM_KEYS.map((realmKey) => (
              <SignInProductInfoRealmLink key={realmKey} realm={REALMS[realmKey]} />
            ))}
          </Card.Content>
        </Card>
      </RealmSpecific>
    </HideWhenEmbedded>
  );
}

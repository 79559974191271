import { asyncWrapper } from 'utils/async';
import { Config } from '@forgerock/javascript-sdk/lib';
import nextStep from 'utils/loginAuthnTree/handleStep';
import { updateErrorCode, updateOtpCountAction, updateOTPRequestedMultipleTimes } from 'components/Login/actions';
import determineConfig from '../frAuthConfig';

export const initAsyncAction = async (dispatch, treeName, realm, brand, showError) => {
  // retain error when redirected from other screens with an error message to be displayed
  if (!showError) {
    dispatch(updateErrorCode(null));
  }
  // Set configuration defaults
  Config.set(determineConfig(treeName, realm, brand));
  const step = await nextStep(dispatch);
  // reset all the counts in case when refresh doesn't update the count
  dispatch(updateOtpCountAction(0));
  dispatch(updateOTPRequestedMultipleTimes(false));
  return step;
};

export default asyncWrapper(initAsyncAction);

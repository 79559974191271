import { asyncWrapper } from '../../async';
import { LogInSuccess } from '../../../components/Login/actions';
import removeSessionStorageAttributes from '../../removeSessionStorageAttributes';
import { getCiamId } from '../../session';

const successRedirection = async (dispatch, payload) => {
  await removeSessionStorageAttributes();
  const { gotoURI, realm } = payload;
  const stage = payload.finalStep;
  let businessUnitId = '';
  if (gotoURI) {
    try {
      const goto = new URL(gotoURI);
      businessUnitId = goto.searchParams.get('client_id');
    } catch (err) {
      // in case of failure it will just use businessUnitId as an empty string
    }
  }

  window.CWC_MLCA.dataLayer.push({
    event: 'sign-in-success',
    user: {
      type: realm,
      id: await getCiamId(realm), // hashed CIAM ID
    },
    product: {
      name: businessUnitId,
    },
  });

  dispatch(
    LogInSuccess({
      realm,
      tokenId: stage.payload.tokenId,
    }),
  );
};

export default asyncWrapper(successRedirection);

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ExpandablePanel } from '@manulife/mux';
import { useRealm, useRichIntl, FormattedRichMessage, ContentPage } from 'ciam-self-service-shared';
import Page, { AUTH_TYPE } from '../../common/Page';
import AnalyticsDataLayer from '../../common/Analytics';
import {
  VERIFY_EMAIL_PAGE_NAME,
  VERIFY_EMAIL_PAGE_TITLE,
  VERIFIED_EMAIL_PAGE_NAME,
  EVENT_REGISTER_SUCCESS,
} from '../constants';
import SigninButton from '../../common/SigninButton';

const ParagraphWrapper = styled.div`
  padding-bottom: 1em;
`;

function SignUpVerifyPage(props) {
  const { firstName, email, isVerified, userName, ciamId } = props;
  const realm = useRealm();
  sessionStorage.setItem(`userName-${realm}`, userName);
  sessionStorage.setItem(`ciamId-${realm}`, ciamId);

  const richIntl = useRichIntl();

  const greeting = firstName ? `, ${firstName}` : '';

  const titleMessageId = !isVerified ? 'signupVerify.pending.page.title' : 'signupVerify.complete.page.title';
  const headingMessageId = !isVerified ? 'signupVerify.pending.page.heading' : 'signupVerify.complete.page.heading';

  return (
    <Page
      title={richIntl.formatMessage({ id: titleMessageId }, { greeting })}
      authType={AUTH_TYPE.UNAUTHENTICATED}
      heading={richIntl.formatMessage(
        { id: headingMessageId },
        {
          greeting,
        },
      )}
    >
      <ContentPage heading={<FormattedRichMessage id={headingMessageId} values={{ greeting }} />}>
        {!isVerified ? (
          <>
            <AnalyticsDataLayer pageName={VERIFY_EMAIL_PAGE_NAME} ignoreSession />
            <ParagraphWrapper>
              <FormattedRichMessage id="signupVerify.pending.content1" />
            </ParagraphWrapper>
            <ExpandablePanel
              title={richIntl.formatMessage({
                id: 'signupVerify.pending.why.title',
              })}
              customStyle={{
                titleStyle: { height: 'auto' },
                headerWrapperStyle: { alignItems: 'start' },
              }}
            >
              <FormattedRichMessage id="signupVerify.pending.why.content" />
            </ExpandablePanel>
            <ParagraphWrapper>
              <FormattedRichMessage id="signupVerify.pending.content2" values={{ email }} />
            </ParagraphWrapper>
            <FormattedRichMessage id="signupVerify.pending.noemail" />
          </>
        ) : (
          <>
            <AnalyticsDataLayer pageName={VERIFIED_EMAIL_PAGE_NAME} pageTitle={VERIFY_EMAIL_PAGE_TITLE} ignoreSession />
            <AnalyticsDataLayer eventName={EVENT_REGISTER_SUCCESS} ignoreSession />
            <ParagraphWrapper>
              <FormattedRichMessage id="signupVerify.complete.content" />
            </ParagraphWrapper>
            <SigninButton isVerified />
          </>
        )}
      </ContentPage>
    </Page>
  );
}

SignUpVerifyPage.propTypes = {
  firstName: PropTypes.string.isRequired,
  email: PropTypes.string,
  isVerified: PropTypes.bool,
  userName: PropTypes.string,
  ciamId: PropTypes.string,
};

SignUpVerifyPage.defaultProps = {
  email: null,
  isVerified: false,
  userName: null,
  ciamId: null,
};

export default SignUpVerifyPage;

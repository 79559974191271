import { CallbackType, StepType } from '@forgerock/javascript-sdk/lib';
import { updateStageAction } from 'components/Login/actions';
import { MESSAGE_TRY_NEW_OTP, GIVEN_NAME, MESSAGE_OTP_EXPIRED } from 'components/Login/constants';
import executeHiddenCallbackForUserContextRetrieval from './executeHiddenCallbackForUserContextRetrieval';
import chooseRetryToSelectSecondOTPFlow from './chooseRetryToSelectSecondOTPFlow';
import nextStep from './handleStep';
import { getCallbackOfTypeSafely, getCallbackWithIdSafely, getCallbackWithMessageSafely } from '../authTreeUtils';

const sendOtp = async (dispatch, step, otp, otpCount) => {
  let finalStep = step;
  if (
    getCallbackOfTypeSafely(step, CallbackType.TextOutputCallback) &&
    getCallbackWithMessageSafely(step, MESSAGE_TRY_NEW_OTP)
  ) {
    dispatch(updateStageAction(step));
  } else {
    const passwordCallback = step.getCallbackOfType(CallbackType.PasswordCallback);
    passwordCallback.setPassword(otp);
    const stage = await nextStep(dispatch, step);
    finalStep = stage;
    if (stage.type === StepType.LoginSuccess || getCallbackWithMessageSafely(finalStep, MESSAGE_OTP_EXPIRED)) {
      return stage;
    }
    // If user is signing up for the first time after 24 hours
    if (getCallbackOfTypeSafely(stage, CallbackType.TextOutputCallback) && getCallbackWithIdSafely(stage, GIVEN_NAME)) {
      finalStep = await executeHiddenCallbackForUserContextRetrieval(dispatch, stage);
    }
    // if otp count 3 select retry from the node response, so as to  trigger "possible" second otp flow
    if (otpCount === 3) {
      finalStep = await chooseRetryToSelectSecondOTPFlow(dispatch, stage, '1');
    }
  }
  return finalStep;
};

export default sendOtp;

import client from '../../restClients/authenticatedBff';
import ActionTypes from './actionTypes';
import { LOGOUT_BFF } from './constants';

export const logoutStartAction = () => {
  return {
    type: ActionTypes.LOGOUT_START,
  };
};

export const logoutSuccessAction = (payload) => {
  return {
    type: ActionTypes.LOGOUT_SUCCESS,
    payload,
  };
};

export const logoutFailureAction = (payload) => {
  return {
    type: ActionTypes.LOGOUT_FAILURE,
    payload,
  };
};
export const logoutCleanUpAction = () => {
  return {
    type: ActionTypes.LOGOUT_CLEANUP,
  };
};

export const logoutHandler = (realm) => {
  const requestBody = {
    realm,
  };
  return async (dispatch) => {
    dispatch(logoutStartAction());
    await client
      .post(LOGOUT_BFF, requestBody)
      .then((response) => {
        dispatch(logoutSuccessAction(response));
      })
      .catch((err) => {
        if (err?.response?.status === 401) {
          // In the case of a 401, we can assume the session simply ended before the logout
          // call was done.  We should consider the logout successful.
          dispatch(logoutSuccessAction(err.response));
        } else {
          dispatch(logoutFailureAction(err.response));
        }
      });
  };
};

export const actionCreators = {
  logoutHandler,
};

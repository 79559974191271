import { SET_EMBEDDED, SET_GOTO_EMBEDDED } from '../components/Embedded/actions';

const initialState = {
  isEmbedded: false,
  returnUrl: null,
};

const commonReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_EMBEDDED:
      return {
        ...state,
        isEmbedded: action.payload,
      };
    case SET_GOTO_EMBEDDED:
      return {
        ...state,
        goToEmbedded: action.payload,
      };
    default:
      return state;
  }
};

export default commonReducer;

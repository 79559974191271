import { CallbackType, Config } from '@forgerock/javascript-sdk/lib';
import { AUTH_TREE_REALM_MAPPING } from 'components/Login/constants';
import nextStep from '../../../../utils/loginAuthnTree/handleStep';
import determineConfig from '../../../../utils/frAuthConfig';
import AuthTreeError from '../../../../utils/authTree/AuthTreeError';
import { isFailed } from '../../../../utils/authTree/helpers';

export default async function signinforOTP(dispatch, payload) {
  const { username, password, realm, brand } = payload;
  Config.set(determineConfig(AUTH_TREE_REALM_MAPPING[realm], realm, brand));
  const secondStep = await nextStep(dispatch);
  const nameCallback = secondStep.getCallbackOfType(CallbackType.NameCallback);
  const passwordCallback = secondStep.getCallbackOfType(CallbackType.PasswordCallback);
  nameCallback.setName(username);
  passwordCallback.setPassword(password);
  const thirdStep = await nextStep(dispatch, secondStep);
  if (isFailed(thirdStep)) {
    throw new AuthTreeError('Cannot activate with provided credentials', thirdStep, 'D_634');
  } else {
    const userInfoRes = thirdStep.getCallbackOfType(CallbackType.TextOutputCallback).getOutputByName('message');
    if (isFailed(userInfoRes)) {
      throw new AuthTreeError('Cannot activate with provided credentials', userInfoRes, 'D_634');
    }

    return userInfoRes;
  }
}

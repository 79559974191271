import { CallbackType } from '@forgerock/javascript-sdk/lib';
import nextStep from './handleStep';
import { OTP_RETRY_OR_RESEND } from './constants';

const chooseResendOrRetryAfterOTPExpiry = async (dispatch, step) => {
  const confirmationCallback = step.getCallbackOfType(CallbackType.ConfirmationCallback);
  confirmationCallback.setInputValue(OTP_RETRY_OR_RESEND);
  const secondStep = await nextStep(dispatch, step);
  return secondStep;
};

export default chooseResendOrRetryAfterOTPExpiry;

import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { MobileInputExperimental } from '@manulife/mux';
import {
  ciamErrorsToMuxErrors,
  filterErrorsForComponent,
  useValidation,
  validMUXMobileNumber,
  ErrorShape,
} from 'ciam-self-service-shared';
import FormElement from '../FormElement';
import muxCustomStyles from '../../../assets/styles/muxCustomStyles';

function FormMobileInput(props) {
  const { id, label, value, onChange, customStyle, errors, validationsCombinedLabel, disabled, ...otherProps } = props;
  const intl = useIntl();
  const filteredErrors = filterErrorsForComponent(errors, id);
  const muxErrors = ciamErrorsToMuxErrors(filteredErrors, intl);
  const countryCodeRef = useRef(null);

  const mobileFullNumber = value.fullPhoneNumber === undefined ? '' : value?.fullPhoneNumber;

  const [validationErrors, setShowValidationErrors] = useValidation(
    validMUXMobileNumber,
    mobileFullNumber,
    label,
    validationsCombinedLabel,
    countryCodeRef,
  );
  const combinedFieldErrors = [...muxErrors, ...validationErrors];

  useEffect(() => {
    if (disabled) {
      setShowValidationErrors(false);
    }
  }, [disabled, setShowValidationErrors]);

  // When the form that this element belongs to is submited, show validation errors
  useEffect(() => {
    const input = countryCodeRef?.current;
    const form = input?.form;

    if (form) {
      const handleSubmit = () => setShowValidationErrors(true);
      form.addEventListener('submit', handleSubmit);
      return () => form.removeEventListener('submit', handleSubmit);
    }

    return undefined;
  }, [countryCodeRef, setShowValidationErrors]);

  return (
    <FormElement>
      <MobileInputExperimental
        countryCodeMap={{
          '+1': {
            mask: '(999) 999-9999',
          },
        }}
        customStyle={{ ...muxCustomStyles.textInput, ...customStyle }}
        errors={combinedFieldErrors}
        label={label}
        onChange={onChange}
        onBlur={() => setShowValidationErrors(true)}
        tooltip=""
        value={value}
        callouts
        disabled={disabled}
        countryCodeInputRef={countryCodeRef}
        {...otherProps}
      />
    </FormElement>
  );
}

FormMobileInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  customStyle: PropTypes.shape({}),
  errors: PropTypes.arrayOf(ErrorShape),
  validationsCombinedLabel: PropTypes.string,
  disabled: PropTypes.bool,
};

FormMobileInput.defaultProps = {
  customStyle: {},
  errors: [],
  validationsCombinedLabel: undefined,
  disabled: false,
};

export default FormMobileInput;

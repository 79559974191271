import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { LANG } from '@manulife/mux';
import {
  Helmet,
  Layout,
  LoadingPage,
  useCIAMContextParam,
  useCIAMHistory,
  usePathBuilder,
  useQueryParams,
  useRealm,
} from 'ciam-self-service-shared';
import PropTypes from 'prop-types';
import qs from 'qs';

import { logoutHandler, logoutCleanUpAction } from '../../Logout/actions';
import { setEmbedded, setGoToEmbedded } from '../../Embedded/actions';
import { isEmbeddedSelector, goToEmbeddedSelector } from '../../Embedded/selectors';
import { changeLocale } from '../../../actions/actions';
import useAuthCheck from '../../../hooks/useAuthCheck';
import useGoToUrl from '../../../hooks/useGoToUrl';
import useStoredQueryString from '../../../hooks/useStoredQueryString';
import { SIGN_OUT_REASONS } from '../../../utils/constants';
import redirectToAuthenticated from '../../../utils/redirectToAuthenticated';

const NON_EMBEDDED_VALUES = [undefined, 'false'];

const AUTH_TYPE = {
  AUTHENTICATED: 'authenticated',
  UNAUTHENTICATED: 'unauthenticated',
  NONE: 'none',
};

function Page(props) {
  const { title, authType, children, showLangToggle, showContactUs, showRealmToggle, showManageId, heading } = props;
  const ciamHistory = useCIAMHistory();
  const realm = useRealm();
  const brand = useCIAMContextParam('brand');
  const { locale } = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const pathBuilder = usePathBuilder();
  const queryParams = useQueryParams();
  const gotoUrl = useGoToUrl();
  const logoutError = useSelector((state) => state?.loginInfo?.logoutError);

  const { isAuthLoading, isLoggedIn, logoutStarted } = useAuthCheck(authType);

  const isEmbedded = useStoredQueryString('embedded', isEmbeddedSelector, (value) => {
    const embedded = value?.toLowerCase() !== 'false';
    return setEmbedded(embedded);
  });

  const goToEmbedded = useStoredQueryString('goto', goToEmbeddedSelector, (value) => {
    const goToParams = qs.parse(value);
    const goToEmbeddedValue = !NON_EMBEDDED_VALUES.includes(goToParams?.embedded?.toLowerCase());
    return setGoToEmbedded(goToEmbeddedValue);
  });

  const [redirectToLogin, setRedirectToLogin] = useState(false);

  const shouldRedirectToSignIn = !isLoggedIn && !isAuthLoading && authType === AUTH_TYPE.AUTHENTICATED;
  const shouldRedirectToAuthenticated = isLoggedIn && !isAuthLoading && authType === AUTH_TYPE.UNAUTHENTICATED;
  const isLoading = isAuthLoading || shouldRedirectToSignIn || shouldRedirectToAuthenticated;

  useEffect(() => {
    function removeGotoParams() {
      const url = new URL(window.location.href);
      url.searchParams.delete('goto');
      url.searchParams.delete('type');
      history.push(`/signin${url.search}`);
    }

    if (shouldRedirectToSignIn) {
      removeGotoParams();
    }

    if (shouldRedirectToAuthenticated) {
      redirectToAuthenticated(gotoUrl, locale, ciamHistory, realm, brand, logoutStarted);
    }
  }, [
    shouldRedirectToSignIn,
    shouldRedirectToAuthenticated,
    ciamHistory,
    history,
    locale,
    gotoUrl,
    realm,
    brand,
    logoutStarted,
    location.pathname,
  ]);

  const handleRedirectClick = () => {
    if (!redirectToLogin) {
      setRedirectToLogin(true);
    }
  };

  const redirect = () => {
    if (!isLoggedIn) {
      return (
        <Redirect
          to={pathBuilder('/signin', true, {
            searchParams: { sign_out: SIGN_OUT_REASONS.SIGNED_OUT },
          })}
          push
        />
      );
    }

    return undefined;
  };

  const onLogoutDismiss = () => dispatch(logoutCleanUpAction());

  const handleLogout = async () => {
    const action = logoutHandler(realm);
    await action(dispatch);

    handleRedirectClick();
  };

  const handleLanguageToggle = () => {
    const newLocale = {
      locale: locale === LANG.FR_CA ? LANG.EN_CA : LANG.FR_CA,
      messages: {},
    };
    dispatch(changeLocale(newLocale));
    const pathnameWithoutRealm = location.pathname.replace(new RegExp(`^/${realm}/?`), '/');
    history.push(
      pathBuilder(pathnameWithoutRealm, true, {
        locale: newLocale.locale,
        realm,
        searchParams: { ...queryParams, ui_locales: newLocale.locale },
      }),
      { ...location.state },
    );
  };

  return (
    <Layout
      isEmbedded={isEmbedded || goToEmbedded}
      isLoggedIn={isLoggedIn}
      onLogout={handleLogout}
      onLanguageToggle={handleLanguageToggle}
      logoutError={logoutError}
      onLogoutDismiss={onLogoutDismiss}
      showLangToggle={showLangToggle}
      showContactUs={showContactUs}
      showRealmToggle={showRealmToggle}
      showManageId={showManageId}
      heading={heading}
    >
      <Helmet title={title} />
      {isLoading ? <LoadingPage /> : children}
      {redirectToLogin ? redirect() : null}
    </Layout>
  );
}

Page.propTypes = {
  // eslint-disable-next-line react/require-default-props
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  authType: PropTypes.oneOf(Object.values(AUTH_TYPE)),
  showLangToggle: PropTypes.bool,
  showContactUs: PropTypes.bool,
  showRealmToggle: PropTypes.bool,
  showManageId: PropTypes.bool,
  heading: PropTypes.string,
};

Page.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  title: '',
  children: '',
  authType: AUTH_TYPE.NONE,
  showLangToggle: true,
  showContactUs: true,
  showRealmToggle: true,
  showManageId: true,
  heading: null,
};

export { AUTH_TYPE };
export default Page;

import AuthTreeError from '../../../utils/authTree/AuthTreeError';

export class OnDemandOTPRedirectError extends AuthTreeError {
  constructor(step) {
    super('redirect required', step);
  }
}

export class OnDemandOTPUnauthenticatedError extends AuthTreeError {
  constructor(step) {
    super('session terminated', step);
  }
}

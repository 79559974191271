const getSubDomain = (url) => {
  const hostnameArray = url.hostname.split('.');
  const numberOfSubdomains = hostnameArray.length - 2;
  return hostnameArray.length === 2 ? url.hostname : hostnameArray.slice(numberOfSubdomains).join('.');
};

const isInboundUrlNotAuthorized = (url) => {
  const urlObject = new URL(url);
  const domain = getSubDomain(urlObject);
  return !window.REACT_APP_VALID_REDIRECT_SUBDOMAINS?.split(',').includes(domain);
};

function redirectInbound(url) {
  if (isInboundUrlNotAuthorized(url)) {
    return null;
  }
  return url;
}

export default redirectInbound;

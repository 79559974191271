import { LANG } from '@manulife/mux';
import { MESSAGES as SHARED_MESSAGES } from 'ciam-self-service-shared';
import en from './en.json';
import fr from './fr.json';

// Merge shared messages with local messages
// Shared messages have their own namespace, so collisions shouldn't be a concern
const MESSAGES = {
  [LANG.EN_CA]: { ...en, ...SHARED_MESSAGES[LANG.EN_CA] },
  [LANG.FR_CA]: { ...fr, ...SHARED_MESSAGES[LANG.FR_CA] },
};

export default MESSAGES;

import axios from 'axios';
import { newUrl, getAMRealms } from 'utils/realmMapping';

const requestOptions = {
  headers: {
    'Content-Type': 'application/json',
    'accept-api-version': 'protocol=1.0,resource=2.1',
    Accept: '*/*',
  },
  withCredentials: true,
};

export default async function firstStep(uri, realm) {
  const AM_URL = newUrl(realm);
  const realmName = getAMRealms(realm);
  const url = `${AM_URL}/json/realms/root/realms${realmName}/authenticate?goto=${uri}&authIndexType=service&authIndexValue=inboundSSO`;
  const response = await axios.post(url, null, requestOptions);
  const { data } = response;
  const { authId } = data;
  const {
    callbacks: [
      {
        output: [{ value: redirectCallback }],
      },
    ],
  } = data;

  return { authId, redirectCallback };
}

export async function secondStep(realm, authId) {
  const AM_URL = newUrl(realm);
  const realmName = getAMRealms(realm);
  const queryParams = new URLSearchParams(window.location.search);
  const myQueryParams = {};
  const url = `${AM_URL}/json/realms/root/realms${realmName}/authenticate?${queryParams}`;

  queryParams.forEach((value, key) => {
    myQueryParams[key] = value;
  });

  const payload = JSON.stringify({
    authId,
    realm: realmName,
    ...myQueryParams,
  });

  const response = await axios.post(url, payload, requestOptions);
  const { data } = response;
  let { successUrl } = data;
  const { callbacks } = data;
  if (callbacks) {
    [
      {
        output: [{ value: successUrl }],
      },
    ] = callbacks;
  }
  return { successUrl };
}

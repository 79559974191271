import useGoToUrl from './useGoToUrl';

const UniversalLinkPrefix = 'https://manulife.ca/personal/universal';

export default function useUniversalLink() {
  const goto = useGoToUrl();
  const isUniversalLink = goto?.startsWith(UniversalLinkPrefix) || false;

  if (isUniversalLink) {
    return goto;
  }

  return null;
}

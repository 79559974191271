import { updateErrorCode } from 'components/Login/actions';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useCIAMHistory, usePathBuilder } from 'ciam-self-service-shared';
import { Config } from '@forgerock/javascript-sdk/lib';
import { FORGOT_PASSWORD_TREE } from 'components/Login/constants';
import { mapErrorFromJson } from 'utils/error-map';

const useAuthTreeErrorHandler = () => {
  const dispatch = useDispatch();
  const history = useCIAMHistory();
  const pathBuilder = usePathBuilder();
  const errorHandler = useCallback(() => {
    if (Config.get()?.tree === FORGOT_PASSWORD_TREE) {
      // catch unhandled error from server/ UI uncaught exceptions in forgot password flow
      dispatch(updateErrorCode(mapErrorFromJson('D_704')));
      history.push('/forgotpassword', { showError: true });
    } else {
      dispatch(updateErrorCode(mapErrorFromJson('D_705')));
      history.push(pathBuilder('/signin'));
    }
  }, [dispatch, history, pathBuilder]);
  return errorHandler;
};

export default useAuthTreeErrorHandler;

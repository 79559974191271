import { CallbackType } from '@forgerock/javascript-sdk/lib';
import { OTP_TEST_ACCOUNT } from 'components/Login/constants';
import { getCallbackOfTypeSafely, getCallbackWithIdSafely } from '../authTreeUtils';
import nextStep from './handleStep';

const executeHiddenCallbackForOTPAutomatedTesting = async (dispatch, step) => {
  let secondStep = step;
  if (
    getCallbackOfTypeSafely(step, CallbackType.TextOutputCallback) &&
    getCallbackWithIdSafely(step, OTP_TEST_ACCOUNT)
  ) {
    const hiddenValCallback = step.getCallbackOfType(CallbackType.HiddenValueCallback);
    hiddenValCallback.setInputValue(OTP_TEST_ACCOUNT);
    secondStep = await nextStep(dispatch, step);
  }
  return secondStep;
};

export default executeHiddenCallbackForOTPAutomatedTesting;

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useRichIntl, FormattedRichMessage, ContentPage } from 'ciam-self-service-shared';
import Page from '../../common/Page';
import SigninButton from '../../common/SigninButton';

const ParagraphWrapper = styled.div`
  padding-bottom: 1em;
`;

function ConfirmMailPage(props) {
  const richIntl = useRichIntl();
  const { name } = props;
  const greeting = name ? `, ${name}` : '';

  return (
    <Page
      title={richIntl.formatMessage({ id: 'comfirmMail.page.title' }, { greeting })}
      heading={richIntl.formatMessage({ id: 'comfirmMail.page.title' }, { greeting })}
    >
      <ContentPage heading={<FormattedRichMessage id="comfirmMail.page.title" values={{ greeting }} />}>
        <ParagraphWrapper>
          <FormattedRichMessage id="confirmMail.content" />
        </ParagraphWrapper>
        <SigninButton />
      </ContentPage>
    </Page>
  );
}

ConfirmMailPage.propTypes = {
  name: PropTypes.string,
};

ConfirmMailPage.defaultProps = {
  name: '',
};

export default ConfirmMailPage;

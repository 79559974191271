import initState from '../../reducers/initState';
import ActionTypes from './actionTypes';

const confirmEmail = (state = initState.confirmEmail, action) => {
  switch (
    action.type // NOSONAR
  ) {
    case ActionTypes.CONFIRM_EMAIL_SUCCESS: {
      const name = action.firstName;
      return {
        ...state,
        confirmEmailStatus: 'Confirmed',
        name,
      };
    }
    case ActionTypes.CONFIRM_EMAIL_FAILURE: {
      return {
        ...state,
        confirmEmailStatus: 'ConfirmFail',
      };
    }
    default:
      return state;
  }
};

export default confirmEmail;

import useGoToUrl from './useGoToUrl';

/**
 * This hook will search the URL parameters for flow and return true if flow = `inboundSSO` or else it will return false.
 * @returns boolean
 */

export default function useIsInboundSSO() {
  const goto = useGoToUrl();
  const params = new URLSearchParams(goto);
  const inbound = 'inboundSSO';
  return !!(params.get('service') && params.get('service') === inbound);
}

import { CallbackType, StepType } from '@forgerock/javascript-sdk/lib';
import { updateOtpCountAction, updateOTPRequestedMultipleTimes } from 'components/Login/actions';
import { MESSAGE_OTP_VERIFICATION_FAILED, FIRST_TIME_LOGIN } from 'components/Login/constants';
import { asyncWrapper } from '../async';
import nextStep from './handleStep';
import successRedirection from './common/successRedirection';
import { getCallbackOfTypeSafely, getCallbackWithIdSafely, getCallbackWithMessageSafely } from '../authTreeUtils';

const gotoMyAccountHandlerAsyncAction = async (dispatch, payload) => {
  const { stage, history, gotoURI, locale, realm, brand } = payload;
  dispatch(updateOtpCountAction(0));
  dispatch(updateOTPRequestedMultipleTimes(true));
  let finalStep = '';
  if (
    getCallbackOfTypeSafely(stage, CallbackType.TextOutputCallback) &&
    getCallbackWithMessageSafely(stage, MESSAGE_OTP_VERIFICATION_FAILED)
  ) {
    const confirmationCallback = stage.getCallbackOfType(CallbackType.ConfirmationCallback);
    confirmationCallback.setInputValue('1');
    finalStep = await nextStep(dispatch, stage);
  }
  if (
    getCallbackOfTypeSafely(stage, CallbackType.TextOutputCallback) &&
    getCallbackWithIdSafely(stage, FIRST_TIME_LOGIN)
  ) {
    const hiddenValueCallback = stage.getCallbackOfType(CallbackType.HiddenValueCallback);
    hiddenValueCallback.setInputValue(FIRST_TIME_LOGIN);
    finalStep = await nextStep(dispatch, stage);
  }
  if (finalStep?.type === StepType.LoginSuccess) {
    successRedirection(dispatch, { finalStep, history, gotoURI, locale, realm, brand });
  }
};

export default asyncWrapper(gotoMyAccountHandlerAsyncAction);

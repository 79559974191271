import ActionTypes from '../actions/actionTypes';
import initState from './initState';

const localeReducer = (state = initState.intl, action) => {
  switch (action.type) {
    case ActionTypes.INIT_STORE: {
      return action.intl;
    }

    case ActionTypes.CHANGE_LOCALE_LANGUAGE_SUCCESS: {
      const todo = action.payload;
      return todo;
    }

    default:
      return state;
  }
};

export default localeReducer;

import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { BUTTON_VARIANT, H2 } from '@manulife/mux';
import {
  FormattedRichMessage,
  useCIAMHistory,
  useRealm,
  usePathBuilder,
  useCIAMContextParam,
  ContentPage,
  CoBrandingButton,
  customStyles,
} from 'ciam-self-service-shared';
import handleOtpForAddNumberAsyncAction from 'utils/loginAuthnTree/handleOtpForAddNumberAsyncActionImpl';
import resendForAddNumberAsyncAction from 'utils/loginAuthnTree/resendForAddNumberAsyncAction';
import goBackHandlerAsyncAction from 'utils/loginAuthnTree/goBackHandlerAsyncAction';
import doItLaterHandlerAsyncAction from 'utils/loginAuthnTree/doItLaterHandlerAsyncAction';
import useAuthTreeErrorHandler from '../views/authTree/useAuthTreeErrorHandler';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FormElement from '../../common/FormElement';
import MFAEnterCode from '../../common/MFA/MFAEnterCode';
import userContextUtils from '../../../utils/userContextUtils';
import useGoToUrl from '../../../hooks/useGoToUrl';
import Page, { AUTH_TYPE } from '../../common/Page';
import {
  updateAddNumberLoadingStatus,
  updateDoItLaterLoadingStatus,
  updateGoBackLoadingStatus,
} from 'components/Login/actions';

const AddMobileVerifyMfa = () => {
  const dispatch = useDispatch();
  const ciamHistory = useCIAMHistory();
  const stage = useSelector((state) => state.loginInfo.stage);
  const otpCount = useSelector((state) => state.loginInfo.otpCount);
  const isOTPGeneratedMultipleTimes = useSelector((state) => state.loginInfo.isOTPGeneratedMultipleTimes);
  const isLoginAfterError = useSelector((state) => state.loginInfo.isLoginAfterError);
  const addNumberLoadingStatus = useSelector((state) => state.loginInfo.addNumberLoadingStatus);
  const doItLaterLoadingStatus = useSelector((state) => state.loginInfo.doItLaterLoadingStatus);
  const goBackLoadingStatus = useSelector((state) => state.loginInfo.goBackLoadingStatus);
  const mobileNumber = useSelector((state) => state.loginInfo.mobileNumber);
  const loginError = useSelector((state) => state.loginInfo.loginError);
  const gotoURI = useGoToUrl();
  const intl = useIntl();
  const realm = useRealm();
  const history = useHistory();
  const pathBuilder = usePathBuilder();
  const brand = useCIAMContextParam('brand');
  const authTreeErrorHandler = useAuthTreeErrorHandler();

  const doItLater = async () => {
    const payload = {
      stage,
      history,
      gotoURI,
      realm,
      ciamHistory,
      pathBuilder,
      brand,
      locale: intl.locale,
    };
    try {
      dispatch(updateDoItLaterLoadingStatus(true));
      await doItLaterHandlerAsyncAction(dispatch, payload);
    } catch (err) {
      authTreeErrorHandler(err);
    } finally {
      dispatch(updateDoItLaterLoadingStatus(false));
    }
  };
  const goBack = async () => {
    const payload = {
      stage,
      history,
      realm,
      brand,
      ciamHistory,
      pathBuilder,
    };
    try {
      dispatch(updateGoBackLoadingStatus(true));
      await goBackHandlerAsyncAction(dispatch, payload);
    } catch (err) {
      authTreeErrorHandler(err);
    } finally {
      dispatch(updateGoBackLoadingStatus(false));
    }
  };

  const handleSubmitCode = async (_, payload) => {
    try {
      dispatch(updateAddNumberLoadingStatus(true));
      await handleOtpForAddNumberAsyncAction(dispatch, payload);
    } catch (err) {
      authTreeErrorHandler(err);
    } finally {
      dispatch(updateAddNumberLoadingStatus(false));
    }
  };

  const handleRequestResend = async (_, payload) => {
    try {
      await resendForAddNumberAsyncAction(dispatch, payload);
    } catch (err) {
      authTreeErrorHandler(err);
    }
  };

  return (
    <Page title={intl.formatMessage({ id: 'mfa.enterCode.page.title' })} authType={AUTH_TYPE.UNAUTHENTICATED}>
      <ContentPage
        heading={
          // eslint-disable-next-line react/jsx-wrap-multilines
          <FormattedRichMessage
            id="mfa.verifyMobile.enterCode.page.title"
            values={{
              new: isOTPGeneratedMultipleTimes ? intl.formatMessage({ id: `mfa.enterCode.new` }) : '',
            }}
          />
        }
      >
        <MFAEnterCode
          channel={userContextUtils.getChannels({ channelList: ['Mobile'], mobile: mobileNumber })}
          onSubmitCode={handleSubmitCode}
          singleChannel
          stage={stage}
          onRequestResend={handleRequestResend}
          otpCount={otpCount}
          hasResentOtp={isOTPGeneratedMultipleTimes}
          error={loginError}
          isLoginAfterError={isLoginAfterError}
          isLoading={addNumberLoadingStatus}
          header={<FormattedMessage id="mfa.verifyMobile.enterCode.page.title" />}
          content={<FormattedRichMessage id="mfa.verifyMobile.enterCode.timeReminder" values={{ mobileNumber }} />}
        />

        <H2>
          <FormattedMessage id="signin.addmobile.verify.didnotgetcode.title" />
        </H2>
        <FormattedRichMessage
          id="signin.addmobile.verify.didnotgetcode.text"
          values={{
            mobileNumber,
          }}
        />

        <FormElement spacing={2}>
          <CoBrandingButton
            variant={BUTTON_VARIANT.SECONDARY}
            customStyle={customStyles.button}
            onClick={goBack}
            disabled={goBackLoadingStatus}
            saving={goBackLoadingStatus}
          >
            <FormattedMessage id="signin.addmobile.gobackButton" />
          </CoBrandingButton>
        </FormElement>

        <FormElement spacing={1}>
          <CoBrandingButton
            variant={BUTTON_VARIANT.SECONDARY}
            customStyle={customStyles.button}
            onClick={doItLater}
            disabled={doItLaterLoadingStatus}
            saving={doItLaterLoadingStatus}
          >
            <FormattedMessage id="signin.addmobile.doitlaterButton" />
          </CoBrandingButton>
        </FormElement>
      </ContentPage>
    </Page>
  );
};

export default AddMobileVerifyMfa;

import client from '../restClients/authenticatedBff';
import { BFF_SIGNUP, BFF_MATCH_PROFILE } from '../components/Signup/constants';

// eslint-disable-next-line import/prefer-default-export
export const registerUserReq = (registrationInfo, realm, brand) => {
  const registrationRequestBody = {
    userName: registrationInfo.username,
    mail: registrationInfo.email,
    password: registrationInfo.password,
    sn: registrationInfo.lastName,
    givenName: registrationInfo.firstName,
    dob: registrationInfo.dob,
    lang: registrationInfo.lang,
    goto: registrationInfo.goto,
    realm,
    brand,
  };
  return client.post(BFF_SIGNUP, registrationRequestBody);
};

export const checkDuplicateRegistration = async (registrationInfo, realm) => {
  try {
    const body = {
      email: registrationInfo.email,
      givenName: registrationInfo.firstName,
      lastName: registrationInfo.lastName,
      dob: registrationInfo.dob,
      realm,
    };

    const response = await client.post(BFF_MATCH_PROFILE, body);
    return Boolean(response.data.status);
  } catch {
    return false;
  }
};

import { CallbackType, StepType } from '@forgerock/javascript-sdk/lib';
import { updateStageAction } from 'components/Login/actions';
import nextStep from './handleStep';

const sameChannelOrDifferent = async (dispatch, step, choice) => {
  if (step.type === StepType.LoginFailure) {
    dispatch(updateStageAction(step));
  } else {
    const confirmationCallback = step.getCallbackOfType(CallbackType.ConfirmationCallback);
    confirmationCallback.setInputValue(choice);
    const secondStep = await nextStep(dispatch, step);
    return secondStep;
  }
  return step;
};

export default sameChannelOrDifferent;

import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { H2, BUTTON_VARIANT } from '@manulife/mux';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  FormattedRichMessage,
  ErrorShape,
  ContentPage,
  useRichIntl,
  useCIAMHistory,
  useRealm,
  useCIAMContextParam,
  CIAMLinkButton,
} from 'ciam-self-service-shared';
import useIsInboundSSO from '../../../hooks/useIsInboundSSO';
import Page, { AUTH_TYPE } from '../../common/Page';
import AnalyticsDataLayer from '../../common/Analytics';
import muxCustomStyles from '../../../assets/styles/muxCustomStyles';
import { SIGNUP_PAGE_NAME, EVENT_REGISTER_START } from '../constants';
import SignupForm from './SignUpForm';
import { AsyncActionState } from '../../../utils/constants';
import { checkDuplicateRegistration, registerUserReq } from '../../../utils/SignUp';
import { completeRegistrationAction, registerFailureAction, startRegistrationAction } from '../actions';
import SignUpDuplicateWarningModal from './SignUpDuplicateWarningModal';
import SigninButton from '../../common/SigninButton';
import useUniversalLink from '../../../hooks/useUniversalLink';

function SignupPage(props) {
  const inboundSSO = useIsInboundSSO();
  const { registrationInfo, error } = props;
  const richIntl = useRichIntl();
  const dispatch = useDispatch();
  const history = useCIAMHistory();
  const realm = useRealm();
  const isSigningUp = registrationInfo?.registrationState === AsyncActionState.Loading;
  const [duplicateData, setDuplicateData] = useState(null);
  const universalLink = useUniversalLink();
  const brand = useCIAMContextParam('brand');

  const handleSignup = useCallback(
    async (formData) => {
      try {
        dispatch(startRegistrationAction(registrationInfo));

        // Don't check for duplicates again when we've already warned the user
        if (!duplicateData) {
          const isPossibleDuplicate = await checkDuplicateRegistration(formData, realm);
          if (isPossibleDuplicate) {
            setDuplicateData(formData);
            return;
          }
        }

        const response = await registerUserReq(formData, realm, brand);
        if (response.data.userName) {
          dispatch(
            completeRegistrationAction({ ...response.data, email: formData.email, ciamId: response.data.ciamId }),
          );
          history.push('/register/verify');
        } else {
          dispatch(registerFailureAction(response.data));
        }
      } catch (err) {
        dispatch(registerFailureAction(err));
      }
    },
    [dispatch, history, realm, brand, registrationInfo, duplicateData],
  );

  const handleSignin = useCallback(() => {
    setDuplicateData(null);
    dispatch(registerFailureAction(null));
    if (universalLink) {
      window.location.href = universalLink;
    } else {
      history.push('/signin');
    }
  }, [dispatch, universalLink, history]);

  const handleDuplicateCreateAnyway = useCallback(() => {
    handleSignup(duplicateData);
    setDuplicateData(null);
  }, [duplicateData, handleSignup]);

  const handleDuplicateForgotUsername = useCallback(() => {
    history.push('/forgotusername');
  }, [history]);

  return (
    <Page title={richIntl.formatMessage({ id: 'signup.page.title' })} authType={AUTH_TYPE.UNAUTHENTICATED}>
      <AnalyticsDataLayer pageName={SIGNUP_PAGE_NAME} ignoreSession />
      <AnalyticsDataLayer eventName={EVENT_REGISTER_START} ignoreSession />
      <ContentPage heading={<FormattedRichMessage id="signup.page.heading" />}>
        <SignupForm onSignup={handleSignup} isSigningUp={isSigningUp} error={error} />
        {!inboundSSO ? (
          <>
            <H2>
              <FormattedRichMessage id="signup.signInInstead" />
            </H2>
            <SigninButton variant={BUTTON_VARIANT.SECONDARY}>
              <FormattedMessage id="signup.signInButton" />
            </SigninButton>
          </>
        ) : (
          <CIAMLinkButton
            to="/intro"
            variant={BUTTON_VARIANT.TERTIARY_BACK}
            customStyle={muxCustomStyles.tertiaryButton}
          >
            <FormattedRichMessage id="common.back" />
          </CIAMLinkButton>
        )}
        <SignUpDuplicateWarningModal
          isOpen={Boolean(duplicateData)}
          onSignin={handleSignin}
          onCreateAnyway={handleDuplicateCreateAnyway}
          onForgotUsername={handleDuplicateForgotUsername}
        />
      </ContentPage>
    </Page>
  );
}

SignupPage.propTypes = {
  registrationInfo: PropTypes.shape({
    id: PropTypes.string,
    registrationState: PropTypes.string,
  }),
  error: ErrorShape,
};

SignupPage.defaultProps = {
  registrationInfo: {},
  error: {},
};

export default SignupPage;

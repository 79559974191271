import React from 'react';
import { FormattedRichMessage, ContentPage, CIAMLinkButton } from 'ciam-self-service-shared';
import Page from 'components/common/Page';
import styled from 'styled-components';
import { MessageCard, MESSAGE_VARIANT } from '@manulife/mux';

const Wrapper = styled.div`
  margin: 5rem 0;
`;

export default function ErrorPage() {
  sessionStorage.removeItem('ciam.addNumberbackButton-otp');
  return (
    <Page title="Error">
      <ContentPage>
        <MessageCard variant={MESSAGE_VARIANT.PRIMARY_ALERT}>
          <FormattedRichMessage id="signin.addmobile.error.messageCard" />
        </MessageCard>
        <Wrapper>
          <CIAMLinkButton to="/signin">
            <FormattedRichMessage id="signin.addmobile.errorButton" />
          </CIAMLinkButton>
        </Wrapper>
      </ContentPage>
    </Page>
  );
}

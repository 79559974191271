import { Config } from '@forgerock/javascript-sdk/lib';
import { updateOtpCountAction, updateOTPRequestedMultipleTimes, updateRealmAction } from 'components/Login/actions';
import { asyncWrapper } from '../async';
import determineConfig from '../frAuthConfig';
import nextStep from './handleStep';

const initAsyncAction = async (dispatch, treeName, realm, brand) => {
  dispatch(updateRealmAction(realm));
  // Set configuration defaults
  Config.set(determineConfig(treeName, realm, brand));
  await nextStep(dispatch);
  // reset all the counts in case when refresh doesn't update the count
  dispatch(updateOtpCountAction(0));
  dispatch(updateOTPRequestedMultipleTimes(false));
};

export default asyncWrapper(initAsyncAction);

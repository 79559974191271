import { connect } from 'react-redux';
import SignUpView from './views/SignUpView';
import { startRegistrationAction, completeRegistrationAction, registerFailureAction } from './actions';

const mapStateToProps = (state) => {
  return {
    registrationInfo: state.registrationInfo,
  };
};

export const mapDispatchToProps = (dispatch) => ({
  startRegistration: (payload) => dispatch(startRegistrationAction(payload)),
  completeRegistration: (payload) => dispatch(completeRegistrationAction(payload)),
  registerFailure: (payload) => dispatch(registerFailureAction(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpView);

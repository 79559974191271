import { FRAuth, StepType } from '@forgerock/javascript-sdk/lib';
import { updateStageAction, createSessionAction, createSessionStatus } from 'components/Login/actions';
import removeSessionStorageAttributes from 'utils/removeSessionStorageAttributes';

const handleStep = async (dispatch, step) => {
  switch (step.type) {
    case StepType.LoginSuccess:
      dispatch(updateStageAction(step));
      await createSessionAction(step).then((sessionData) => {
        dispatch(createSessionStatus(sessionData));
      });
      break;

    case StepType.LoginFailure:
      dispatch(updateStageAction(step)); // TODO: ??
      break;

    default:
      dispatch(updateStageAction(step));
  }
  if (step?.payload?.code >= 400) {
    // remove all values from session Storage
    removeSessionStorageAttributes();
  }

  return step;
};

const nextStep = async (dispatch, step) => {
  return FRAuth.next(step)
    .then((newStep) => handleStep(dispatch, newStep))
    .catch(() => 'LoginFailure');
};

export default nextStep;

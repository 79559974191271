import init from './steps/init';
import requestResend from './steps/requestResend';
import submitCode from './steps/submitCode';
import submitCredentials from './steps/submitCredentials';

export default {
  init,
  submitCode,
  requestResend,
  submitCredentials,
};

import ActionTypes from './actionTypes';
import initState from '../../reducers/initState';

// eslint-disable-next-line import/prefer-default-export
export const forgotUsernameInfo = (state = initState.forgotUsernameInfo, action) => {
  switch (action.type) {
    case ActionTypes.INIT_STORE: {
      return action.forgotUsernameInfo;
    }
    case ActionTypes.ADD_EMAIL_OR_PHONE: {
      const { emailOrPhoneValue } = action;
      return {
        ...state,
        emailOrPhoneValue,
      };
    }
    default: {
      return state;
    }
  }
};

/* This file contains a list of route mappings, they are not named react components */
/* eslint-disable react/display-name */

import * as React from 'react';
import { renderRoutes } from 'react-router-config';
import { getRealmAwarePaths } from 'ciam-self-service-shared';
import LoginMFASelectChannelPage from 'components/LoginMFA/LoginMFASelectChannelPage';
import SignInPage from 'components/Login/views/SignInPage';
import IntroductionPage from 'components/Login/views/IntroductionPage';
import AddMobileContainer from './components/AddMobile';
import AddMobileVerifyMfa from 'components/AddMobile/views/AddMobileVerifyMfa';
import LoginVerifyMfa from 'components/AddMobile/views/LoginVerifyMfa';
import AddMobileFinish from 'components/AddMobile/views/AddMobileFinish';
import ResetPasswordConfirmPage from 'components/ForgotPassword/views/ResetPasswordConfirmPage';
import ResetPasswordDonePage from 'components/ForgotPassword/views/ResetPasswordDonePage';
import ResetPasswordInputPage from 'components/ForgotPassword/views/ResetPasswordInputPage';
import ForgotPasswordPrompt from 'components/ForgotPassword/views/ForgotPasswordPrompt';
import EmailConfirmed from 'components/ConfirmMail';
import AboutManulifeIdPage from './components/pages/AboutManulifeIdPage';
import VerifyPage from './components/pages/VerifyPage';
import HealthPage from './components/Health';
import ForgotUsernameContainer from './components/ForgotUsername';
import Contact from './components/Contact';
import RegistrationContainer from './components/Signup';
import OnDemandOTP from './components/OnDemandOTP';
import ActivationFlow from './components/Activation';
import OidcPage from './components/Login/views/inboundSsoTree/OidcPage';
import Code from './components/Login/views/inboundSsoTree/Code';

export const routes = [
  {
    path: [...getRealmAwarePaths('/'), ...getRealmAwarePaths('/signin')],
    exact: true,
    component: () => <SignInPage />,
  },
  {
    path: getRealmAwarePaths('/sso'),
    component: () => <Code />,
  },
  {
    path: getRealmAwarePaths('/inbound'),
    component: () => <OidcPage />,
  },
  {
    path: getRealmAwarePaths('/intro'),
    component: () => <IntroductionPage />,
  },
  {
    path: getRealmAwarePaths('/mfa'),
    component: () => <LoginMFASelectChannelPage />,
  },
  {
    path: getRealmAwarePaths('/otp'),
    component: () => <LoginVerifyMfa />,
  },
  {
    path: getRealmAwarePaths('/addmobilesuccess'),
    component: () => <AddMobileFinish />,
  },
  {
    path: getRealmAwarePaths('/addnumber'),
    component: () => <AddMobileContainer />,
  },
  {
    path: getRealmAwarePaths('/addnumbermfa'),
    component: () => <AddMobileVerifyMfa />,
  },
  {
    path: getRealmAwarePaths('/personalinfo'),
    component: () => <ResetPasswordConfirmPage />,
  },
  {
    path: getRealmAwarePaths('/inputpassword'),
    component: () => <ResetPasswordInputPage />,
  },
  {
    path: getRealmAwarePaths('/resetpasswordsuccess'),
    component: () => <ResetPasswordDonePage />,
  },
  {
    path: getRealmAwarePaths('/aboutmanulifeid'),
    component: () => <AboutManulifeIdPage />,
  },
  {
    path: getRealmAwarePaths('/contact'),
    component: () => <Contact />,
  },
  {
    path: getRealmAwarePaths('/registration'),
    component: () => <VerifyPage />,
  },
  {
    path: getRealmAwarePaths('/activation'),
    component: () => <ActivationFlow />,
  },
  {
    path: getRealmAwarePaths('/register'),
    component: () => <RegistrationContainer />,
  },
  {
    path: getRealmAwarePaths('/forgotpassword'),
    component: () => <ForgotPasswordPrompt />,
  },
  {
    path: getRealmAwarePaths('/forgotusername'),
    component: () => <ForgotUsernameContainer />,
  },
  {
    path: getRealmAwarePaths('/mailconfirm'),
    component: () => <EmailConfirmed />,
  },
  {
    path: getRealmAwarePaths('/otp-on-demand'),
    component: () => <OnDemandOTP />,
  },
  {
    path: '/isalive',
    component: () => <HealthPage />,
  },
  {
    path: '/',
    component: () => <SignInPage />,
  },
];

export const route = renderRoutes(routes);

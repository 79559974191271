const muxCustomStyles = {
  textInput: { rootStyle: { width: '100%' } },
  tertiaryButton: {
    buttonStyle: {
      paddingLeft: 0,
    },
  },
  inboundButton: {
    buttonStyle: {
      marginTop: '40px',
      width: '300px',
      maxWidth: '100%',
    },
  },
  inboundBackButton: {
    buttonStyle: {
      paddingLeft: 0,
      marginTop: '30px',
    },
  },
  legend: { legendStyle: { padding: '0' } },
  topProgressbar: {
    rootStyle: { marginTop: '20px' },
    progressBarStyle: { width: '100%' },
    labelStyle: { width: '100%' },
  },
  dateOfBirth: { fieldSetStyle: { width: '100%' } },
  paragraph: { elementStyle: { marginTop: 0 } },
};

export default muxCustomStyles;

/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';
import confirmEmail from 'components/ConfirmMail/reducer';
import { loginInfo } from '../components/Login/reducer';
import { register } from '../components/Signup/reducer';
import { forgotPasswordInfo, pageInfo } from '../components/ForgotPassword/reducer';
import { forgotUsernameInfo } from '../components/ForgotUsername/reducer';
import { verifyPassword } from '../components/VerifyPassword/reducer';
import registrationSuccessInfo from './registrationSuccessReducer';
import verifyInfo from './verifyReducer';
import otp from './otpReducer';
import intl from './localeReducer';
import common from './commonReducer';
import mfa from './mfaReducer';

const appReducer = combineReducers({
  common,
  loginInfo,
  registrationSuccessInfo,
  registrationInfo: register,
  verifyInfo,
  otp,
  forgotPasswordInfo,
  pageInfo,
  forgotUsernameInfo,
  intl,
  verifyPassword,
  confirmEmail,
  mfa,
});

const rootReducer = (state, action) => appReducer(state, action);

export default rootReducer;

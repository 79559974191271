import qs from 'query-string';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { usePathBuilder } from 'ciam-self-service-shared';
import useGoToUrl from '../../hooks/useGoToUrl';

function isSamlOtpOnDemandRequest(location) {
  const samlOtpOnDemandServices = ['saml_ondemand_stepup'];
  const { service } = qs.parse(location.search, { ignoreQueryPrefix: true });
  return samlOtpOnDemandServices.includes(service);
}

function isOauthOtpOnDemandRequest(gotoUri) {
  const otpOnDemandValues = ['stepup_mandatory', 'stepup_conditional'];
  const gotoUrl = new URL(gotoUri);
  const gotoParams = qs.parse(gotoUrl.search, { ignoreQueryPrefix: true });
  const claims = JSON.parse(gotoParams.claims);
  // Disable camelcase rule for one line, this is a field in the API response and out of our control
  // eslint-disable-next-line camelcase
  const acrValues = claims?.id_token?.acr?.values || [];
  return otpOnDemandValues.some((value) => acrValues?.includes(value));
}

export default function useOnDemandOtpRedirect() {
  const location = useLocation();
  const gotoUrl = useGoToUrl();
  const history = useHistory();
  const pathBuilder = usePathBuilder();

  return useMemo(() => {
    try {
      // SAML On Demand
      if (isSamlOtpOnDemandRequest(location)) {
        history.push(pathBuilder('/otp-on-demand', true, { searchParams: { type: 'saml' } }));
        return true;
      }
      // oAuth On Demand
      if (isOauthOtpOnDemandRequest(gotoUrl)) {
        history.push(pathBuilder('/otp-on-demand', true, { searchParams: { type: 'oidc' } }));
        return true;
      }
    } catch {
      // If we can't successfully parse the gotoURI (either it's missing or invalid)
      // then treat it as sign in and do not redirect for stepup
    }
    return false;
  }, [gotoUrl, history, location, pathBuilder]);
}
